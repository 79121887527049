import React from "react"
import {
  Header,
} from "./components"

const indent = { marginLeft: 14, }

// converted using https://word2cleanhtml.com/cleanit
// with some tweaks to better match original
export const TermsOfService = () => (
    <div>
    <Header title="Terms of Service" 
            downloadLink="https://tellescope-information.s3.us-east-2.amazonaws.com/legal-docs/Tellescope+Terms+of+Service+v1.0.pdf"

    />

    <main style={{ padding: "10px 50px" }}>
    <p>
    These Terms of Service (“Terms”) are an agreement between you (“you”,
    “your”) and Immuto, Inc., dba Tellescope (“Company”, “Immuto”,
    “Tellescope”, “we”, “us” or “our”) that allows you to use our applicable
    website(s), application(s) (“app(s)”), tools, software, subscriptions,
    memberships, content, API(s), widgets, and/or other products and services
    (collectively, as applicable, the "Services"), as long as you follow these Terms. By accessing or using any of our
    Services, including without limitation by downloading, installing or using
    any associated Software (defined below), you signify your agreement to all
    of the following, which are all expressly incorporated herein and must also
    be observed and followed (collectively, the “Agreement”):
</p>
<p style={indent}>
    (1) these Terms;
</p>
<p style={indent}>
    (2) our privacy policy as posted on our website (“Privacy Policy”); and
</p>
<p style={indent}>
    (3) any other standard policies or community guidelines (such as Acceptable
    Use Policies), if any, posted in any applicable parts of our Services.
</p>
<p>
    <strong>
        <em>
            <u>
                IF YOU ARE HAVING AN EMERGENCY, CONTACT 911, AND DO NOT USE OUR
                SERVICE(S).
            </u>
        </em>
    </strong>
</p>
<p>
    <strong> </strong>
</p>
<p>
    <strong>To Use Our Services (defined below), You:</strong>
</p>
<ul>
<li>
    May need to register with us to access and use some of our Services
</li>
<li>
    Must not initiate or participate in any activities on our Services that
    are illegal, harmful, or interfere with anyone's use of our services,
    including the sending of e-mail or instant messaging spam
</li>
</ul>
<p>
    <strong>If You Post Content On our Services, You:</strong>
</p>
<ul>
<li>
    May post content that you create or have been given permission to post by
    the owner, is legal, and doesn't violate this Agreement
</li>
<li>
    Are responsible for content, data and information that you post to our
    Services and assume all risks of posting personal information online
</li>
<li>
    Continue to own the content but grant us a license to use and distribute
    your content, subject to and in accordance with this Agreement.
</li>
</ul>
<p>
    1. DESCRIPTION OF SERVICES
</p>
<p>
    1.1. As used herein, “Software” means any software (including without
    limitation APIs or apps) supplied by or on behalf of the Company, including
    without limitation any for which the purpose is to enable you to use
    certain portions of the Services. For clarity, the Software is a part of
    the Services.
</p>
<p>
1.2. Any reference to “<a href="http://www.tellescope.com" target="_blank" rel="noopener noreferrer">www.tellescope.com</a>”, the
    “website(s)”, the “web site(s)”, the “site”, or other similar references,
    shall include any and all pages, subdomains, affiliated domains, brands,
    products or other areas of our website, or any other affiliated sites or
    domains owned or operated by or on behalf of us, plus any of the online
    content, information and services as made available in or through the
    website.
</p>
<p>
    1.3. The Services include without limitation all aspects of the website, or
    of any app or other product or service, including but not limited to all
    products, Software and other applications, features, channels and services
    offered therein.
</p>
<p>
    1.4. Any reference to “content” shall include all content in all forms or
    mediums, such as (without limitation) text, software, scripts, graphics,
    photos, sounds, music, videos, audiovisual combinations, interactive
    features and other materials you may view on, access through, or contribute
    to the Services.
</p>
<p>
    1.5. You must be at least 18 years of age, and, where law requires an older
    legal age, of legal age for contractual consent or older to use this
    website and/or the other Services. Due to the age restrictions, no content
    or information from the Services falls within the Child Online Privacy
    Protection Act (“COPA”) and is not monitored as doing so.
</p>
<p>
    1.6. All information and services are exchanged electronically, via the
    internet. You are responsible for maintaining your own access to the
    internet and for obtaining and maintaining any equipment and ancillary
    services needed to connect to, access or otherwise use the Services,
    including, without limitation, modems, hardware, servers, software,
operating systems, networking, web servers and the like (collectively, “    <u>Equipment</u>”). You shall also be responsible for maintaining the
    security of the Equipment. You consent to receiving communications
    electronically.
</p>
<p>
    1.7. Company is a privately owned and operated company and does not
    represent or speak for any governmental office or authority.
</p>
<p>
    1.8. You may acquire additional products, services and/or content of ours
    from our websites or Services. We reserve the right to require that you
    agree to separate agreements as a condition of your use and/or purchase of
    such additional products, services and/or content, which terms will apply
    in addition to these Terms.
</p>
<p>
    2. BUSINESS USER TERMS.
</p>
<p>
    2.1. If you are a business user offering some portions of the Services to
    your customers or end users (which may only be done if and to the extent
    expressly permitted by us herein), then this Section 2 shall also apply.
</p>
<p>
    2.2. Subject to the terms and conditions of this Agreement, you hereby
    grants to us the non-exclusive, right and license during the Term of this
    Agreement to market, promote, offer, advertise, sell and distribute and /
    or grant licenses to your applicable products, services, content, advice,
    support, and/or materials (collectively, “Your Services”) through our
    Services to our end users, including without limitation to the right to use
    any related marks or logos to the extent related thereto, in accordance
    with any logo guidelines provided in writing in advance. You retain all
    rights in and to Your Services and we retain all rights in and to our
    Services.
</p>
<p>
    2.3. You shall be responsible for clearly and conspicuously disclosing to
    your customers and end users (“end users”) (including without limitation in
    any posted terms and conditions and in any written agreements with your
    customers) (i) that you (and not we) are solely responsible for all
    content, services and/or advice that you provide to end users or any
    messages or content sent (or attempted to send) to, from, between or among,
    end users; (ii) that we shall have no liability whatsoever to end users;
    (iii) that we are not responsible for any disputes between you and your end
    users; (iv) regarding all disclaimers, warranty limitations, and
    limitations of liability, by us herein or otherwise with respect to the
    Services; and (v) with respect to use of the Services by your end users,
    such end users are responsible for complying with, and subject to, all the
    applicable terms as herein. You shall not state or imply otherwise. You
    hereby agree to indemnify, defend and hold us harmless from any for any
    allegation, loss, liability, claim made against us by any end users or
    otherwise arising out of any breach of this Section 2 and/or in any way
    relating to or arising out of Your Services (including without limitation
    the use thereof by end users or the offering thereof via our Services).
</p>
<p>
    2.4. You shall not violate the end user terms in Section 3. For example,
    without limitation, you should not use the Services to provide Emergency
    Services. You are considered a Third Party Service Provider as defined in
    Section 3.3.
</p>
<p>
    2.5. You will provide Your Services in a professional and workmanlike
    manner, in accordance with all applicable laws and industry standards
    including without limitation those standards generally offered to your own
    customers or patients. Without limiting the foregoing, in addition, you
    shall use best efforts to ensure that Your Services are available 24/7/365
    except to the extent outside your reasonable control. Your Services (and
    the use thereof by end users and the offering thereof via our Services)
    will not infringe any third party rights.
</p>
<p>
    2.6. You may receive push notifications, text messages, picture messages,
    alerts, emails or other types of messages directly sent to you (“Push
    Messages”), You acknowledge that your wireless service provider may charge
    you fees for data, text messaging and/or other wireless access, including
    in connection with Push Messages. Please check with your wireless service
    provider to determine what fees apply to your access to and use of Push
    Messages from the Company. YOU ARE SOLELY RESPONSIBLE FOR ANY FEE, COST OR
    EXPENSE THAT YOU INCUR TO PROVIDE THE SERVICES, INCLUDING WITHOUT
    LIMITATION FOR YOUR RECEIPT OF PUSH MESSAGES FROM THE COMPANY.
</p>
<p>
    2.7. You may wish to provide additional products, services and/or content
    via the Services. We reserve the right to require that you agree to
    separate agreements as a condition of your provision of such additional
    products, services and/or content, which terms will apply in addition to
    these Terms. We also reserve the right to determine that certain products,
    services and/or content you wish to provide are outside the scope of our
    Services, which cannot be offered through the Services.
</p>
<p>
    2.8. We shall mutually agree in writing on any pricing for Your Services,
    to us and/or to end users.
</p>
<p>
    2.9. You may not use the Services or and information you gain from working
    with us or our end users to compete with us. You may not attempt to
    circumvent this Agreement and attempt to gain direct relationships with our
    end users to displace us. You shall (i) ensure that each instance of
    communication, transaction, or collection of data under this Agreement is
    only made to the extent required to provide the applicable Services; and
    (ii) only use any data collected under this Agreement (including without
    limitation from or about Company or from or about end users (collectively,
    “Protected Data”)) to the extent needed, in a bona fide manner, to perform
    the Services as contemplated herein for the purposes of fulfilling your
    obligations under this Agreement to provide the applicable Services, and
    for no other purpose. Protected Data includes without limitation lists of
    customers or ed users, or other personal information from or about end
    users. This Section 2.9 (Non-Circumvention) shall survive after expiration
    or termination of this Agreement for any reason. Without limiting the
    generality of the foregoing, you shall not<strong>:</strong>
</p>
<ul>
    <li>
        use any scrapers, robots, crawlers, or other automated means to find,
        create, save, or aggregate competing databases, collections, libraries
        or lists of end users or other Protected Data;<strong></strong>
    </li>
    <li>
        retain a library of any Protected Data gathered hereunder after such
        data is no longer needed for the purposes of providing the Services
        contemplated herein;<strong></strong>
    </li>
    <li>
        obtain or attempt to obtain the Protected Data from via any method
        other than as expressly permitted herein while providing applicable
        Services;<strong></strong>
    </li>
    <li>
        attempt to circumvent this Agreement, Company, or the Services in any
        manner, such as, without limitation, by (i) attempting to solicit
        agreements with ed users or other middleware platforms or entities, to
        access Protected Data or other data or information as available
        hereunder, nor (ii) using any names or list(s) of end users or
        information about one or more end user(s) gathered hereunder to solicit
        any end user(s) for any reason.<strong></strong>
    </li>
    <li>
        include Protected Data and/or other Company Confidential Information in
        any product or service other than in as expressly contemplated herein
        for the mutual and collaborative provision of the Services (even if you
        do not charge for such product or service).<strong></strong>
    </li>
    <li>
        use Protected Data and/or other Company Confidential Information in any
        way to create or improve any competitive product or service
        (competitive in any way with Company) other than in as expressly
        contemplated herein for the mutual and collaborative provision of the
Services (even if you do not charge for such product or service).        <strong></strong>
    </li>
</ul>
<p>
    <strong></strong>
</p>
<p>
    3. END USER TERMS.
</p>
<p>
    3.1. We are not providing any medical advice or services via the Services,
    and should not be relied upon as a substitute for consultations with
    qualified professionals who are familiar with your individual needs. Please
    consult your doctor as necessary. THE SERVICES DO NOT INCLUDE THE PROVISION
    OF MEDICAL CARE BY THE COMPANY. RATHER, THE SERVICES ENABLE REGISTERED
    USERS TO ACCESS REMOTE HEALTH COACHING TOOLS AND RESOURCES. ANY USE OF THE
    SERVICES BY ANY PARTY, INCLUDING YOU, IN ANY HIGH RISK APPLICATION IS DONE
    AT THE USER’S OWN RISK, WITHOUT ANY WARRANTY. AS USED HEREIN, A “HIGH RISK
    APPLICATION” IS ANY USE WHERE THE FAILURE OF THE SERVICE COULD CAUSE
    SERIOUS RISK, INCLUDING RISK OF INJURY TO PERSONS OR PROPERTY, OR A
    LIFE-THREATENING SITUATION, INCLUDING BUT NOT LIMITED TO, MEDICAL, NUCLEAR,
    AVIATION, NAVIGATION, EMERGENCY SERVICES, EMERGENCY WEATHER ALERTS,
    MILITARY APPLICATIONS OR OTHER SIMILAR APPLICATIONS. OUR SERVICES ARE
    OFFERED FOR INFORMATIONAL PURPOSES ONLY. ALWAYS SEEK THE ADVICE OF YOUR
    PHYSICIAN OR OTHER QUALIFIED HEALTH PROVIDER WITH ANY QUESTIONS REGARDING
    YOUR MEDICAL CONDITION OR THE USE (OR FREQUENCY) OF ANY MEDICATION OR
    MEDICAL DEVICE. NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY IN
    SEEKING IT BECAUSE OF SOMETHING YOU HAVE READ IN CONNECTION WITH OUR
    SERVICES. NONE OF THE INFORMATION ON THE SITES REPRESENTS OR WARRANTS THAT
    ANY PARTICULAR DRUG OR TREATMENT IS SAFE, APPROPRIATE, OR EFFECTIVE FOR
    YOU.
</p>
<p>
    3.2. The Services may include mobile-based programs for changing behaviors
    that can lead to health problems, including health coaching, peer
    mentoring, and data logging, to individuals who may benefit from the use of
    such services, including, without limitation, the following services:
</p>
<ul>
<li>
   The ability to access health information related to your health goals.
</li>
<li>
   The ability to connect with and message users about their health goals on
  li multiple channels
</li>
<li>
    The ability to track progress related to health goals.
</li>
</ul>
<p>
    3.3. The Service may include, among other things, a search, directory, and
    communications platform for enabling the connection between individuals
    seeking to obtain Third Party Services (defined below) and/or doctors,
    nurses, pharmacists, or other individuals or companies seeking to provide
    Third Party Services. The Company does not guarantee or warrant, and makes
    no representations regarding, the reliability, quality or suitability of
    such Third Party Services nor Third Party Service Providers (defined
    below). When interacting with Third Party Service Providers you should
    exercise caution and common sense to protect your personal safety and
    property, just as you would when interacting with other persons whom you
    don't know. By using the Service, you agree to hold the Company free from
    the responsibility for any liability or damage that might arise out of the
    transactions involved. By posting or listing in our directory, you give us
    license to use your listing, including any content, information, and any
    names, marks or logos therein, in our directory an in promotion of our
    directory and Services. NEITHER THE COMPANY NOR ITS AFFILIATES OR LICENSORS
    IS RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF
    THE SERVICE OR THIRD PARTY SERVICES NOR ANY THIRD PARTY SERVICE PROVIDERS.
    THE COMPANY AND ITS AFFILIATES AND LICENSORS WILL NOT BE LIABLE FOR ANY
    CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION WITH YOUR USE OF THE SERVICE
    OR THE THIRD PARTY SERVICES. THE COMPANY DOES NOT PROVIDE THE TELEHEALTH
    SERVICES OR OTHER SERVICES FROM THIRD PARTIES IN OTHER CATEGORIES
    (COLLECTIVELY, THE “THIRD PARTY SERVICES”). THE COMPANY IS MERELY A
    DIRECTORY SERVICE HELPING USERS FIND THIRD PARTIES TO PROVIDE SUCH THIRD
    PARTY SERVICES (THE “THIRD PARTY SERVICE PROVIDERS”). IT IS UP TO THE THIRD
    PARTY SERVICE PROVIDER TO OFFER THEIR THIRD PARTY SERVICES, WHICH MAY BE
    SCHEDULED THROUGH USE OF THE COMPANY SOFTWARE OR SERVICE. THE COMPANY
    OFFERS INFORMATION AND A METHOD TO OBTAIN SUCH THIRD PARTY SERVICES FROM
    THIRD PARTY SERVICE PROVIDERS, BUT DOES NOT, AND DOES NOT INTEND TO,
    PROVIDE THIRD PARTY SERVICES OR ACT IN ANY WAY AS A THIRD PARTY SERVICE
    PROVIDER, AND HAS NO RESPONSIBILITY OR LIABILITY FOR ANY THIRD PARTY
    SERVICES PROVIDED TO (OR FAILED TO BE PROVIDED) NOR FOR THE ACTIONS OR
    INACTIONS OF ANY THIRD PARTY SERVICE PROVIDERS. ANY INDIVIDUALS WHO PROVIDE
    ANY THIRD PARTY SERVICES ARE NOT EMPLOYEES NOR CONTRACTORS OF COMPANY BUT
    RATHER OF THIRD PARTY SERVICE PROVIDERS, AND COMPANY IS NOT RESPONSIBLE FOR
    THEIR ACTIONS OR INACTIONS. THE COMPANY DOES NOT EMPLOY OR CONTRACT WITH
    PHYSICIANS TO PROVIDE MEDICAL CARE TO YOU. TO THE EXTENT YOU RECEIVE
    MEDICAL CARE CONSEQUENT TO THE SERVICES, YOUR TREATING MEDICAL PROFESSIONAL
    IS RESPONSIBLE FOR OBTAINING YOUR INFORMED CONSENT TO ANY MEDICAL DIAGNOSIS
    OR TREATMENT, INCLUDING WITHOUT LIMITATION, YOUR CONSENT TO USE
    TELEMEDICINE IN THE COURSE OF YOUR TREATMENT TO THE EXTENT SUCH CONSENT IS
    REQUIRED BY APPLICABLE STATE LAW.
</p>
<p>
    3.4. No Emergency Services. THE SERVICES ARE FOR NON-EMERGENCY PURPOSES
    ONLY. DO NOT ATTEMPT TO ACCESS EMERGENCY CARE THROUGH THE SERVICES. IF AT
    ANY TIME YOU ARE CONCERNED ABOUT YOUR CARE OR TREATMENT, OR IF YOU THINK
    YOU HAVE A MEDICAL EMERGENCY, CALL 911 OR GO TO THE NEAREST OPEN EMERGENCY
    ROOM. THE SERVICES ARE NOT INTENDED TO SUPPORT OR CARRY EMERGENCY OR
    TIME-CRITICAL CALLS OR COMMUNICATIONS TO ANY TYPE OF HOSPITAL, LAW
    ENFORCEMENT AGENCY, MEDICAL CARE UNIT, OR ANY OTHER KIND OF EMERGENCY OR
    TIME-CRITICAL SERVICE (COLLECTIVELY, “EMERGENCY SERVICES”). Company, its
    affiliates or staff are in no way liable for such emergency calls or
    communications. By agreeing to these Terms, you understand that additional
    arrangements must be made to access Emergency Services. To access Emergency
    Services, you acknowledge and accept your responsibility to purchase,
    separately from the Services, traditional wireless or wireline telephone
    service and other means of communication intended for access to Emergency
    Services. You recognize and agree that Company is not required to offer
    Emergency Services or access thereto pursuant to any applicable local and
    or national rules, regulation or law. You further acknowledge that Company
    is not a replacement for your primary telephone service or any other means
    of communication.
</p>
<p>
    3.5. As part of the Services you may receive push notifications, local
    client notifications, text messages, picture messages, alerts, emails or
    other types of messages directly sent to you (“Push Messages”), You
    acknowledge that, when you use the Services, your wireless service provider
    may charge you fees for data, text messaging and/or other wireless access,
    including in connection with Push Messages. You have control over the Push
    Messages settings, and can opt in or out of these Push Messages through the
    Services or through your mobile device’s operating system (with the
    possible exception of infrequent, important service announcements and
    administrative messages). Please check with your wireless service provider
    to determine what fees apply to your access to and use of the Services,
    including your receipt of Push Messages from the Company. YOU ARE SOLELY
    RESPONSIBLE FOR ANY FEE, COST OR EXPENSE THAT YOU INCUR TO DOWNLOAD,
    INSTALL AND/OR USE THE SERVICES ON YOUR MOBILE DEVICE, INCLUDING FOR YOUR
    RECEIPT OF PUSH MESSAGES FROM THE COMPANY.
</p>
<p>
    4. PASSWORDS AND ACCESS
</p>
<p>
    4.1. In order to access some features of the Services, you may have to
    register or create an account. You may never use another's account without
    permission. When creating your account, you must provide accurate and
    complete information. Registration or subscription to the Services and
    payment of any applicable fee, authorizes a single individual to use the
    Services unless otherwise expressly stated. You are solely responsible for
    the activity that occurs on your account, and you must keep your account
    password secure. You must notify Company immediately of any breach of
    security or unauthorized use of your account. Although Company will not be
    liable for your losses caused by any unauthorized use of your account, you
    may be liable for the losses of Company or others due to such unauthorized
    use. It is a condition of your use of the Services that all the information
    you provide will be correct, current, and complete; If we believe the
    information you provide is not correct, current, or complete, we have the
    right to refuse you access to the Services or any of its resources, and to
    terminate or suspend your access at any time, without notice.
</p>
<p>
    4.2. You shall not download any content, software or services unless you
    see a “download” or similar link displayed by Company on the Services for
    that content. You shall not copy, reproduce, distribute, transmit,
    broadcast, display, sell, license, or otherwise exploit any content for any
    other purposes without the prior written consent of Company or the
    respective licensors of the content. Company and its licensors reserve all
    rights not expressly granted in and to the Services and their content.
</p>
<p>
    4.3. You agree not to circumvent, disable or otherwise interfere with
    security-related features of the Services or features that prevent or
    restrict use or copying of any content or enforce limitations on use of the
    Services or the content therein.
</p>
<p>
    5. YOUR OBLIGATIONS
</p>
<p>
    5.1. You represent and warrant that all information that you provide to us
    will be true, accurate, complete and current, and that you have the right
    to provide such information to us in connection with your use of the
    Services. You must comply with the terms of any applicable policies posted
    in our Services, including any Acceptable Use Policy.
</p>
<p>
    You may not:
</p>
<ul>
<li>
    restrict or inhibit any other user from using and enjoying the Services;
</li>
<li>
    post or transmit any unlawful, fraudulent, libelous, defamatory, obscene,
    pornographic, vulgar, sexually-orientated, profane, threatening, abusive,
    hateful, offensive, false, misleading, derogatory, or otherwise
    objectionable information of any kind, including without limitation any
    transmissions constituting or encouraging conduct that would constitute a
    criminal offense, give rise to civil liability, or otherwise violate any
    local, state, national or foreign law, including without limitation the
    U.S. export control laws and regulations;
</li>
<li>
    post or transmit any advertisements, solicitations, chain letters,
    pyramid schemes, investment opportunities or schemes or other unsolicited
    commercial communication (except as otherwise expressly permitted by
    Company) or engage in spamming or flooding;
</li>
<li>
    post or transmit any information or software which contains a virus,
    Trojan horse, worm or other harmful component;
</li>
<li>
    upload, post, publish, reproduce, transmit or distribute in any way any
    component of the Services itself or derivative works with respect thereto;
</li>
<li>
    resell or otherwise exploit for commercial purposes, directly or
    indirectly, any portion of the Services, or access to them;
</li>
<li>
    use email addresses obtained from the Services for solicitation purposes
    of any kind, directly or indirectly;
</li>
<li>
    use data mining, robots or other similar data gathering and extraction
    tools;
</li>
<li>
    access (or attempt to access) the Services or any content therein through
    any automated means (including use of scripts or web crawlers);
</li>
<li>
    make any derivative works based, in whole or in part, on any portion or
    all of the Services;
</li>
<li>
    use i-frames, webpage frames, or any similar framing, to enclose, capture
    or distribute any part of the Services;
</li>
<li>
    mirror or cache or store any pages or portions of the Service;
</li>
<li>
    co-brand any portion of the Services;
</li>
<li>
    otherwise imply any relationship with or endorsement of your brands or
    services;
</li>
<li>
    use a false email address, impersonate any person or entity, forge e-mail
    headers or otherwise disguise the origin of any communication or mislead as
    to the source of the information you provide to the Services;
</li>
<li>
    portray Company or any company affiliated with it in a negative manner or
    otherwise portray its Services in a false, misleading, derogatory or
    offensive manner;
</li>
<li>
    use the Services in any manner that could damage, disable, overburden, or
    impair our servers or interfere with any other party's use and enjoyment of
    the Services;
</li>
<li>
    attempt to gain unauthorized access to any services or information to
    which you have not been granted access through password mining or any other
    process;
</li>
<li>
    post or transmit any photograph or likeness of another person without
    that person's consent, if and to the extent necessary under applicable
    laws;
</li>
<li>
    post, publish, transmit, reproduce, distribute or in any way exploit any
    information, software or other material obtained through the Services for
    commercial purposes (other than as expressly permitted by the Services and
    by the provider of such information, software or other material); or
</li>
<li>
    upload, post, publish, transmit, reproduce, or distribute in any way,
    information, software or other material obtained through the Services which
    is protected by copyright, or other proprietary right, or derivative works
    with respect thereto, without obtaining permission of the copyright owner
    or rightsholder, or which otherwise violates or infringes the rights of
    others, including without limitation, patent, trademark, trade secret,
    copyright, publicity, or other proprietary rights.
</li>
</ul>
<p>
    5.2. Company has no obligation to monitor the Services. However, you
    acknowledge and agree that Company has the right to monitor the Services
    electronically from time to time, and to disclose any information as
    necessary or appropriate to satisfy any law, regulation or other
    governmental request, to operate the Services properly, or to protect
    itself or its customers. Company reserves the right to refuse to post or to
    remove any information or materials, in whole or in part, that, in its sole
    discretion, are unacceptable, undesirable, inappropriate or in violation of
    this Agreement.
</p>
<p>
    5.3. With respect to any Software, including without limitation
    subscription software as a service, subject to the terms and conditions of
    this Agreement, we hereby grant to you a limited, nonexclusive,
    non-transferable license to access and use such Software during the term of
    this Agreement, solely by the number of authorized users, and only up to
    the approved and mutually agreed usage volumes, as set forth on the
    applicable ordering document or as otherwise mutually agreed, and solely
    for internal and non-commercial purposes, provided that you shall also
    comply at all times with all official documentation, technical manuals,
    functional manuals, operator and user guides and manuals.
</p>
<p>
    5.4. You shall not, and shall not attempt to (and shall not authorize or
    allow any third party to or attempt to): (a) download or otherwise obtain a
    copy of the Service (as applicable as such term is used herein, including
    any portion thereof) in any form; (b) reverse engineer, reverse compile,
    decompile, disassemble, or translate, exploit, or otherwise derive the
    source code of the Service or otherwise modify, the Service, or create any
    derivative works thereof; or (c) use the Service on behalf of any third
    party or for any purpose other than as described in this Agreement; (d)
    sell, resell, lease, license, sublicense, distribute, reproduce, copy,
    duplicate, or otherwise transfer or exploit the Service or use it as a
    service bureau; (e) post, send, process or store infringing, obscene,
    threatening, libelous, or otherwise unlawful or tortuous material,
    including material violating of third party rights; (f) post, send, process
    or store material containing software viruses, worms, Trojan horses or
    other harmful or malicious computer code, files, scripts, agents or
    programs; (g) interfere with or disrupt the integrity or performance of the
    Service or attempt to gain unauthorized access to the Service or related
    systems or networks; (h) remove, alter or obscure any titles, product logo
    or brand name, trademarks, copyright notices, proprietary notices or other
    indications of the intellectual property rights and/or our rights and
    ownership thereof, whether such notice or indications are affixed on,
    contained in or otherwise connected to the software or on any copies made
    in accordance with this Agreement; (i) remove, alter or obscure any titles,
    product logo or brand name, trademarks, copyright notices, proprietary
    notices or other indications of the intellectual property rights and/or our
    rights and ownership thereof, whether such notice or indications are
    affixed on, contained in or otherwise connected to the Service, or
    documentation, or on any copies made in accordance with this Agreement; (j)
    use, or authorize or permit the use of, the Service except as expressly
    permitted herein; (k) use the Service to perform any activity which is or
    may be, directly or indirectly, unlawful, harmful, threatening, abusive,
    harassing, tortuous, or defamatory, nor to perform any activity which
    breaches the rights of any third party. The Service may be used only by you
    (i) for your internal business purposes and only for your direct benefit;
    (ii) only by the number of persons for whom a license fee has been paid,
    and all such use may only be by those persons using the Service for the
    benefit of you in the course and scope of their employment, subject to the
    terms hereof; (iii) only in its original form without alteration or
    combination with other products, services or software except as expressly
    authorized in any applicable documentation; and (iv) in compliance with all
    applicable laws and in compliance with all documentation and instructions
    provided by us. You agree not to copy, duplicate or imitate, in whole or in
    part, any concept, idea, business model, business process, product, service
    or other intellectual property or other ideas or content embodied in the
    Services or learned by you from your use of or access to the Services. You
    agree not to use the Services to violate any local, state, national or
    international law or to impersonate any person or entity, or otherwise
    misrepresent your identity or your affiliation with a person or entity.
    Materials you post to the Services may not contain URLs or links to
    websites that compete with the Services nor other competitive content or
    references.
</p>
<p>
    5.5. We grant to you a limited, revocable, and nonexclusive right to create
    a hyperlink to our publicly available website(s), subject to the terms
    hereof, provided that the link does not portray us or our products or
    services in a false, misleading, derogatory, or offensive matter, and
    provided you do not i-frame them or alter the content therein or in any way
    imply a relationship therewith. You may not use any logo, trademark, or
    tradename that may be displayed on this site or other proprietary graphic
    image in the link without our prior written consent.
</p>
<p>
    5.6. You will keep and protect any of our Confidential Information as
    confidential, using at least the same efforts you use to protect your own
    confidential information and in no event less than reasonable and industry
    standard efforts. Our “Confidential Information” includes the Services,
    documentation and information about the Services and their operation, and
    any other information you obtain from or about us or from or about the
    Services, or any other information which a reasonable person would or
    should understand to be confidential or proprietary in nature. You agree to
    return or destroy our Confidential Information when this Agreement is over.
    You acknowledge and agree we shall be entitled to seek equitable relief in
    any court of competent jurisdiction without the necessity of posting bond
    and in addition to such other remedies as may be available under law or in
    equity. Your confidentiality obligations shall survive termination or
    expiration of this Agreement.
</p>
<p>
    5.7. While this Section 5 highlights some of your key obligations, headers
    and section titles are for convenience only, and you are bound by all the
    terms of this Agreement.
</p>
<p>
    6. PUBLIC POSTINGS AND LICENSED MATERIALS
</p>
<p>
    6.1. We will use reasonable efforts to protect the confidentiality of
    certain personally identifiable information you submit to us (e.g., your
    address and credit card information submitted by you initially for the
    purpose subscribing to the Service) (“Personally Identifiable
    Information”), in accordance with the Privacy Policy posted on our website.
</p>
<p>
    6.2. Certain material you may post on our Services is or may be available
    to the public, including without limitation any public profile data,
    feedback, questions, comments, suggestions, uploads, blog entries, ratings,
    reviews, images, videos, poll answers, etc., in any form or media, that you
    post via the Services or otherwise (collectively, "Public Postings"). These
    Public Postings will be treated as non-confidential and nonproprietary. You
    are responsible for any Public Postings and the consequences of sharing or
    publishing such content with others or the general public. This includes,
    for example, any personal information, such as your address, the address of
    others, or your current location. WE ARE NOT RESPONSIBLE FOR THE
    CONSEQUENCES OF PUBLICLY SHARING OR POSTING ANY PERSONAL OR OTHER
    INFORMATION ON THE SERVICES.
</p>
<p>
    6.3. Other content or communications you transmit to us, including without
    limitation any feedback, data, questions, comments, suggestions, in any
    form or media, that you submit to us via e-mail, the Services or otherwise
    (to the extent excluding any Personally Identifiable Information,
    collectively, "Submissions"), will be treated as non-confidential and
    nonproprietary.
</p>
<p>
    6.4. By providing any Public Posting or Submission, you (i) grant to
    Company a royalty-free, non-exclusive, perpetual, irrevocable,
    sub-licensable right to use, reproduce, modify, adapt, publish, translate,
    create derivative works (including products) from, distribute, and display
    such content throughout the world in all media and you license to us all
    patent, trademark, trade secret, copyright or other proprietary rights in
    and to such content for publication on the Services pursuant to this
    Agreement; (ii) agree that we shall be free to use any ideas, concepts or
    techniques embodied therein for any purpose whatsoever, including, but not
    limited to, developing and marketing products or services incorporating
    such ideas, concepts, or techniques, without attribution, without any
    liability or obligation to you; (iii) grant to Company the right to use the
    name that you submit in connection with such content. In addition, you
    hereby waive all moral rights you may have in any Public Posting or
    Submissions.
</p>
<p>
    6.5. You shall be solely responsible for your own content and any Public
    Postings and Submissions. You affirm, represent, and warrant that you own
    or have the necessary licenses, rights, consents, and permissions to
    publish content you post or submit. You further agree that content you
    submit via Public Postings or Submissions will not contain third party
    copyrighted material, or material that is subject to other third party
    proprietary rights, unless you have permission from the rightful owner of
    the material or you are otherwise legally entitled to post the material and
    to grant us all of the license rights granted herein. You further agree
    that you will not submit to the Services any content or other material that
    is contrary to any posted “community guidelines” or similarly titled
    document, if any, which may be updated from time to time, or contrary to
    applicable local, national, and international laws and regulations.
</p>
<p>
    6.6. We do not endorse any content submitted to the Services by any user or
    other licensor, or any opinion, recommendation, or advice expressed
    therein, and we expressly disclaim any and all liability in connection with
    content. We do not permit copyright infringing activities and infringement
    of intellectual property rights on the Services, and we will remove all
    content if properly notified that such content infringes on another's
    intellectual property rights as set forth herein below. We reserve the
    right to remove content without prior notice. We reserve the right to
    decide whether your content violates this Agreement for reasons other than
    copyright infringement, such as, but not limited to, pornography,
    obscenity, or excessive length. We may at any time, without prior notice
    and in our sole discretion, remove such content and/or terminate a user's
    account or otherwise block access for submitting such material in violation
    of this Agreement.
</p>
<p>
    6.7. We are not responsible for end user error or errors in inputs or for
    errors in any user supplied data. We do not independently verify the
    truthfulness or accuracy of any data or content input into the Services and
    are not responsible for the fraud, misrepresentation, negligence or
    misconduct of any end user or other third party.
</p>
<p>
    6.8. Neither party shall issue or release any announcement, statement,
    press release or other publicity or marketing materials relating to this
    Agreement or otherwise use the other party’s trademarks, service marks,
    trade names, logos, domain names or other indicia of source, affiliation or
    sponsorship, in each case, without the prior written consent of the other
    party, which consent shall not be unreasonably withheld, conditioned or
    delayed, provided, however, that we may, without your consent, include your
    name and/or other indicia in our lists of current or former customers in
    promotional and marketing materials. Additionally, upon your consent, we
    may prepare a case study relating to your access and use of the Services,
    including but not limited to one or more testimonials from you or your
    users, aggregated data of the results of your use of the Services, and
    other informational material as determined by us. you agree to provide
    reasonable assistance to us and timely responses in our preparation of said
    case study.
</p>
<p>
    7. FEES AND PAYMENTS
</p>
<p>
    7.1. If and to the extent any portion of the Services may require a fee
    payment or incremental payment or subscription, you agree to pay Company
    any applicable fees posted for the Services. By completing and submitting
    any credit card or other payment authorization through the Services, you
    are authorizing Company to charge the fees to the account you identify. You
    must keep all billing information, including payment method, up to date.
    You agree to pay us for all charges incurred under your account, including
    all applicable taxes, fees, and surcharges. You authorize and direct us to
    charge your designated payment method for these charges or, if your
    designated payment method fails, to charge any other payment method you
    have on file with us. Further, you authorize and direct us to retain
    information about the payment method(s) associated with your account. If we
    do not receive payment from your designated payment method or any other
    payment method on file, you agree to pay all amounts due upon demand by us.
    You will be responsible for accrued but unpaid charges, even if your
    account is canceled by you or terminated by us. During any free trial or
    other promotion, if any, you will still be responsible for any purchases
    and surcharges incurred using your account.
</p>
<p>
    7.2. After 30 days from the date of any unpaid charges, your fee-based
    Services will be deemed delinquent and we may terminate or suspend your
    account and Services for nonpayment. We reserve the right to assess an
    additional 1.5 percent late charge (or the highest amount allowed by law,
    whichever is lower) per month if your payment is more than 30 days past due
    and to use any lawful means to collect any unpaid charges. You are liable
    for any fees, including attorney and collection fees, incurred by us in our
    efforts to collect any remaining balances from you. Except as otherwise
    mutually agreed in writing, we reserve the right to change our fees with 30
    days’ notice.
</p>
<p>
    7.3. You are responsible for all charges incurred under your account,
    including applicable taxes, fees, surcharges, and purchases made by you or
    anyone you allow to use your account (including your children, family,
    friends, or any other person with implied, actual, or apparent authority)
    or anyone who gains access to your account as a result of your failure to
    safeguard your username, password, or other authentication credentials or
    information.
</p>
<p>
    8. WARRANTIES AND LIMITATIONS OF WARRANTIES.
</p>
<p>
    8.1. If you are not completely satisfied with the Services, your sole
    remedy is to cease using the Services. With respect to any fee-based
    Services, if you signed up for designated term or timeframe, you will still
    be responsible for payment for the full term. If you did not subscribe for
    any minimum period, then you may cancel at any time on 30 days advanced
    notice, and cease to use the Services, then you will not be charged any
    additional amounts after the effective date of such termination. In any
    case, you will be responsible for any and all charges and activity accrued
    prior to your Services termination date, and those obligations will survive
    your termination of the Services. Company undertakes commercially
    reasonable efforts to ensure that the information it provides is current
    and accurate, however, Company does not warrant the accuracy of
    information. Company also undertakes commercially reasonable efforts to
    protect the confidentiality of any confidential information you provide, in
    accordance with the Privacy Policy, however, Company does not guaranty the
    confidentiality of such information against unauthorized third party access
    or system failure.
</p>
<p>
    8.2. THE SERVICES, THE WEBSITE, APPS, AND ALL INFORMATION, CONTENT, AND
    MATERIALS RELATED TO THE FOREGOING, ARE PROVIDED "AS IS." EXCEPT AS
    EXPRESSLY STATED IN THIS AGREEMENT, WE DISCLAIM ALL WARRANTIES, EXPRESS OR
    IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY,
    FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT,
    NON-INTERFERENCE, SYSTEM INTEGRATION AND ACCURACY OF DATA. WE DO NOT
    WARRANT THAT USE OF THE SERVICES WILL BE UNINTERRUPTED, ERROR-FREE OR VIRUS
    FREE. ALTHOUGH INFORMATION THAT YOU SUBMIT MAY BE PASSWORD PROTECTED, WE DO
    NOT GUARANTEE THE SECURITY OF ANY INFORMATION TRANSMITTED TO OR FROM THE
    SERVICES AND YOU AGREE TO ASSUME THE SECURITY RISK FOR ANY INFORMATION YOU
    PROVIDE THROUGH THE SERVICES.
</p>
<p>
    9. LIMITATIONS OF LIABILITY.
</p>
<p>
    9.1. IN NO EVENT SHALL WE OR OUR AFFILIATES BE LIABLE FOR ANY INDIRECT,
    INCIDENTAL, CONSEQUENTIAL OR SPECIAL DAMAGES, OR FOR LOSS OF PROFITS OR
    DAMAGES ARISING DUE TO BUSINESS INTERRUPTION OR FROM LOSS OR INACCURACY OF
    INFORMATION, INCLUDING IF AND TO THE EXTENT ANY OF THE FOREGOING ARISES IN
    CONNECTION WITH THIS AGREEMENT OR YOUR USE OR INABILITY TO USE THE
    SERVICES, WHETHER OR NOT SUCH DAMAGES WERE FORESEEABLE AND EVEN IF WE WERE
    ADVISED THAT SUCH DAMAGES WERE LIKELY OR POSSIBLE. IN NO EVENT WILL THE
    AGGREGATE LIABILITY OF US TO YOU FOR ANY AND ALL CLAIMS ARISING IN
    CONNECTION WITH THIS AGREEMENT OR THE SERVICES, EXCEED THE TOTAL FEES PAID
    TO US BY YOU, IF ANY, DURING THE SIX-MONTH PERIOD PRECEDING THE DATE OF ANY
    CLAIM (OR $10 IF THE SERVICES ARE FREE). YOU ACKNOWLEDGE THAT THIS
    LIMITATION OF LIABILITY IS AN ESSENTIAL TERM BETWEEN YOU AND US RELATING TO
    THE PROVISION OF THE SERVICE TO YOU AND WE WOULD NOT PROVIDE THE SERVICE TO
    YOU WITHOUT THIS LIMITATION.
</p>
<p>
    9.2. YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS US AND OUR AFFILIATED
    COMPANIES, AND EACH OF OUR AND THEIR RESPECTIVE OFFICERS, DIRECTORS,
    MEMBERS, AGENTS, AND EMPLOYEES FROM AND AGAINST ALL LOSSES, EXPENSES,
    DAMAGES, CLAIMS, JUDGMENTS, COSTS, EXPENSES AND LIABILITIES, INCLUDING
    REASONABLE ATTORNEYS' FEES, INCURRED BY US OR SUCH PARTIES AND/OR ARISING
    OUT OF OR RESULTING FROM (1) ANY ACTUAL OR ALLEGED VIOLATION BY YOU OF THIS
    AGREEMENT (INCLUDING ANY REPRESENTATION OR WARRANTY HEREIN); (2) ANY
    ACTIVITY RELATED TO YOUR ACCOUNT BY YOU OR ANY OTHER PERSON ACCESSING THE
    SERVICE WITH YOUR PASSWORD; (3) YOUR USE OF AND ACCESS TO THE SERVICES; (4)
    YOUR ACTUAL OR ALLEGED VIOLATION OF ANY THIRD PARTY RIGHT, INCLUDING
    WITHOUT LIMITATION ANY COPYRIGHT, PROPERTY OR PRIVACY RIGHT; (5) YOUR
    ACTUAL OR ALLEGED VIOLATION OF ANY LAW, RULE OR REGULATION; AND/OR (6) YOUR
    CONTENT OR DATA, INCLUDING IF IT CAUSES ANY DAMAGE TO A THIRD PARTY. YOUR
    DEFENSE, INDEMNIFICATION AND HOLD HARMLESS OBLIGATIONS IN THIS AGREEMENT
    WILL SURVIVE THIS AGREEMENT AND YOUR USE OF THE SERVICES.
</p>
<p>
    9.3. You acknowledge that the information on the Website and other Services
    is provided 'as is' for general information only. If you use the Services
    to provide any services in any heavily regulated industry, such as, without
    limitation, medical, legal, tax or financial advice, you are fully
    responsible for all such services, and represent and warrant that you are
    appropriately qualified and certified to do so, possessing all necessary
    licenses and permits to do so. You indemnify us for any failure by you or
    your agents to do so and/or to follow any applicable laws, rules and
    regulations. You may use the Services for informational purposes only, as
    an aid, but only as one information source among many, and not as the sole
    basis for making any decisions; you must conduct proper due diligence and
    use your own judgment when making any decisions based on any information,
    analytics or reports derived from the Services.
</p>
<p>
    9.4. We shall not be liable for any circumstances arising out of causes
    beyond our reasonable control or without our fault or negligence,
    including, but not limited to, Acts of God, acts of civil or military
    authority, fires, riots, wars, embargoes, Internet disruptions, hacker
    attacks, or communications failures, or other force majeure.
</p>
<p>
    9.5. If we breach this Agreement, you agree that your exclusive remedy is
    to recover, from us or any affiliates, resellers, distributors, and
    vendors, direct damages up to an amount equal to your Services fee for one
    month (or up to USD$10.00 if the Services are free). YOU CAN'T RECOVER ANY
    OTHER DAMAGES OR LOSSES, INCLUDING, WITHOUT LIMITATION, DIRECT,
    CONSEQUENTIAL, LOST PROFITS, SPECIAL, INDIRECT, INCIDENTAL, OR PUNITIVE.
    These limitations and exclusions apply if this remedy doesn't fully
    compensate you for any losses or fails of its essential purpose or if we
    knew or should have known about the possibility of the damages. To the
    maximum extent permitted by law, these limitations and exclusions apply to
    anything related to this Agreement such as, without limitation, loss of
    content; any virus affecting your use of the Services; delays or failures
    in starting or completing transmissions or transactions; claims for breach
    of contract, warranty, guarantee, or condition; strict liability,
    negligence, misrepresentation, or omission; trespass, or other tort;
    violation of statute or regulation; or unjust enrichment.
    <strong>
        Some or all of these limitations or exclusions may not apply to you if
        your state, province, or country doesn't allow the exclusion or
        limitation of incidental, consequential, or other damages.
    </strong>
</p>
<p>
    10. DURATION OF TERMS
</p>
<p>
    10.1. Once in effect, this Agreement will continue in operation until
    terminated by either you or us. However, even after termination, the
    provisions of sections 3 through 18 of this Agreement will remain in effect
    in relation to any prior use of the Services by you. You may terminate this
    Agreement at any time and for any reason by providing notice to Company in
    the manner specified in this Agreement or by choosing to cancel your access
    to the Services using the tools provided for that purpose within the
    Services. We may terminate this Agreement without notice or, at our option,
    temporarily suspend your access to the Services, in the event that you
    breach this Agreement. Notwithstanding the foregoing, Company also reserves
    the right to terminate this Agreement at any time and for any reason by
    providing notice to you either through email or other reasonable means.
    After termination of this Agreement for any reason, you understand and
    acknowledge that Company will have no further obligation to provide the
    Services or access thereto. Upon termination, all licenses and other rights
    granted to you by this Agreement, if any, will immediately cease, but your
    licenses to us shall survive, and certain of your obligations (including
    payment obligations, if any) will survive in accordance with the terms
    hereof.
</p>
<p>
    11. MODIFICATION OF TERMS
</p>
<p>
    11.1. Company may change the terms of this Agreement from time to time. You
    will be notified of any such changes via e-mail (if you have provided a
    valid email address) and/or by our posting notice of the changes on the
    Services (which may consist of publishing the changes on our website). Any
    such changes will become effective when notice is received or when posted
    on the Services, whichever first occurs. If you object to any such changes,
    your sole recourse will be to terminate this Agreement. Continued use of
    the Services following such notice of any such changes will indicate your
    acknowledgement of such changes and agreement to be bound by such changes.
</p>
<p>
    12. MODIFICATIONS TO SERVICES
</p>
<p>
    12.1. We reserve the right to modify or discontinue the Services at any
    time with or without notice to you, including without limitation by adding
    or subtracting features and functionality, third party content, etc. In the
    event of such modification or discontinuation of the Services, your sole
    remedy shall be to terminate this Agreement as set forth herein. Continued
    use of the Services following notice of any such changes will indicate your
    acknowledgement and acceptance of such changes and satisfaction with the
    Services as so modified.
</p>
<p>
    13. OWNERSHIP
</p>
<p>
    13.1. We and/or our vendors and suppliers, as applicable, retain all right,
    title and interest in and to the Services, the website and all information,
    content, Software, and other software and materials provided by or on
    behalf of us, including but not limited to all text, images, videos, logos,
    button icons, audio clips, and the look and feel of the website and our
    brands and logos, and any data compilations, including without limitation
    any data input by or on behalf of us or our third party providers, and any
    data to the extent processed by, or resulting as an output of, the
    Services, and all Services usage data, statistical data or aggregated data
    collected or reported with respect to the any part or all of the Services,
    including without limitation any aggregated and anonymized data extracted
    or derived from the Service, including all aggregated and anonymized usage
    data, statistical data, transactional data, metadata, market data and other
    aggregated and anonymized data collected from user data and files. We own
    the rights to any metadata we collect from or about your use of the
    Services. Without limiting the generality of the foregoing, we reserve the
    right to create and market public indexes, analysis or insights created
    from such data. You agree that you will not copy, reproduce, distribute or
    create derivative works from any information, content, software or
    materials provided by us, or remove any copyright or other proprietary
    rights notices contained in any such information, content, software or
    materials without the copyright owner's prior written consent.
</p>
<p>
    13.2. Unless otherwise stated, all content in our websites or other
    Services, is our property or the property of third parties. These contents
    are protected by copyright as a collective work and/or compilation,
    pursuant to U.S. copyright laws, international conventions and other
    copyright laws.
</p>
<p>
    13.3. Your feedback is welcome and encouraged. You agree, however, that (i)
    by submitting unsolicited ideas to us, you automatically forfeit your right
    to any intellectual property rights in such ideas; and (ii) unsolicited
    ideas submitted to us or any of our employees or representatives
    automatically become our property.
</p>
<p>
    14. INTERNATIONAL USERS<strong></strong>
</p>
<p>
    14.1. The Services are controlled, operated and administered by us from
    within the United States. We make no representation that this site is
    available for access or use at other locations outside the U.S. However,
    any access or use from outside the U.S. is still subject to this Agreement.
    Access to the Services is expressly prohibited from territories where this
    site or any portion thereof is illegal. You agree not to access or use any
    information or materials on the Services in violation of U.S. export laws
    and regulations, or in violation of any laws or regulations in the country
    from which you are accessing the Services. Any personal information which
    we may collect via the Services may be stored and processed in our servers
    located in the United States or in any other country in which we, or our
    affiliates, subsidiaries, or agents maintain facilities. You consent to any
    such transfer of personal information outside your country of residence to
    any such location.
</p>
<p>
    15. THIRD PARTY CONTENT AND SERVICES
</p>
<p>
    15.1. Certain content (including without limitation advertisements) on the
    Services may be supplied by third parties. Company does not have editorial
    control over such content. Any opinions, advice, statements, services,
    offers, or other information that constitutes part of the content expressed
    or made available by third parties, including without limitation, suppliers
    and vendors, advertisers, or any customer or user of the Services, are
    those of the respective authors or distributors and not of Company or its
    affiliates or any of its officers, directors, employees, or agents. In many
    instances, the content available on the Services represents the opinions
    and judgments of the respective third parties, whether or not under
    contract with Company. You may enter into correspondence with or
    participate in promotions of such third parties, such as advertisers
    promoting their products, services or content on this site. Any such
    correspondence or participation, including the delivery of and the payment
    for products, services or content, are solely between you and each such
    third party. Company neither endorses nor is responsible for the accuracy
    or reliability of any opinion, advice, submission, posting, or statement
    made on the Services. Under no circumstances shall Company, or its
    affiliates, or any of their respective officers, directors, employees, or
    agents, be liable for any loss or damage caused by your reliance on any
    content or other information obtained through the Services.
</p>
<p>
    15.2. The Services may integrate and/or interact with third party services,
    such as via APIs or browser extensions. For example, the Services may
    leverage APIs from third parties, and/or rely on third party browser
    extensions, and Company has no affiliation, association, endorsement, or
    sponsorship by any other third party services with which it integrates or
    interacts from time to time (collectively, “Third Party Services”). Company
    makes no claim, representation or warranty of any kind, type or nature
    concerning any Third Party Services, nor Company’s or any User’s compliance
    with any third party terms of service for any such Third Party Services
    (collectively, “Third Party Terms”). It shall be each User’s sole
    responsibility to analyze and interpret any applicable Third Party Terms
    and comply therewith. Each User is solely responsible for their
    interpretation of Third Party Terms and their actions relevant to
    compliance thereof. By using the Services, you hereby release Company and
    waive any and all claims or claim rights that you may have against Company,
    and release and indemnify Company against any claims that any third party
    may have against you, including with respect to your use of any Third Party
    Services, including if accessed or used via our Services, and with respect
    to Third Party Terms, applicable privacy policies or any other rules or
    regulations of such third parties.
</p>
<p>
    15.3. Without limiting the generality of the foregoing, we may elect, in
    our discretion, to utilize social logins, allowing you to login to the
    Services via other third party authentication services, such as (without
    limitation) your Facebook, Twitter, LinkedIn, Google, or other account
    credentials. You understand that these are Third Party Services, and this
    in no way creates and endorsement of, by or from us to them or vice versa,
    that we are not responsible for their logins, systems or data, and that by
    using such third party logins, you may be subject to their respective
    privacy policies and other terms of use.
</p>
<p>
    15.4. You understand that when using the Services, you will be exposed to
    content from a variety of sources, and that we are not responsible for the
    accuracy, usefulness, safety, or intellectual property rights of or
    relating to such content. You further understand and acknowledge that you
    may be exposed to content that is inaccurate, offensive, indecent, or
    objectionable, and you agree to waive, and hereby do waive, any legal or
    equitable rights or remedies you have or may have against us with respect
    thereto, and, to the extent permitted by applicable law, agree to indemnify
    and hold harmless Company, its owners, operators, affiliates, licensors,
    and licensees to the fullest extent allowed by law regarding all matters
    related to your use of the Services.
</p>
<p>
    15.5. As a convenience to you, Company may provide, in or through the
    Services, one or more links to third party web sites or services and/or
    provide email contacts respecting third parties. Company makes no
    endorsement of such third parties, nor any representation or warranty
    regarding anything that takes place between you and any such third parties,
    including, without limitation, visits to third party web sites, services,
    email correspondence with third parties, and business or other transactions
    with third parties found through the Services. Please understand that such
    third parties are independent from and not controlled by Company, even if,
    for example, a Company link or logo appears on a website linked from this
    website or our other Services. It is up to you to read those third party
    sites’ applicable terms of use, privacy, and other applicable policies. For
    example, without limitation, we may outsource operation of certain aspects
    of our Services to one or more third parties, and they may have access to
    certain data by virtue of operating such Services, subject to their own
    policies.
</p>
<p>
    15.6. Certain third parties may be third party beneficiaries to this
    contract. For example, without limitation, Apple Inc., Google, Inc., or
    Microsoft Corporation will be a third-party beneficiary to this contract if
    you access the Services using applications developed for Apple iOS,
    Android, or Microsoft Windows-powered mobile devices, respectively. These
    third party beneficiaries are not parties to this contract and are not
    responsible for the provision or support of the Services in any manner.
    However, your access to the Services using these devices is subject to
    terms set forth in the applicable third party beneficiary's terms of
    service.
</p>
<p>
    16. DIGITAL MILLENNIUM COPYRIGHT ACT
</p>
<p>
    16.1. If you are a copyright owner or an agent thereof and believe that any
    content in our Services infringes upon your copyrights, you may submit a
    notification pursuant to the Digital Millennium Copyright Act ("DMCA") by
    providing our Copyright Agent with the following information in writing
    (see 17 U.S.C 512(c)(3) for further detail):
</p>
<ul>
    <li>
        A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
    </li>
    <li>
        Identification of the copyrighted work claimed to have been infringed,
        or, if multiple copyrighted works at a single online site are covered
        by a single notification, a representative list of such works at that
        site;
    </li>
    <li>
        Identification of the material that is claimed to be infringing or to
        be the subject of infringing activity and that is to be removed or
        access to which is to be disabled and information reasonably sufficient
        to permit the service provider to locate the material;
    </li>
    <li>
        Information reasonably sufficient to permit the service provider to
        contact you, such as an address, telephone number, and, if available,
        an electronic mail;
    </li>
    <li>
        A statement that you have a good faith belief that use of the material
        in the manner complained of is not authorized by the copyright owner,
        its agent, or the law; and
    </li>
    <li>
        A statement that the information in the notification is accurate, and
        under penalty of perjury, that you are authorized to act on behalf of
        the owner of an exclusive right that is allegedly infringed.
    </li>
</ul>
<p>
    16.2. Company's designated method to receive notifications of claimed
infringement is by emailing the Copyright Agent at    <a href="mailto:inquiries@tellescope.com">inquiries@tellescope.com</a>. You
    acknowledge that if you fail to comply with all of the requirements of this
    Section your DMCA notice may not be valid.
</p>
<p>
    16.3. If you believe that your content that was removed (or to which access
    was disabled) is not infringing, or that you have the authorization from
    the copyright owner, the copyright owner's agent, or pursuant to the law,
    to post and use the material in your content, you may send a counter-notice
    containing the following information to the Copyright Agent:
</p>
<ul>
    <li>
        Your physical or electronic signature;
    </li>
    <li>
        Identification of the content that has been removed or to which access
        has been disabled and the location at which the content appeared before
        it was removed or disabled;
    </li>
    <li>
        A statement that you have a good faith belief that the content was
        removed or disabled as a result of mistake or a misidentification of
        the content; and
    </li>
    <li>
        Your name, address, telephone number, and e-mail address, a statement
        that you consent to the jurisdiction of the federal court in Delaware,
        and a statement that you will accept service of process from the person
        who provided notification of the alleged infringement.
    </li>
</ul>
<p>
    16.4. If a counter-notice is received by the Copyright Agent, Company may
    send a copy of the counter-notice to the original complaining party
    informing that person that it may replace the removed content or cease
    disabling it in 10 business days. Unless the copyright owner files an
    action seeking a court order against the content provider, member or user,
    the removed content may be replaced, or access to it restored, in 10 or
    more business days after receipt of the counter-notice, at Company's sole
    discretion.
</p>
<p>
    17. CLASS ACTION WAIVER AND ARBITRATION
</p>
<p>
    <strong>
        THIS SECTION CONTAINS A BINDING ARBITRATION CLAUSE AND CLASS ACTION
        WAIVER. IT AFFECTS YOUR RIGHTS ABOUT HOW TO RESOLVE ANY DISPUTE WITH
        US.
    </strong>
</p>
<p>
    <strong> </strong>
</p>
<p>
    17.1. Except if you opt-out as expressly permitted below, or except to the
    extent contrary to applicable law ("Excluded Disputes"), you hereby agree
    that all disputes between you and us (whether or not such dispute involves
    a third party) with regard to your relationship with us, including without
    limitation disputes related to these Terms, your use of the Service, and/or
    rights of privacy and/or publicity, may, in our discretion, be resolved by
    binding, individual arbitration under the American Arbitration
    Association's rules for arbitration of consumer-related disputes and you
    hereby expressly waive trial by jury. As an alternative, you may bring your
    claim in your local "small claims" court, if permitted by that small claims
    court's rules. You may bring claims only on your own behalf. You agree that
    you shall not participate in any class action or class-wide arbitration for
    any claims covered by this Agreement. You also agree not to participate in
    claims brought in a private attorney general or representative capacity, or
    consolidated claims involving another person's account, if we are a party
    to the proceeding. This dispute resolution provision will be governed by
    the US Federal Arbitration Act, to the extent permissible. In the event the
    American Arbitration Association is unwilling or unable to set a hearing
    date within one hundred and sixty (160) days of filing the case, then
    either we or you can elect to have the arbitration administered instead by
    the Judicial Arbitration and Mediation Services. Judgment on the award
    rendered by the arbitrator may be entered in any court having competent
    jurisdiction. Any provision of applicable law notwithstanding, the
    arbitrator will not have authority to award damages, remedies or awards
    that conflict with these Terms. You may opt out of this agreement to
    arbitrate. If you do so, neither you nor we can require the other to
    participate in an arbitration proceeding. To opt out, you must notify us in
    writing within 30 days of the date that you first became subject to this
arbitration provision. You must use this address to opt out:    <a href="mailto:inquiries@tellescope.com">inquiries@tellescope.com</a>.
</p>
<p>
    17.2. You must include your name and residence address, the email address
    you use for your account with us, and a clear statement that you want to
    opt out of this arbitration agreement. If and to the extent the prohibition
    against class actions and other claims brought on behalf of third parties
    contained above is found to be unenforceable, then such preceding language
    in this Arbitration section will be null and void. This arbitration
    agreement will survive the termination of your relationship with us.
</p>
<p>
    18. MISCELLANEOUS.
</p>
<p>
    18.1. You shall comply with all laws, rules and regulations now or
    hereafter promulgated by any government authority or agency that are
    applicable to your use of the Services, or the transactions contemplated in
    this Agreement. You may not assign your rights or obligations hereunder,
    and any attempt by you to sublicense, assign or transfer any of the rights,
    duties or obligations hereunder or to exceed the scope of this Agreement is
    void. In the event that Company is sold to a third party, such a sale will
    not be deemed a transfer of personal information so long as that third
    party agrees to assume Company's obligations as to these Terms of Services
    and any associated Privacy Policy. This Agreement, the Services, and the
    rights and obligations of the parties with respect to the Services will be
    subject to and construed in accordance with the laws of the State of
    Delaware, excluding conflict of law principles. By accessing or using any
    Services you agree that the statutes and laws of the State of Delaware,
    without regard to conflicts of law principles thereof, will apply to all
    matters arising from or relating to the use thereof. You also agree and
    hereby submit to the exclusive legal jurisdiction and venues of the Courts
    of New Castle County, Delaware with respect to such matters. This is the
    entire agreement between you and Company with regard to the matters
    described herein and govern your use of the Services, superseding any prior
    agreements between you and Company with respect thereto. The failure of
    Company to exercise or enforce any right or provision of this Agreement
    shall not constitute a waiver of such right or provision. If any provision
    of this Agreement is found by a court of competent jurisdiction to be
    invalid, the parties nevertheless agree that the court should endeavor to
    give effect to the parties' intentions as reflected in the provision, and
    the other provisions hereof shall remain in full force and effect. You
    agree that regardless of any statute or law to the contrary, any claim or
    cause of action arising out of this Agreement or related to use of the
    Services must be filed within three (3) months after such claim or cause of
    action arose or be forever barred.
</p>
<p>
    <strong>Last Updated: </strong>
    <strong>January 15,</strong>
    <strong> 2021</strong>
</p>
 &copy; 2021 Brian Heller, Outside GC
    </main>
    </div>
)

export default TermsOfService