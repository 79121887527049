import { Grid, SxProps, Typography } from "@mui/material"
import { HoverPaper, LoadingLinear, ScrollingList, useCalendarEvents, useCarePlans, useEnduserSession, usePortalCustomizations, value_is_loaded } from "@tellescope/react-components"
import { CalendarEvent } from "@tellescope/types-client"
import React from "react"
import { VirtualVisitIcon } from "../components/icons"
import { useFullHeight } from "../components/navigation"
import { TitleWithFirstName } from "../components/titles"
import { DASHBOARD_CHILD_MARGIN } from "../definitions/constants"
import { CarePlanIcon, ChevronRightIcon } from "../definitions/icons"
import { routes, useNavigateToPage } from "../definitions/routes"
import { cardListTitleStyle } from "../definitions/styles"


const textSx: SxProps = {
  maxWidth: {
    xs: '50vw', 
    mg: '60vw',
    xl: '70vw',
  }
}
export const CarePlanEventCard = ({ item: event } : { item: CalendarEvent }) => {
  const navigate = useNavigateToPage()

  return (
    <HoverPaper 
      style={{ padding: 15, borderRadius: 15, marginBottom: 10 }}
      baseElevation={5} hoveredElevation={10}
      onClick={() => {
        navigate(routes.events, { id: event.id })
      }}
    >
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item style={{ 
        backgroundColor: '#F1F0EC', borderRadius: '100%', 
        padding: 7.5, width: 35, height: 35,
      }}>
        {event.enableVideoCall
          ? <VirtualVisitIcon style={{ fontSize: 20 }} /> 
          : <CarePlanIcon style={{ fontSize: 20 }} /> 
        }
      </Grid>

      <Grid item>
      <Grid container direction="column" sx={{ 
        pl: { xs: 1, md: 2},
      }}>
        <Typography noWrap color="primary" sx={{ 
          ...textSx,
          fontWeight: 'bold', 
          fontSize: {
            xs: 16,
            md: 20,
          },
        }}>
          {event.title}
        </Typography>

        <Typography noWrap sx={{  
          ...textSx,
          fontSize: {
            xs: 12,
            md: 16,
          },
          opacity: 0.8,
        }}>
          {event.description}
        </Typography>
      </Grid>
      </Grid>

      <Grid container alignItems="center" justifyContent="flex-end">
        <ChevronRightIcon color="primary" />
      </Grid>
    </Grid>
    </HoverPaper>
  )
}

export const CarePlanOverview = ({ 
  maxHeight,
} : { 
  maxHeight?: React.CSSProperties['maxHeight']
}) => {
  const fullHeight = useFullHeight()

  const [, { filtered, ...loadProps }] = useCalendarEvents()
  const [carePlansLoading] = useCarePlans()
  const careplan = value_is_loaded(carePlansLoading) ? carePlansLoading.value[0] : undefined
  const eventsLoading = filtered(e => e.carePlanId === careplan?.id)

  if (
    !careplan 
  || (value_is_loaded(eventsLoading) && eventsLoading.value.length === 0) 
  ) return <Typography sx={{ mx: DASHBOARD_CHILD_MARGIN }}>Your care plan will appear here</Typography>
  return (
    <Grid container>
      <LoadingLinear data={eventsLoading} render={events => (
        events.length === 0 ? <></> : (
          <ScrollingList maxHeight={maxHeight || fullHeight} {...loadProps}
            items={events} 
            title={"Care Plan"}
            titleStyle={{
              ...cardListTitleStyle,
              marginLeft: DASHBOARD_CHILD_MARGIN, marginRight: DASHBOARD_CHILD_MARGIN,
              marginBottom: 0,
            }}
            itemContainerStyle={{ 
              borderRadius: 15, 
              paddingTop: 5,
              padding: DASHBOARD_CHILD_MARGIN 
            }}
            Item={CarePlanEventCard}
          />
        )
      )} />
    </Grid>
  )
}

export const CarePlanHome = () => {
  const session = useEnduserSession()
  const [customizationsLoading] = usePortalCustomizations()
  const customization = (
    value_is_loaded(customizationsLoading) 
      ? customizationsLoading.value.find(c => c.page === 'Care Plan') 
      : undefined
  )
  return (
    <Grid container direction="column" alignItems="center" sx={{ mt: 3 }} >
      {customization?.headerImageURL &&
        <img src={customization.headerImageURL} alt="page header" style={{ maxHeight: '16vh', maxWidth: '80%', marginBottom: '10px' }} />
      }
      {session.userInfo.fname &&
        <Grid item sx={{ mb: 2 }}>
          <TitleWithFirstName ending="Care Plan" />
        </Grid>
      }
      <CarePlanOverview />
    </Grid>
  )
}