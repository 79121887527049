import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Checkbox, createTheme, FormControl, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Paper, Select, TextField, ThemeProvider, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { DayPicker } from 'react-day-picker';
import { getLocalTimezone, useBookingFlowContext, WithBookingFlowContext } from "./contexts";
import { BookingPage } from "./types";
import 'react-day-picker/dist/style.css';
import { DisplayPicture, FormikSubmitButton, HoverPaper, LabeledIconButton, LoadingButton, useEnduserSession, useEnduserSessionContext, WithEnduserSession } from "@tellescope/react-components";
import { BaseAvailabilityBlock, Timezone, TIMEZONES_USA, VALID_STATES } from "@tellescope/types-models";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useFormik }  from "formik"
import * as yup from "yup"
import { FormikTextField } from "../../components/inputs";
import { 
  yupDateMMDDYYYYValidatorRequired,
  // yupDateMMDDYYYYValidatorRequired, 
  yupPhoneRequiredValidator 
} from "../../definitions/schemas";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { PRIMARY_HEX, SECONDARY_HEX } from "@tellescope/constants";
import { CalendarEvent, Product } from "@tellescope/types-client";
import { differenceInCalendarDays } from "date-fns"
import { DateTime } from "luxon"

import {
  css,
} from "@emotion/css"
import { TellescopeHost } from "../../definitions/constants";

import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js'; 
import { CheckCircleOutline } from "@mui/icons-material"
import { get_add_to_gcal_link, payment_cost_to_string, user_display_name } from "@tellescope/utilities";
import { SetEnduserStateForm, StateInput } from "../AppointmentScheduling";
import { GoBackArrowIcon } from "../../components/icons";

const StripeInput = () => {
  const { stripe } = useBookingFlowContext()
  const [stripePromise, setStripePromise] = useState<ReturnType<typeof loadStripe>>()

  const fetchRef = useRef(false)
  useEffect(() => {
    if (!stripe) return

    if (fetchRef.current) return
    fetchRef.current = true

    setStripePromise(loadStripe(stripe.publishableKey, { stripeAccount: stripe.stripeAccount }))
  }, [stripe])

  if (!stripe) return null
  if (stripe.complete) {
    return (
      <Grid container alignItems="center" wrap="nowrap">
        <CheckCircleOutline color="success" />

        <Typography sx={{ ml: 1, fontSize: 20 }}>
          Your payment details have been saved!
        </Typography>
      </Grid>
    )
  }

  if (!stripePromise) return <LinearProgress />
  return (
    <Elements stripe={stripePromise} options={{
      clientSecret: stripe.clientSecret,
    }}>
      <StripeForm />
    </Elements>
  )
}

const StripeForm = () => {
  const { stripe, setStripe } = useBookingFlowContext()
  const Stripe = useStripe();
  const elements = useElements()

  const [ready, setReady] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event?.preventDefault();

    if (!Stripe) return
    if (!elements) return

    const {error} = await Stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: { 
        return_url: window.location.href, 
      },
      redirect: 'if_required', //  ensures the redirect url won't be used, unless the Bank redirect payment type is enabled (it's not, just card)
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error?.message ?? '');
    } else {
      setStripe(s => ({ ...s!, complete: true }))
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  if (!stripe) return null
  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onReady={() => setReady(true)} 
        options={{ business: { name: stripe.businessName } }}
      />
      <Button variant="contained" color="primary" type="submit" sx={{ mt: 1 }}
        disabled={!(stripe && ready)}
      >
        Save Payment Details
      </Button>
      {/* Show error message to your customers */}
      {errorMessage && 
        <Typography color="error" sx={{ mt: 0.5 }}>
          {errorMessage}
        </Typography>
      }
    </form>
  )
}

const MOBILE_FIRST_MAX_WIDTH = "320px";

export const ThankYou = () => {
  const { loadedInfo, bookedEvent } = useBookingFlowContext()
  const downloadICS = useDownloadICS()
  const [params] = useSearchParams()
  const entropy = params.get('entropy') || ''

  const emitRef = useRef(false)
  useEffect(() => {
    if (!bookedEvent) return
    if (emitRef.current) return 
    emitRef.current = true

    window.parent?.postMessage({ type: 'Booking Success', bookedEventId: bookedEvent.id, entropy }, "*")
  }, [bookedEvent, entropy])

  return (
    <Grid container direction="column" alignItems="center" sx={{ pt: 3 }} rowSpacing={3}>
      {loadedInfo?.appointmentBookingPage.thankYouHeaderImageURL &&
        <Grid item sx={{ textAlign: 'center' }}>
          <img src={loadedInfo?.appointmentBookingPage.thankYouHeaderImageURL} 
            alt="logo" style={{ textAlign: 'center', maxWidth: '90%', maxHeight: '37px' }} 
          />
        </Grid>
      }

      <Grid item>
      <Typography sx={{ fontSize: 26, opacity: 0.75, textAlign: 'center' }}>
        {loadedInfo?.appointmentBookingPage.thankYouTitle || "Appointment Confirmed"}
      </Typography>
      </Grid>

      {loadedInfo?.appointmentBookingPage.thankYouMainImageURL &&
        <Grid item sx={{ textAlign: 'center', my: 3 }}>
          <img src={loadedInfo?.appointmentBookingPage.thankYouMainImageURL} 
            alt="thanks" 
            style={{ maxWidth: '90%', maxHeight: '300px' }} 
          />
        </Grid>
      }

      <Grid item>
      <Typography sx={{ fontSize: 18, opacity: 0.60, textAlign: 'center' }}>
        {loadedInfo?.appointmentBookingPage.thankYouDescription || "Check your email for next steps"}
      </Typography>
      </Grid>

      {bookedEvent &&
        <>
        <Grid item>
          <LoadingButton variant="contained" 
            submitText="Add to Calendar" submittingText="Downloading..."
            onClick={() => downloadICS(bookedEvent)}
          />
        </Grid>
        <Grid item>
          <LoadingButton variant="outlined" 
            submitText="Add to Google Calendar" submittingText="Opening..."
            onClick={() => window.open(get_add_to_gcal_link(bookedEvent), "_blank")}
          />
        </Grid>
        </>
      }
    </Grid>
  )
}

export const Payment = () => {
  return (
    <Grid container>
      <Typography>
        Checkout securely using square!
      </Typography>
    </Grid>
  )
}

const ConfirmationEditDecorator = ({ onClick } : { onClick: () => void }) => (
  <InputAdornment position="end">
    <IconButton onClick={onClick}>
      <EditOutlinedIcon />
    </IconButton>
  </InputAdornment>
)

export const Products = () => {
  const {
    loadedInfo,
    template,
  } = useBookingFlowContext()

  if (!template?.productIds?.length) return null

  const products = template.productIds.map(id => loadedInfo?.products.find(p => p.id === id)).filter(p => p) as Product[]
  if (products.length === 0) return null

  return (
    <Grid container direction="column" spacing={0.5}>
    {products.map(p => (
      <Grid item key={p.id} alignSelf="center">
        <Grid container alignItems={"center"} justifyContent="center" 
          sx={{ 
            p: 1, 
            width: 225, minHeight: 50,
            backgroundColor: 'primary.main',
            borderRadius: 2,
          }}
        >
          <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: 27 }}>
            {p.cost.currency === 'USD' ? '$' : ''}{p.cost.amount / 100}
          </Typography> 
        </Grid>
      </Grid>
    ))}
    </Grid>
  )
}

const downloadFile = (data: Uint8Array | Blob | Buffer | string, options : { name?: string, dataIsURL?: boolean, type?: string}) => {
  let { name, type } = options
  name = name || "download.txt"
  type = type || "octet/stream"

  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none"

  const blob = new Blob([data], {type});
  const url = (
    (options.dataIsURL && typeof data === 'string')
      ? data
      : window.URL.createObjectURL(blob)
  )
  
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
}

const useDownloadICS = () => {
  const session = useEnduserSession()

  const downloadICS = useCallback(async (event : Pick<CalendarEvent, 'id'>) => {
    const url = `${session.host || 'https://api.tellescope.com'}/v1/calendar-events/download-ics-file?authToken=${session.authToken}&calendarEventId=${event.id}&excludeAttendee=true`

    try {
      downloadFile((await (await fetch(url)).arrayBuffer()) as any, { name: "event.ics", dataIsURL: true, type: 'text/calendar'})
    } catch(err) {
      console.error(err)
    }
  }, [session])

  return downloadICS
}

export const Confirmation = ({ rescheduledCalendarEventId } : { rescheduledCalendarEventId?: string }) => {
  const session = useEnduserSession()
  const {
    loadedInfo,
    location,
    template,
    selectedSlot,
    setPage,
    trackSuccess,
    timezone,
    bookingToken,
    fields,
    stripe,
    multi,
    userIds,
    setBookedEvent,
    selectedUsers,
    formResponseId,
    holdAppointmentMinutes,
    reason, setReason,
    scheduledBy,
  } = useBookingFlowContext() 

  const startTimeLocalized = DateTime.fromMillis(selectedSlot.startTimeInMS, { zone: timezone })

  const [agreed, setAgreed] = useState<boolean[]>(
    (loadedInfo?.appointmentBookingPage?.terms ?? [])?.map(t => false)
  )
  const [payInPerson, /*setPayInPerson*/] = useState(true)

  const emitRef = useRef(false)
  useEffect(() => {
    if (emitRef.current) return 
    emitRef.current = true

    window.parent?.postMessage({ type: 'Confirmation' }, "*")
  }, [])

  return (
    <Grid container direction="column" spacing={4} sx={{ px: 4, maxWidth: MOBILE_FIRST_MAX_WIDTH }}>
      <Grid item>
        <Typography sx={{ 
          textAlign: 'center', fontWeight: 'bold', fontSize: 30, color: 'black', opacity: 0.7,
          lineHeight: '33px',
        }}>
          Confirm your <br />
          appointment
        </Typography> 
      </Grid>

      {location && // there might not be a location...
        <Grid item>
          <TextField variant="standard" disabled fullWidth value={location?.title} 
            InputProps={{
              endAdornment: (
                (loadedInfo?.locations?.length ?? 0) > 1
                  ? <ConfirmationEditDecorator onClick={() => setPage(BookingPage.Calendar)} />
                  : null
              ),
              disableUnderline: true,
            }}
            sx={{
              borderBottom: '1px solid #00000077',
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
                opacity: 0.65,
              },
            }}          
          />
        </Grid>
      }

      <Grid item>
        <TextField variant="standard" disabled fullWidth value={template?.displayTitle || template?.title} 
          InputProps={{
            endAdornment: (
              (loadedInfo?.calendarEventTemplates?.length ?? 0) > 1 
                ? <ConfirmationEditDecorator onClick={() => setPage(BookingPage.PickAppointmentType)} />
                : null
            ),
            disableUnderline: true,
          }}
          sx={{
            borderBottom: '1px solid #00000077',
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
              opacity: 0.65,
            },
          }}
        />
      </Grid>

      <Grid item>
        <TextField variant="standard" disabled fullWidth 
          value={`${startTimeLocalized.toLocaleString(DateTime.DATETIME_MED)}`} 
          InputProps={{
            endAdornment: <ConfirmationEditDecorator onClick={() => setPage(BookingPage.Calendar)} />,
            disableUnderline: true,
          }}
          sx={{
            borderBottom: '1px solid #00000077',
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor: "#000000",
              opacity: 0.65,
            },
          }}
        />
      </Grid>

      <Grid item>
        <Typography sx={{ mt: 0.25, fontSize: 17, opacity: 0.65 }}>Timezone: {timezone}</Typography>
      </Grid>

      {loadedInfo?.appointmentBookingPage?.collectReason && loadedInfo?.appointmentBookingPage?.collectReason !== 'Do Not Collect' &&
        <Grid item>
          <TextField size="small" multiline minRows={2} label="Reason for Appointment" 
            value={reason} onChange={e => setReason(e.target.value)}
            required={loadedInfo.appointmentBookingPage.collectReason === 'Required'}
          />
        </Grid>
      }

      {(loadedInfo?.appointmentBookingPage.terms ?? []).length > 0 &&
        <Grid item sx={{ mx: 3 }} alignSelf="center">
          <Grid container justifyContent="start">
          {loadedInfo?.appointmentBookingPage?.terms?.map((t, i) => (
            <Grid key={t.title} item>
            <Grid container alignItems={'center'} justifyContent="space-between" wrap="nowrap">
              <Checkbox checked={!!agreed[i]} 
                onChange={() => setAgreed(a => a.map((b, _i) => _i === i ? !b : b))} 
              />

              <Typography style={{ color: 'black', opacity: 0.65, fontSize: 14 }}>
                I agree to the <a href={t.link} target="_blank" rel="noopener noreferrer"
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    opacity: 0.85,
                  }}
                >
                  {t.title}
                </a>
              </Typography>
            </Grid>
            </Grid>
          ))}
          </Grid>
        </Grid>
      }

      {/* {(template?.productIds?.length ?? 0) > 0 &&
        <Grid item sx={{ mb: 3 }}>
          <Products />
        </Grid>
      } */}

      {stripe &&
        <Grid item>
        <Grid container direction="column">
          {(loadedInfo?.products ?? []).length > 0 && 
            <Grid item sx={{ mb: 3 }}>
              <Typography>
                Your card will be charged {payment_cost_to_string({
                  amount: loadedInfo!.products.reduce((a, b) => a + b.cost.amount, 0),
                  currency: 'USD',
                })} for this appointment
              </Typography>
            </Grid>
          }
          <StripeInput />    
        </Grid>
        </Grid>
      }

      <Grid item alignSelf="center">
        <LoadingButton variant="contained" submitText="Confirm"
          onClick={async () => {
            if (!payInPerson) return setPage(BookingPage.Payment)

            let event = undefined as CalendarEvent | undefined
            try {
              const result = await session.api.calendar_events.book_appointment({
                calendarEventTemplateId: template?.id!,
                startTime: new Date(selectedSlot.startTimeInMS),
                userId: selectedSlot.userId,
                locationId: location?.id,
                agreedToTerms: loadedInfo?.appointmentBookingPage.terms,
                bookingPageId: loadedInfo?.appointmentBookingPage.id,
                rescheduledCalendarEventId,
                token: bookingToken,
                timezone,
                fields,
                customerId: stripe?.customerId,
                otherUserIds: (
                  selectedUsers.length > 1
                    ? selectedUsers.filter(u => u.id !== selectedSlot.userId).map(u => u.id)
                    : multi 
                      ? userIds 
                      : []
                ),
                holdFormResponseId: formResponseId,
                holdUntil: holdAppointmentMinutes ? new Date(Date.now() + (holdAppointmentMinutes * 60 * 1000)) : undefined,
                reason,
                scheduledBy,
              }) 
              event = result.createdEvent
            } catch(err) { // try again quietly without timezone on error
              const result = await session.api.calendar_events.book_appointment({
                calendarEventTemplateId: template?.id!,
                startTime: new Date(selectedSlot.startTimeInMS),
                userId: selectedSlot.userId,
                locationId: location?.id,
                agreedToTerms: loadedInfo?.appointmentBookingPage.terms,
                bookingPageId: loadedInfo?.appointmentBookingPage.id,
                token: bookingToken,
                rescheduledCalendarEventId,
                fields,
                customerId: stripe?.customerId,
                otherUserIds: multi ? userIds : [],
                holdFormResponseId: formResponseId,
                holdUntil: holdAppointmentMinutes ? new Date(Date.now() + (holdAppointmentMinutes * 60 * 1000)) : undefined,
                reason,
                scheduledBy,
              }) 
              event = result.createdEvent
            }
            
            trackSuccess()

            if (loadedInfo?.appointmentBookingPage.thankYouRedirectURL) {
              return (window?.top ?? window).location.href = loadedInfo.appointmentBookingPage.thankYouRedirectURL
            }

            setBookedEvent(event)

            setPage(BookingPage.ThankYou)
          }}
          style={{ 
            borderRadius: 8, width: 200, textTransform: 'none', fontWeight: 'bold',
          }}
          disabled={
            agreed.includes(false) 
            || (stripe && !stripe.complete)
            || (loadedInfo?.appointmentBookingPage?.collectReason === 'Required' && !reason)
          }
        />
      </Grid>
    </Grid>
  )
}


export const Intake = () => {
  const { 
    enduserSession: session, 
    updateLocalSessionInfo 
  } = useEnduserSessionContext()
  const { 
    loadedInfo,
    intakeInfo,
    setIntakeInfo,
    template,
    setPage,
    setStripe,
    rescheduledCalendarEventId, 
    restrictByState,
  } = useBookingFlowContext()
  const [error, setError] = useState('')

  const validationSchema = useMemo(() =>  yup.object({
    fname: yup.string().required('First name is required'),
    lname: yup.string().required('Last name is required'),
    email: yup.string().email("Must be a valid email").required('Email is required'),
    dateOfBirth: yupDateMMDDYYYYValidatorRequired,
    phone: yupPhoneRequiredValidator,
    state: (
      restrictByState
        ? yup.string().required("State is required")
        : yup.string()
    ),
  }), [restrictByState])

  // skip intake for user who already has all of this information
  useEffect(() => {
    if (!session.authToken) return
    if (
      // @ts-ignore
      session.userInfo?.isUpsertedAccount
    ) {
      return
    }

    // no need to load stripe details if no products
    if (!template?.productIds?.length) {
      if (restrictByState) {
        // calendar comes after intake when restrictByState
        return setPage(BookingPage.Calendar)
      } else {
        return setPage(BookingPage.Confirmation)
      }
    } 

    // copied in BookingPage.Calendar for restrictByState case
    session.api.calendar_events.stripe_details({ })
    .then(({ stripe }) => {
      // don't collect payment on reschedule
      if (stripe && !rescheduledCalendarEventId) { 
        setStripe(stripe) 
      }
    })
    .catch(console.error)
    .finally(() => {
      if (restrictByState) {
        // calendar comes after intake when  limitedByState
        setPage(BookingPage.Calendar)
      } else {
        setPage(BookingPage.Confirmation)
      }
    })
  }, [session, setPage, setStripe, template, rescheduledCalendarEventId, restrictByState])

  const formik = useFormik({
    validationSchema,
    initialValues: {
      fname: intakeInfo?.fname || '',
      lname: intakeInfo?.lname || '',
      email: intakeInfo?.email || '',
      phone: intakeInfo?.phone || '',
      dateOfBirth: intakeInfo?.dateOfBirth || '',
      state: intakeInfo?.state || '',
    },
    onSubmit: (values) => {
      setIntakeInfo(values)
      setError('')
      session.api.calendar_events.session_for_public_appointment_booking({
        businessId: loadedInfo?.appointmentBookingPage.businessId!,
        organizationIds: loadedInfo?.appointmentBookingPage.organizationIds,
        calendarEventTemplateId: template?.id!,
        ...values,
      })
      .then(({ authToken, stripe }) => {
        session.authToken = authToken
        updateLocalSessionInfo({ 
          ...values, 
          // @ts-ignore
          isUpsertedAccount: true,
        }, authToken
        )
        // don't collect payment on reschedule
        if (stripe && !rescheduledCalendarEventId) {
          setStripe(stripe) 
        }

        // if limited by state, Calendar comes after Intake, not before
        if (restrictByState) {
          setPage(BookingPage.Calendar)
        } else {
          setPage(BookingPage.Confirmation)
        }
      })
      .catch(err => {
        formik.setSubmitting(false)
        setError(err?.message)
      })
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
    <Grid container direction="column" sx={{ px: 4, maxWidth: MOBILE_FIRST_MAX_WIDTH }} spacing={2}>
      <Grid item>
        <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 36, color: 'black', opacity: 0.65 }}>
          {loadedInfo?.appointmentBookingPage.intakeTitle || ""}
        </Typography>
      </Grid>

      <Grid item sx={{ mt: 1, mb: 4 }}>
      <Typography sx={{ textAlign: 'center', fontSize: 20, opacity: 0.6  }}>
        {loadedInfo?.appointmentBookingPage.intakeDescription || "Enter your contact information"}
      </Typography>
      </Grid>

      <Grid item sx={{ width: '100%'}}>
        <FormikTextField formik={formik} required fullWidth field="fname" label="First Name" variant="standard" />
      </Grid>

      <Grid item sx={{ width: '100%'}}>
        <FormikTextField formik={formik} required fullWidth field="lname" label="Last Name" variant="standard" />
      </Grid>

      <Grid item sx={{ width: '100%'}}>
        <FormikTextField formik={formik} required fullWidth field="phone" label="Phone" variant="standard" />
      </Grid>

      <Grid item sx={{ width: '100%'}}>
        <FormikTextField formik={formik} required fullWidth field="email" label="Email" variant="standard" />
      </Grid>

      <Grid item sx={{ width: '100%'}}>
        <FormikTextField formik={formik} required fullWidth field="dateOfBirth" label="Birth Date" variant="standard" 
          placeholder="MM-DD-YYYY"
          onChangeModifier={v => 
            (
              v.length === 2 && /\d{2}/.test(v) && formik.values['dateOfBirth']?.toString()?.length !== 3 // allow deletion
                ? v + '-'
              : v.length === 5 && /\d{2}-\d{2}/.test(v) && formik.values['dateOfBirth']?.toString()?.length !== 6 // allow deletion
                ? v + '-'
                : v
            )
            .replaceAll('/', '-')
          }
        />
      </Grid>

      {restrictByState &&
        <Grid item sx={{ width: '100%'}}>
          <StateInput value={formik.values.state}  
            onChange={state => { formik.setFieldTouched('state'); formik.setFieldValue('state', state); }}
          />
        </Grid>
      }
      
      <Grid item sx={{ mt: 8 }} alignSelf="center">
        <FormikSubmitButton formik={formik} submitText="Next" 
          disabledIfUnchanged={false} // may be pre-filled if they navigate backwards, need to enable in this case
          disabled={!formik.values.email} // otherwise shows as clickable when no fields filled for some reason
          style={{ 
            borderRadius: 10,
            width: 175,
            textAlign: 'center',
            textTransform: 'none', 
            fontWeight: 'bold',
          }}
        />
      </Grid>

      {error &&
        <Grid item sx={{ width: '100%'}}>
          <Typography color="error">{error}</Typography>
        </Grid>
      }
    </Grid>
    </form>
  )
}

export const appointment_slot_display_time = (startTime: number, timezone: Timezone) => (
  DateTime
  .fromMillis(startTime, { zone: timezone })
  .toLocaleString({
    hour: '2-digit',
    minute: '2-digit',    
    hour12: true,
  })
)

const APPOINTMENT_SLOTS_MAX_HEIGHT = 135
export const AppointmentSlots = ({ fromDate } : { fromDate?: Date }) => {
  const { 
    loadedSlots,
    selectedSlot,
    setSelectedSlot,
    setPage,
    disabledDays,
    timezone,
    template,
    noUsersFound,
    error,
    restrictByState,
    loadedInfo,
  } = useBookingFlowContext()
  
  const matchingSlots: BaseAvailabilityBlock[] = []

  let beforeFromDate = false
  for (const s of loadedSlots[template?.id ?? ''] ?? []) {
    // don't add duplicate slots for the same time
    if (
      matchingSlots.find(_s => _s.startTimeInMS === s.startTimeInMS)
    ) {
      continue
    }

    const slotDate = DateTime.fromMillis(s.startTimeInMS, { zone: timezone }).toJSDate()
    const selectedDate = DateTime.fromMillis(selectedSlot.startTimeInMS, { zone: timezone }).toJSDate()

    // don't loaded slots which come before valid start date
    if (fromDate && fromDate.getTime() >= slotDate.getTime()) {
      beforeFromDate = true
      continue
    }

    if (
      slotDate.getDate() === selectedDate.getDate()
      && slotDate.getMonth() === selectedDate.getMonth()
      && slotDate.getFullYear() === selectedDate.getFullYear()
    ) {
      matchingSlots.push(s)
    }
  }

  const maxHeight = loadedInfo?.appointmentBookingPage.appointmentSlotsMaxHeight || APPOINTMENT_SLOTS_MAX_HEIGHT
  const showScroll = matchingSlots.length * 28 > maxHeight

  return (
    <Grid container direction="column">
      <Grid container alignItems="center" justifyContent={"space-between"} sx={{ mb: 1 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
          Select a visit time:
        </Typography>
        
        <Grid item xs={12}>
        {matchingSlots.length === 0 
          ? (
            !!disabledDays.find(d => is_same_day(d, new Date(selectedSlot.startTimeInMS)))
          || beforeFromDate
          )
            ? <Typography sx={{ fontSize: 15 }}>
                No appointments are available at this date
              </Typography>
          : noUsersFound
            ? <Typography sx={{ fontSize: 15 }}>
                No users found
              </Typography>
            : <Typography sx={{ fontSize: 15 }}>{error ? '' : "Loading..."}</Typography>
          : null
        }
        </Grid>

        {error && <Typography color="error" sx={{ }}>{error}</Typography>}
        {/* {selectedSlot.durationInMinutes !== -1 &&
          <Button variant="contained" >
            Confirm
          </Button>
        } */}
      </Grid>

      <Grid container spacing={1} sx={{ 
        maxHeight,
        overflowY: showScroll ? 'scroll' : 'auto',
      }}
        className={css`
          ::-webkit-scrollbar {
            width: 3px;
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }
          ::-webkit-scrollbar-thumb {
            color: #333333;
            background-color: #33333399;
            outline: 1px solid #33333399;
          }          
        `}
      >
      {matchingSlots.map(s => {
        const selected = selectedSlot.durationInMinutes !== -1 && selectedSlot.startTimeInMS === s.startTimeInMS
        return (
          <Grid key={s.startTimeInMS} item xs={6} sx={{ pr: 1 }}
            onClick={() => {
              if (selected) {
                return setSelectedSlot({ 
                  startTimeInMS: s.startTimeInMS, // start with current day selected
                  durationInMinutes: -1, // indicate no slot selected
                  userId: '', // indicate no slot selected
                })
              }
              setSelectedSlot(s)

              if (restrictByState) {
                // intake is already done
                setPage(BookingPage.Confirmation)
              } else {
                setPage(BookingPage.Intake)
              }
            }}
          >
            <Typography sx={{ 
              textAlign: 'center',
              border: '1.5px solid #00000099',
              borderRadius: 2,
              padding: 1,
              fontSize: 17,
              color: selected ? '#ffffff' : undefined,
              backgroundColor: selected ? '#00000099' : undefined,
            }}>
              {appointment_slot_display_time(s.startTimeInMS, timezone)} 
            </Typography>
          </Grid>
        )
      })}
      </Grid>

      {showScroll &&
        <Typography sx={{ fontSize: 13, opacity: 0.8, mt: 1.5, textAlign: 'center' }}>
          {
            ['ipad','iphone','ipod']
            .find(s => navigator.userAgent.toLowerCase().includes(s))
              ? "Tap and scroll down to view more times"
              : "Scroll down to view more times"
          }
          
          {/* Scroll down to see more appointments */}
        </Typography>
      }
    </Grid>
  )
}

export const CalendarLocation = () => {
  const { 
    location,
    setLocation,
    setTimezone,
    loadedInfo,
    setLoadedSlots,
    setDisabledDays,
    setLocationToggleCount,
  } = useBookingFlowContext()

  if ((loadedInfo?.locations.length ?? 0) === 0) return null 
  if (loadedInfo?.locations.length === 1) {
    return (
      <Grid container alignItems={"center"} justifyContent="center"
        sx={{ 
          p: 0.8,
          width: 280,
          border: '1.5px solid #00000099',
          borderRadius: 3,
        }}
      >
        <Typography noWrap sx={{ fontWeight: 'bold', fontSize: 14 }}>
          {loadedInfo?.locations[0].title}
        </Typography>
      </Grid>
    )
  }
  return (
    <FormControl sx={{ width: 280 }} size="small">
      <InputLabel id="demo-simple-select-label">
        Location
      </InputLabel>
      <Select size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={location?.title ?? ''}
        label="Location"
        onChange={e => {
          const location = loadedInfo?.locations.find(l => l.title === e.target.value)
          if (!location) return
          setLocation(location)

          // need to reload availability for new location
          setLoadedSlots({}) 
          setDisabledDays([])
          setLocationToggleCount(t => t+1)
          
          if (!location.timezone) return
          setTimezone(location.timezone)
        }}
      >
        {loadedInfo?.locations.map(l => (
          <MenuItem key={l.id} value={l.title}>{l.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export const TimezoneSelector = () => {
  const { timezone, setTimezone, } = useBookingFlowContext()

  return (
    <FormControl sx={{ width: 280 }}>
      <InputLabel id="demo-simple-select-label">
        Timezone
      </InputLabel>
      <Select size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={timezone}
        label="Timezone"
        onChange={e => setTimezone(e.target.value as Timezone)}
      >
        {Array.from(new Set([...TIMEZONES_USA, getLocalTimezone()])).map(l => (
          <MenuItem key={l} value={l}>{l}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const is_same_day = (d1: Date, d2: Date) => (
     d1.getDate() === d2.getDate() 
  && d1.getMonth() === d2.getMonth()
  && d1.getFullYear() === d2.getFullYear()
)

const Calendar = ({ isRescheduling } : { isRescheduling?: boolean }) => {
  const { selectedUsers, userTags } = useBookingFlowContext()

  // if rescheduling, skip user selection and pick original hosts
  if (isRescheduling) {
    return <CalendarPicker isRescheduling={isRescheduling} />
  }

  if (userTags?.length && selectedUsers.length < userTags.length) {
    return <UsersPicker  />
  } 

  return (
    <CalendarPicker />
  )
}

const UsersPicker = () => {
  const session = useEnduserSession()
  const [params] = useSearchParams()
  const { restrictByState, loadedInfo, userTags=[], selectedUsers=[], setSelectedUsers, } = useBookingFlowContext()
  const tag = userTags?.[selectedUsers.length] || ''

  const state = params.get('state') || session.userInfo.state  || ''
  const users = (loadedInfo?.users || []).filter(u => 
    tag
    && u.tags?.includes(tag)
    && (
      !restrictByState
      || u.credentialedStates?.find(c => c.state === state)
    )
  )

  const emitRef = useRef(false)
  useEffect(() => {
    if (emitRef.current) return 
    emitRef.current = true

    window.parent?.postMessage({ type: 'UsersPicker' }, "*")
  }, [])

  if (!users?.length) {
    return <Typography color="error">No valid appointment hosts found</Typography>
  }
  return (
    <Grid container direction="column" alignItems="center" spacing={1}
      sx={{ width: '100%', maxWidth: 500 }}
    >
      {userTags.length > 1 && 
        <>
        <Grid container alignItems="center" justifyContent={"center"} spacing={1}>
          {selectedUsers.length > 0 && 
            <Grid item>
              <LabeledIconButton Icon={GoBackArrowIcon} label="Back"
                onClick={() => setSelectedUsers(us => us.slice(0, us.length -1))} 
                size={20}
              />
            </Grid>
          }

          <Grid item>
            <Typography>{selectedUsers.length + 1} of {userTags.length}</Typography>
          </Grid>
        </Grid>
        </>
      }

      <Grid item sx={{ width: '100%' }}>
      <Grid container direction="column" spacing={1} wrap="nowrap"
        sx={{ maxHeight: 400, overflowY: 'auto' }}
      >
      {users.map(u => (
        <Grid item key={u.id} onClick={() => setSelectedUsers(us => [...us, u])} sx={{ mr: 1 /* extra spacing for scrollbar */}}>
        <HoverPaper hoveredElevation={8} style={{ margin: 8, width: 'calc(100% - 16px)', borderRadius: 10 }}>
        <Grid container alignItems="center" wrap="nowrap" spacing={1}
          sx={{ p: 1 }}
        >
          <Grid item sx={{ width: 60 }}>
            <DisplayPicture src={u.avatar} size={50} />
          </Grid>

          <Grid item>
          <Grid container direction="column" spacing={0.5}>
            <Grid item>
            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
              {user_display_name(u)}
            </Typography>
            </Grid>

            {u.bio?.trim() &&
              <Grid item title={u.bio}>
                <Typography sx={{ 
                  width: '100%', maxHeight: 70,
                  overflowY: 'clip', 
                  fontSize: 12,
                  '&:hover': { overflowY: "scroll" },
                }}>
                  {u.bio.split('\n').map((row, i) => (
                    <React.Fragment key={i}>
                      {row}<br/>
                    </React.Fragment>
                  ))}
                </Typography> 
              </Grid>
            }
          </Grid>
          </Grid>
        </Grid>
        </HoverPaper>
        </Grid>
      ))} 
      </Grid>
      </Grid>

    </Grid>
  )
}

const DEFAULT_SELECTED_SIZE = 45
const SELECTED_SIZE_ADJUSTMENT = 10
const MS_IN_ONE_DAY = 1000 * 60 * 60 * 24
export const CalendarPicker = ({ isRescheduling } : { isRescheduling?: boolean }) => {
  const session = useEnduserSession()
  const { 
    loadedInfo,
    template, setTemplate,
    location,
    selectedSlot,
    setLoadedSlots,
    setSelectedSlot,
    loadedSlots,
    disabledDays, setDisabledDays,
    timezone,
    userId,
    minDate,
    maxDate,
    isAuthenticated,
    userIds,
    multi,
    selectedUsers, setSelectedUsers,
    locationToggleCount,
    noUsersFound,
    setError,
    restrictByState, restrictByCareTeam,
    hoursBeforeRestriction, hoursAfterRestriction,
  } = useBookingFlowContext()

  const emitRef = useRef(false)
  useEffect(() => {
    if (emitRef.current) return 
    emitRef.current = true

    window.parent?.postMessage({ type: 'CalendarPicker' }, "*")
  }, [])

  const loadByDayRef = useRef({ } as Record<`${string}-${number}-${string}-${number}`, boolean>)
  const templateRef = useRef('')

  useEffect(() => {
    if (!template) return
    if (noUsersFound) return

    // don't load availability until location is known
    if ((loadedInfo?.locations || []).length > 1 && !location) {
      return
    }

    // a real slot is selected for same template/day 
    if (selectedSlot.durationInMinutes !== -1 && template.id === templateRef.current) {
      return
    }
    templateRef.current = template.id

    // we already fetched slots for this template, day,  and location
    if (loadByDayRef.current[`${template.id}-${selectedSlot.startTimeInMS}-${location?.id ?? ''}-${locationToggleCount}`]) return
    loadByDayRef.current[`${template.id}-${selectedSlot.startTimeInMS}-${location?.id ?? ''}-${locationToggleCount}`] = true

    const fromDefault = (
      selectedSlot.startTimeInMS < Date.now()
        ? new Date(Date.now() + 1000)
        : new Date(selectedSlot.startTimeInMS)
    ) 
    const from = (
      minDate && (minDate.getTime() > fromDefault.getTime())
        ? minDate
        : fromDefault
    )

    const toDefault = new Date(from.getTime() + MS_IN_ONE_DAY * 40)
    const to = (
      maxDate && (maxDate.getTime() < toDefault.getTime())
        ? maxDate 
        : toDefault
    )

    if (to.getTime() < from.getTime()) return 

    session.api.calendar_events.get_appointment_availability({ 
      userId,
      calendarEventTemplateId: template.id,
      from,
      to,
      limit: 1000,
      locationId: location?.id,
      businessId: template.businessId,
      restrictedByState: restrictByState,
      // restrictions
      userIds: (
        selectedUsers.length
          ? selectedUsers.map(u => u.id)
          : (
            userIds?.length 
              ? userIds.filter(userId => restrictByCareTeam ? session?.userInfo?.assignedTo?.includes(userId) : true)
              : (
                restrictByCareTeam
                  ? (
                    !userId // when a userId is provided, don't include whole care team
                      ? session.userInfo.assignedTo
                      : undefined
                  )
                  : undefined
              )
          )
      ),
      multi: multi || selectedUsers.length > 1, // default to multi when picking multiple users
    })
    .then(({ availabilityBlocks: _availabilityBlocks }) => {
      const newlyDisabled: Date[] = []

      const availabilityBlocks = (
        _availabilityBlocks
        // prevent last-minute booking when disabled
        .filter(b => 
          (
            hoursBeforeRestriction
              ? b.startTimeInMS > (Date.now() + 1000 * 60 * 60 * hoursBeforeRestriction)
              : true
          ) 
          && (
            hoursAfterRestriction
              ? b.startTimeInMS <= (Date.now() + 1000 * 60 * 60 * hoursAfterRestriction)
              : true
          )
          
        )
        // ensure sorted from earlier to later in time
        .sort((a1, a2) => a1.startTimeInMS - a2.startTimeInMS)
      )

      if (!availabilityBlocks.find(a => is_same_day(new Date(a.startTimeInMS), new Date(selectedSlot.startTimeInMS)))) {
        newlyDisabled.push(new Date(selectedSlot.startTimeInMS))
      }

      // no availability blocks in "from" to "to"
      if (availabilityBlocks.length === 0) {
        newlyDisabled.push(new Date(selectedSlot.startTimeInMS))
        newlyDisabled.push(to)
        let loopMS = selectedSlot.startTimeInMS + MS_IN_ONE_DAY // add 1 day at a time until we hit to date
        while (loopMS < to.getTime()) {
          newlyDisabled.push(new Date(loopMS))
          loopMS += + MS_IN_ONE_DAY // add 1 day at a time until we hit to date
        }
      }

      availabilityBlocks.forEach((b, i) => {
        const previous = (
          i === 0
            ? selectedSlot // query from this date
            : availabilityBlocks[i - 1]
        )

        const prevDay = new Date(previous.startTimeInMS)
        const day = new Date(b.startTimeInMS)


        const daysApart = differenceInCalendarDays(day, prevDay)

        for (let i = daysApart; i > 1; i--) {
          newlyDisabled.push(new Date(prevDay.getTime() + (i - 1) * MS_IN_ONE_DAY))
        }
      })

      if (newlyDisabled.length > 0) {
        setDisabledDays(ds => [...ds, ...newlyDisabled])
      }

      setLoadedSlots(existing => ({
        ...existing,
        [template.id]: [
          ...(existing[template.id] ?? []), 
          ...availabilityBlocks.filter(s => !existing[template.id]?.find(e => e.startTimeInMS === s.startTimeInMS && e.userId === s.userId))
        ]
      }))
    })
    .catch((err: any) => {
      setError(err?.message)
      console.error(err)
    })
  }, [selectedSlot, location, session, template, setLoadedSlots, setDisabledDays, userId, maxDate, minDate, loadedInfo?.appointmentBookingPage, loadedInfo?.locations, userIds, multi, selectedUsers, locationToggleCount, noUsersFound, setError, hoursBeforeRestriction, hoursAfterRestriction, restrictByCareTeam, restrictByState])

  // todo: disabling days https://react-day-picker.js.org/basics/modifiers#disabling-days
  // todo: style modifiers https://react-day-picker.js.org/basics/styling#styling-modifiers
      // styles prop seems best to avoid css

  const fromDate = minDate || (
    DateTime
    .fromMillis(Date.now(), { zone: timezone })
    .plus({ 
      day: isRescheduling ? 1 : 0 // don't allow same day reschedules by default
    })
    .toJSDate()
  )

  if ( isAuthenticated
    && !session.userInfo.assignedTo?.length 
    && restrictByCareTeam
  ) {
    return (
      <Typography>Your care team must be assigned before booking this appointment type</Typography>
    )
  }
  if (!loadedInfo?.appointmentBookingPage || !template) return <LinearProgress />
  return ( 
    // need this outer justifyCenter because we use a lower width than mobile_first... 
    <Grid container justifyContent={'center'}>
    <Grid container direction="column" alignItems="center" 
      sx={{ 
        maxWidth: MOBILE_FIRST_MAX_WIDTH, 
      }}
    > 
      <Grid container sx={{ px: 2 }}>

      {selectedUsers.length > 0 &&
        <Grid item sx={{ mb: 1 }}>
        <Grid container alignItems='center' justifyContent={"center"} wrap="nowrap" spacing={1}>
          <Grid item>
          <LabeledIconButton Icon={GoBackArrowIcon} label="Back to host selection"
            onClick={() => {
              setLoadedSlots({}) 
              setDisabledDays([])
              setSelectedUsers(us => us.slice(0, us.length -1))
            }} 
            size={25}
          />
          </Grid>

          <Grid item>
          <Typography>
            Booking {selectedUsers.map(user_display_name).join(', ')}
          </Typography>
          </Grid>
        </Grid>
        </Grid>
      }

      <Grid item sx={{ mb: 3 }}>
        <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 27, color: 'black', opacity: 0.75 }}>
          Pick a date and time for your visit
        </Typography> 
      </Grid>

      {(loadedInfo?.calendarEventTemplates?.length ?? 0) > 1 &&
        <Grid item>
          <FormControl sx={{ width: 280 }}>
            <InputLabel id="demo-simple-select-label">
              Event Type
            </InputLabel>
            <Select size="small"
              labelId="event-select-label"
              id="event-select"
              value={template.displayTitle ?? template?.title ?? ''}
              label="Event Type"
              onChange={e => {
                const event = loadedInfo?.calendarEventTemplates.find(l => 
                  (l.displayTitle && l.displayTitle === e.target.value)
                  || l.title === e.target.value
                )
                if (!event) return
                setTemplate(event) 
                setDisabledDays([])
                setSelectedSlot({
                  startTimeInMS: Date.now(), // start with current day selected
                  durationInMinutes: -1, // indicate no slot selected
                  userId: '', // indicate no slot selected
                })
              }}
            >
              {loadedInfo?.calendarEventTemplates.map(l => (
                <MenuItem key={l.id} value={l.displayTitle || l.title}>{l.displayTitle || l.title}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }

      <Grid item sx={{ mt: (loadedInfo?.calendarEventTemplates?.length ?? 0) > 1 ? 2 : 0 }}>
        <CalendarLocation />
      </Grid>

      <Grid item sx={{ mt: 2 }}>
        <TimezoneSelector />
      </Grid>

      </Grid>

      {/* Don't show day picker until location is picked first, or there are no locations to pick from */}
      {(!loadedInfo?.locations?.length || !!location) &&
        <>
        {!noUsersFound &&
          <DayPicker disabled={disabledDays} 
            onMonthChange={month => {
              setSelectedSlot({
                startTimeInMS: (
                  month.getTime() < Date.now()
                    ? Date.now()
                    : month.getTime()
                ),
                durationInMinutes: -1,
                userId: '',
              })
            }}
            mode="single" showOutsideDays
            selected={
              DateTime.fromMillis(selectedSlot.startTimeInMS, { zone: timezone }).toJSDate()
            } 
            onSelect={(startTime=new Date()) => setSelectedSlot(() => ({ 
              startTimeInMS: startTime.getTime(),
              // unselect slot by resetting this information on selecting a new date
              durationInMinutes: -1,
              userId: '',
            }))} 
            fromDate={fromDate}
            toDate={maxDate}
            styles={{
              cell: { padding: 0.25 },
              day: {}, // the number within a day
              nav_button_next: { // keeps background consistent after click
                backgroundColor: loadedInfo?.appointmentBookingPage.backgroundColor,
              },
              nav_button_previous: { // keeps background consistent after click
                backgroundColor: loadedInfo?.appointmentBookingPage.backgroundColor,
              },
            }}
            // show available days as a custom modifier
            modifiers={{ 
              available: (
                (loadedSlots[template?.id ?? ''] ?? [])
                .filter(s => !is_same_day(
                  DateTime.fromMillis(s.startTimeInMS, { zone: timezone }).toJSDate(), 
                  DateTime.fromMillis(selectedSlot.startTimeInMS, { zone: timezone }).toJSDate(),
                ))
                .map(s => 
                  DateTime.fromMillis(s.startTimeInMS, { zone: timezone }).toJSDate()
                ) 
              )
            }}
            modifiersStyles={{
              selected: {
                backgroundColor: loadedInfo?.appointmentBookingPage.primaryColor,
                height: DEFAULT_SELECTED_SIZE - SELECTED_SIZE_ADJUSTMENT, width: DEFAULT_SELECTED_SIZE - SELECTED_SIZE_ADJUSTMENT,
                marginLeft: SELECTED_SIZE_ADJUSTMENT / 2, 
              },
              available: {
                border: '2px solid #00000060',
                height: DEFAULT_SELECTED_SIZE - SELECTED_SIZE_ADJUSTMENT, width: DEFAULT_SELECTED_SIZE - SELECTED_SIZE_ADJUSTMENT,
                marginLeft: SELECTED_SIZE_ADJUSTMENT / 2, 
              }
            }}
          />
        }

        <Grid container sx={{ px: 2, pt: noUsersFound ? 2 : 0 }}>
          <AppointmentSlots fromDate={fromDate} />
        </Grid>
        </>
      }

     
    </Grid>
    </Grid>
  )
}

const USER_DISPLAY_MAX_WIDTH = '250px'

export const UserDisplay = () => {
  const { loadedInfo, template } = useBookingFlowContext()
  if (!loadedInfo?.userDisplayName) return null

  return (
    <Grid container direction="column" alignItems="center" spacing={2} sx={{
      mt: {
        xs: undefined,
        sm: '170px'
      }
    }}>
      <Grid item>
      <Paper elevation={8} sx={{ borderRadius: '100%', width: 100, height: 100 }}>
        <DisplayPicture src={loadedInfo.userAvatar} size={100} />
      </Paper>
      </Grid>

      <Grid item>
      <Typography sx={{ fontSize: 27, mt: 0.5 }}>
        {loadedInfo.userDisplayName}
      </Typography>
      </Grid>
      
      {template &&
        <Grid item>
        <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>
          {template.durationInMinutes} Minute Appointment
        </Typography>
        </Grid>
      }
    </Grid>
  )
}

const EventSelector = () => {
  const { setTemplate, loadedInfo, setPage, restrictByState, } = useBookingFlowContext()

  const templates = loadedInfo?.calendarEventTemplates ?? []
  if (templates.length === 0) return null 

  return (
    <Grid container direction="column" 
      sx={{ px: 1, maxWidth: MOBILE_FIRST_MAX_WIDTH }} 
    >
    <Grid container justifyContent={"center"}>
      <Typography sx={{ fontSize: 22, textAlign: 'center', mb: 1 }}>
        What would you like to schedule?
      </Typography>

      <Grid item 
        sx={{
          maxHeight: '500px',
          overflowY: 'auto',
        }}
        className={css`
          ::-webkit-scrollbar {
            width: 3px;
          }
          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          }
          ::-webkit-scrollbar-thumb {
            color: #333333;
            background-color: #33333399;
            outline: 1px solid #33333399;
          }          
        `} 
      >
      {templates.map(t => (
        <Grid item key={t.id} 
          sx={{ 
            my: 1,
            cursor: 'pointer',
          }}
          onClick={() => {
            setTemplate(t)
            if (restrictByState) {
              return setPage(BookingPage.Intake)
            }
            setPage(BookingPage.Calendar)
          }}
        >
        <Grid container sx={{
          padding: 2,
          borderRadius: 4,
          border: '1px solid #aaaaaa',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          }
        }}>
          <Typography>
            {t.displayTitle || t.title}
          </Typography>
        </Grid>
        </Grid>
      ))}
    </Grid>
    </Grid>
    </Grid>
  )
}

const DEFAULT_FONT_FACE = (
`font-family: 'Domine', serif;
src: {
  url ('https://fonts.googleapis.com/css2?family=Domine:wght@400;500;600;700&display=swap') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
};`
)

const PublicAppointmentBookingPageWithContext = () => {
  const session = useEnduserSession()
  const { 
    page, setPage, 
    userId, 
    loadedInfo, 
    isAuthenticated, 
    rescheduledCalendarEventId,
    skipState,
    restrictByState,
    setLoadedSlots,
    setDisabledDays,
  } = useBookingFlowContext()

  return (
    <>
    {loadedInfo?.appointmentBookingPage?.fontURL &&
      <>
      <link href={loadedInfo.appointmentBookingPage?.fontURL} rel="stylesheet" />
      </>
    }
    <ThemeProvider theme={createTheme({
      palette: {
        primary: {
          main: loadedInfo?.appointmentBookingPage?.primaryColor ?? PRIMARY_HEX,
        },
        secondary: {
          main: loadedInfo?.appointmentBookingPage?.secondaryColor  ?? SECONDARY_HEX,
        },
      },
      typography: {
        fontFamily: loadedInfo?.appointmentBookingPage?.fontFace || 'Domine, serif',
      }
    })}>
    <Grid container justifyContent={'center'} sx={{
      backgroundColor: loadedInfo?.appointmentBookingPage.backgroundColor,
    }}>
    <Grid container direction="column" wrap="nowrap"
      alignItems={
        page === BookingPage.Calendar
          ? 'center'
          : undefined
      }
      sx={{ 
        minHeight: '100vh',
        py: 3,
        maxWidth: (
          page === BookingPage.Calendar // has special UI when userId provided
            ? undefined
            : MOBILE_FIRST_MAX_WIDTH
        )
      }}
      className={css`
        @font-face {
          ${loadedInfo?.appointmentBookingPage?.fontFamily || DEFAULT_FONT_FACE}  
        }`
      }
    >
    {loadedInfo?.appointmentBookingPage?.topLogo && 
      <Grid item sx={{ mb: 2, textAlign: 'center' }}>
        <img src={loadedInfo.appointmentBookingPage.topLogo} alt="Top branding" 
          style={{ maxHeight: 100, maxWidth: `calc(0.85 * ${MOBILE_FIRST_MAX_WIDTH})`, textAlign: 'center' }}
        />
      </Grid>
    }
    
    {(
         (!restrictByState && page === BookingPage.Intake) // Calendar comes before Intake in limitedByState flow
      || (restrictByState && page === BookingPage.Calendar && !skipState) // Calendar comes after Intake in limitedByState flow
      || page === BookingPage.Confirmation 
      || page === BookingPage.Payment 
    )
      ? (
        <Grid item alignSelf={"flex-start"} sx={{ pl: 2, pb: 2 }}> 
          <IconButton onClick={() => (
            setPage(
              p => {
                // flow different with limitedByState restriction
                if (restrictByState) {
                  if (p === BookingPage.Calendar) {
                    // going back to intake might change information (like state), so we should re-fetch availability
                    setLoadedSlots({}) 
                    setDisabledDays([])
                    return BookingPage.Intake
                  }
                  if (p === BookingPage.Confirmation) {
                    return BookingPage.Calendar
                  }
                }

                return p - (isAuthenticated ? 2 : 1) // when authenticated, skip intake when going backwards from confirmation
              }
            )
          )}>
            <ArrowBackIosNewIcon  />
          </IconButton>
        </Grid>
      )
      : null
    }

    {
        page === BookingPage.Loading ? null
      : page === BookingPage.PickAppointmentType ? <EventSelector />
      : page === BookingPage.Calendar ? (
        // ensure state is collected when booking page is state-restricted
        (
             restrictByState
          && !VALID_STATES.includes(session.userInfo?.state ?? '')
          && !skipState
        )
          ? <SetEnduserStateForm />
          : userId 
            ? (
              <Grid container justifyContent={'center'} sx={{
                maxWidth: `calc(${USER_DISPLAY_MAX_WIDTH} + ${MOBILE_FIRST_MAX_WIDTH})`,
              }}>
                <Grid item xs={12} sm={6}>
                  <UserDisplay />
                </Grid>

                <Grid item xs={12} sx={{
                  display: { sm: 'none' },
                  borderBottom: { xs: '2px solid #cccccc', sm: undefined },
                  my: { xs: 4, sm: 0 },
                  maxWidth: MOBILE_FIRST_MAX_WIDTH,
                }} />

                <Grid item xs={12} sm={6}>
                  <Calendar isRescheduling={!!rescheduledCalendarEventId} />
                </Grid>
              </Grid>
            )
            : <Calendar />
      )
      : page === BookingPage.Intake ? <Intake />
      : page === BookingPage.Confirmation ? <Confirmation rescheduledCalendarEventId={rescheduledCalendarEventId} />
      : page === BookingPage.Payment ? <Payment />
      : page === BookingPage.ThankYou ? <ThankYou />
      : (
        <Typography>Unknown page <span onClick={() => setPage(p => p-1)}>back</span></Typography>
      )
    }
    </Grid>
    </Grid>
    </ThemeProvider>
    </>
  )
}

const CACHE_KEY = 'appointment-booking'
export const PublicAppointmentBookingPage = () => {
  const [queries] = useSearchParams()
  const businessId = queries.get('businessId')
  const appointmentBookingPageId = queries.get('appointmentBookingPageId')
  const rescheduledCalendarEventId = queries.get('rescheduledCalendarEventId') ?? undefined
  const bookingToken = queries.get('bookingToken') || undefined
  const minDate = queries.get('from') || undefined
  const maxDate = queries.get('to') || undefined
  const fieldsString = queries.get('fields') || undefined
  const multi = queries.get('multi') === "true"
  const skipState = queries.get('skipState') === "true"
  const formResponseId = queries.get('formResponseId') || undefined
  const holdAppointmentMinutes = queries.get('holdAppointmentMinutes') ? parseInt(queries.get('holdAppointmentMinutes')!) : undefined
  let fields = undefined as undefined | Record<string, string>
  try {
    fields = fieldsString ? JSON.parse(fieldsString) : undefined
  } catch(err) {}

  const userIdsString = queries.get('userIds') || undefined
  let userIds: string[] = []
  try {
    userIds = (
      userIdsString 
        ? userIdsString.split(',')
        : []
    )
    if (!Array.isArray(userIds)) {
      userIds = []
    } else {
      userIds = userIds.filter(u => typeof u === 'string')
    }
  } catch(err) {
    console.error(err)
  }

  const userTagsString = queries.get('userTags') || undefined
  let userTags: string[] = []
  try {
    userTags = (
      userTagsString 
        ? userTagsString.split(',')
        : []
    )
    if (!Array.isArray(userTags)) {
      userTags = []
    } else {
      userTags = userTags.filter(u => typeof u === 'string')
    }
  } catch(err) {
    console.error(err)
  }

  const userFilterTagsString = queries.get('userFilterTags') || undefined
  let userFilterTags: string[] = []
  try {
    userFilterTags = (
      userFilterTagsString 
        ? userFilterTagsString.split(',')
        : []
    )
    if (!Array.isArray(userFilterTags)) {
      userFilterTags = []
    } else {
      userFilterTags = userFilterTags.filter(u => typeof u === 'string')
    }
  } catch(err) {
    console.error(err)
  }

  try {
    window.localStorage[CACHE_KEY] = ''
  } catch(err) {} // incognito browser, etc.

  if (!businessId) return (
    <Typography>This link is invalid (no businessId provided)</Typography>
  )
  if (!appointmentBookingPageId) return (
    <Typography>This link is invalid (no appointmentBookingPageId provided)</Typography>
  )
  return (
    <WithEnduserSession sessionOptions={{
      host: TellescopeHost,
      businessId,
      cacheKey: CACHE_KEY, // keeps session cache separate for this flow
    }}>
      <AppointmentBookingPage isPublic
        businessId={businessId} bookingToken={bookingToken}
        appointmentBookingPageId={appointmentBookingPageId} 
        rescheduledCalendarEventId={rescheduledCalendarEventId}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
        fields={fields} userIds={userIds}
        multi={multi}
        skipState={skipState}
        userTags={userTags} userFilterTags={userFilterTags}
        formResponseId={formResponseId} holdAppointmentMinutes={holdAppointmentMinutes}
      />
    </WithEnduserSession>
  )
}

export const AppointmentBookingPage = ({
  businessId,
  appointmentBookingPageId,
  rescheduledCalendarEventId,
  userId,
  isPublic,
  ...passthroughs
} : {
  businessId: string,
  appointmentBookingPageId: string,
  rescheduledCalendarEventId?: string,
  userId?: string,
  userIds?: string[],
  isPublic?: boolean,
  bookingToken?: string,
  minDate?: Date,
  maxDate?: Date,
  fields?: Record<string, string>
  multi?: boolean,
  skipState?: boolean, // if we trust state is known on backend but not defined in front-end (restricted / private session), then can skip step
  userTags?: string[],
  userFilterTags?: string[],
  formResponseId?: string,
  holdAppointmentMinutes?: number,
  scheduledBy?: string,
}) => (
  <WithBookingFlowContext businessId={businessId} appointmentBookingPageId={appointmentBookingPageId} userId={userId}
    isAuthenticated={!isPublic} {...passthroughs}
    rescheduledCalendarEventId={rescheduledCalendarEventId}
  >
    <PublicAppointmentBookingPageWithContext />
  </WithBookingFlowContext>
)