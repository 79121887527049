import React, { useEffect, useRef, useState } from "react"
import {
  Badge,
  Checkbox,
  FormControlLabel,
  Grid, 
  TextField, 
  Typography,
} from "@mui/material"
import { 
  ScrollingList, 
  DisplayPicture, 
  LoadingLinear, 
  useChatRooms, 
  useChats,
  useDisplayInfoForSenderId, 
  useEnduserSession, 
  useModalIconButton, 
  IconModal,
  LoadingButton,
  useEnduserSessionContext, 
} from "@tellescope/react-components"
import { 
  Messages, 
  SendMessage, 
  user_display_name 
} from "@tellescope/chat"
import { ChatRoom } from "@tellescope/types-client"
import { useParams } from "react-router-dom"
import { routes, useNavigateToPage } from "../definitions/routes"
import { elapsed_time_display_string } from "@tellescope/react-components"
import { 
  HoverPaper, 
  ResponsiveModal, 
} from "../components/layout"
import { useFullHeight } from "../components/navigation"
import { truncate_string } from "@tellescope/utilities"
import { dashboardChildStyles } from "../definitions/constants"
import { ResolveCustomPage } from "./CustomPage"
import { AddCircleIcon } from "../definitions/icons"
import { useOrganizationTheme } from "../definitions/contexts"

export const SetDisplayName = () => {
  const { updateUserInfo } = useEnduserSessionContext()
  const [consented, setConsented] = useState(false)
  const [displayName, setDisplayName] = useState('')

  const focusRef = useRef<HTMLElement>()

  useEffect(() => {
    if (!consented) return
    focusRef.current?.focus()
  }, [consented])

  return (
    <Grid container direction={"column"} spacing={1} sx={{ maxWidth: 400 }}>
      <Grid item>
        <Typography>
          Your Display Name will be visible to all others in this conversation.
          It does not need to include any personally-identifiable information.
          Display Names using inappropriate language will result in removal from the conversation 
          as well as other potential penalties
        </Typography>
      </Grid>

      <Grid item sx={{ mb: 2 }}>
        <FormControlLabel label="I Understand" 
          control={<Checkbox checked={consented} onChange={() => setConsented(c => !c)} />} 
        />
      </Grid>

      <Grid item>
        <TextField disabled={!consented} size="small" label="Your Display Name"
          inputRef={focusRef} fullWidth
          value={displayName} onChange={e => setDisplayName(e.target.value)} 
        />
      </Grid>

      <Grid item>
      <LoadingButton submitText="Save Display Name" submittingText="Saving..."
        disabled={!(consented && displayName)}
        onClick={() => updateUserInfo({ displayName })}  
      />
      </Grid>
    </Grid>
  )
}

export const Conversation = ({ room } : { room: ChatRoom }) => {
  const session = useEnduserSession()
  const [chatsLoading, { loadRecentlyCreated }] = useChats(room.id)
  const height = useFullHeight()

  useEffect(() => {
    const i = setInterval(loadRecentlyCreated, 5000)
    return () => { clearInterval(i) }
  }, [loadRecentlyCreated])

  if (room.type === 'Group Chat' && !session.userInfo.displayName) {
    return (
      <Grid container alignItems={'center'} justifyContent={'center'}
        style={{ ...dashboardChildStyles, height }}
      >
        <SetDisplayName />
      </Grid>
    )
  }

  return ( 
    <Grid container style={{ ...dashboardChildStyles, height }} direction="column" wrap="nowrap">
      {room.title && (
        <Typography style={{ fontWeight: 'bold', fontSize: 18, marginBottom: 3 }}>
          {room.title}
        </Typography>
      )}
      <Grid container direction="column" justifyContent="space-between" sx={{ 
        display: 'flex', flex: 1,
        direction: 'column',
        padding: 3,
        border: '1px solid #00000022', borderRadius: 3,
        maxWidth: 700,
      }}>
        <Grid item>
          <Messages markRead chatUserId={session.userInfo.id} messages={chatsLoading} showNames 
            style={{ maxHeight: `calc(${height} - 150px)` }}
            imageDimensions={{
              maxWidth: 250,
            }}
          />
        </Grid>

        <Grid item>
          {room.endedAt
            ? <Typography>This conversation has been ended</Typography>
            : <SendMessage multiline size="small" roomId={room.id} />
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export const RoomPreview = ({ room } : { room: ChatRoom }) => {
  const session = useEnduserSession()
  const sender = useDisplayInfoForSenderId(room?.recentSender ?? room?.creator ?? '')

  return ( 
    <Grid container justifyContent="space-between" wrap="nowrap">
      <Grid item xs={7}>
      <Grid container alignItems="center" wrap="nowrap">
        <Grid item>
          <Badge color="primary" overlap="circular" showZero={false} max={9} 
            badgeContent={
              room.infoForUser?.[session.userInfo.id]?.unreadCount ?? 0
            }
          >
            <DisplayPicture user={sender} size={30} />
          </Badge>
        </Grid>

        <Grid item style={{ marginLeft: 10 }}>
        <Grid container direction="column">
          <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>
            {truncate_string(room.title, { length: 30 })}
          </Typography>
          <Typography style={{ fontSize: 13 }}>
            {truncate_string(room.recentMessage, { length: 40 })}
          </Typography>
        </Grid>
        </Grid>
      </Grid>
      </Grid>

      <Grid item xs={5}>
      <Grid container direction="column" alignItems="flex-end" wrap="nowrap">
        <Typography style={{ fontSize: 12 }}>
          {truncate_string(user_display_name(sender), { length: 40 })}
        </Typography>
        <Typography style={{ fontSize: 12 }}>
          {elapsed_time_display_string(new Date(room.recentMessageSentAt ?? room.updatedAt))}
        </Typography>
      </Grid>
      </Grid>
    </Grid>
  )
}

const InitiateConversationIconButton = () => {
  const session = useEnduserSession()
  const navigate = useNavigateToPage()
  const { theme } = useOrganizationTheme()
  const addConversationProps = useModalIconButton({
    Icon: AddCircleIcon,
    label: "Start Conversation"
  })

  const [title, setTitle] = useState(
       theme.portalSettings?.communication?.enduserInitiatedChatDefaultSubject 
    || `The ${theme.name} Team`
  )
  const [, { createElement: createChatRoom }] = useChatRooms({ dontFetch: true })
  const [, { createElement: createChat }] = useChats(undefined, { dontFetch: true })

  const [message, setMessage] = useState('')

  if (!theme.portalSettings?.communication?.allowEnduserInitiatedChat) {
    return null
  }
  return (    
    <IconModal {...addConversationProps} ModalComponent={ResponsiveModal}
      style={{ marginLeft: 5, maxWidth: 500  }}
    >
    <Grid container direction="column" spacing={1}>
      <Grid item>
      <Typography>
        Start a conversation with the {theme.name} team
      </Typography>
      </Grid>

      <Grid item>
        <TextField label="Subject" value={title} 
          size="small" fullWidth
          onChange={e => setTitle(e.target.value)}
        />
      </Grid>

      <Grid item>
      <TextField multiline minRows={2} fullWidth 
        label="Your Message" placeholder="Your message here..."
        value={message} onChange={e => setMessage(e.target.value)} 
      />
      </Grid>

      <Grid item>
      <LoadingButton disabled={!(message && title)}
        submitText="Start conversation" submittingText="Starting..."
        onClick={async () => {
          const room = await createChatRoom({ title, enduserIds: [session.userInfo.id] })
          await createChat({ roomId: room.id, message })

          addConversationProps.setOpen(false)
          navigate(routes.communications, { id: room.id })
        }}
      />
      </Grid>
    </Grid>
    </IconModal>
  )
}

export const DefaultChatHome = () => {
  const [, { filtered, findById: findRoom, loadMore, doneLoading, loadRecentlyCreated }] = useChatRooms({ sortBy: 'updatedAt' })
  const navigate = useNavigateToPage()
  const roomId = useParams().roomId


  const room = findRoom(roomId ?? '')

  useEffect(() => {
    if (room) return
    
    const i = setInterval(loadRecentlyCreated, 5000)
    return () => { clearInterval(i) }
  }, [loadRecentlyCreated, room])

  if (room) {
    return <Conversation room={room} />
  }

  return (
    <LoadingLinear data={filtered(r => !r.endedAt)} render={chatRooms=> (
    <>
    <Grid container justifyContent="center" style={dashboardChildStyles}>
      <ScrollingList items={chatRooms} loadMore={loadMore} doneLoading={doneLoading}
        title="Your Conversations"
        TitleComponent={({ title, titleStyle }) => (
          <Grid container alignItems="center">
            <Typography style={titleStyle}>
              {title}
            </Typography>

            <Grid item sx={{ mb: 0.3 }}>
              <InitiateConversationIconButton />
            </Grid>
          </Grid>
        )}
        emptyText="Your conversations will appear here"
        Item={({ item: room }) => (
          <HoverPaper elevation={3} onClick={() => navigate(routes.communications, { id: room.id })}
            sx={{ 
              padding: {
                xs: 0.5, 
                md: 1,
              },
              py: {
                xs: 1,
                md: 2,
              }, 
              marginBottom: 1, 
              cursor: 'pointer',
            }} 
          >
            <RoomPreview room={room} />
          </HoverPaper>
        )}
      />
    </Grid>
    </>
    )} />
  )
}

export const Chat = () => (
  <ResolveCustomPage page="Communications" defaultPageComponent={<DefaultChatHome />} />
)