import React from "react"
import { Checkbox, FormControlLabel, Typography } from "@mui/material"
import { useOrganizationTheme } from "../definitions/contexts"

export const TERMS_VERSION = '1.0'
export const TermsOfServiceAndPrivacyPolicyConsent = ({ value, onChange } : { 
  value: boolean, 
  onChange: React.Dispatch<React.SetStateAction<boolean>>,
}) => {
  const theme = useOrganizationTheme()

  return (
    <FormControlLabel 
      control={<Checkbox checked={value} onChange={() => onChange(t => !t)} />} 
      label={
        <Typography style={{ fontSize: 14 }}>
          I agree to the 
          <a href={theme.theme.customTermsOfService || "/terms-of-service"} target="_blank" rel="noopener noreferrer">Terms of Service</a> and 
          {' '}<a href={theme.theme.customPrivacyPolicy || "/privacy-policy"} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        </Typography>
      } 
    />
  )
}