export const MOBILE_WIDTH_CUTOFF = 600 // in @mui, xs is under 0-600px

export const TellescopeHost = (
  process.env.REACT_APP_TELLESCOPE_HOST 
  || (
    window.location.origin.includes(':3030')
      ? window.location.origin.replace(':3030', ':8080')
      : window.location.origin.includes('staging') 
        ? 'https://staging-api.tellescope.com'
        : 'https://api.tellescope.com'
  )
)

export const DEFAULT_BUSINESS_ID = (
    process.env.REACT_APP_TELLESCOPE_BUSINESS_ID 
  ||'60398b1131a295e64f084ff6' // local test organization
)

export const DASHBOARD_CHILD_MARGIN = '20px'
export const dashboardChildStyles = { padding: DASHBOARD_CHILD_MARGIN }