import React from "react"
import {
  Divider,
  Grid, 
  LinearProgress,
  Typography,
} from "@mui/material"
import { useFullHeight, useIsMobile } from "../components/navigation"
import { LoadingLinear, useManagedContentRecords, ScrollingList, useFilters, ContentSearch, PDFBlockUI, SecureVideo, ArticleViewer, useEnduserSession, SecureImage, useAssignedManagedContentRecords } from "@tellescope/react-components"
import { routes, useNavigateToPage } from "../definitions/routes"
import { useParams } from "react-router-dom"
import { remove_script_tags, truncate_string } from "@tellescope/utilities"
import { ManagedContentRecord } from "@tellescope/types-client"
import { dashboardChildStyles } from "../definitions/constants"
import { cardListTitleStyle } from "../definitions/styles"

export const ContentViewer = () => {
  const [, { findById }] = useManagedContentRecords({ dontFetch: true })

  const id = useParams().id
  const content = findById(id ?? '')

  if (content === null) return <Typography>This content has been deleted</Typography>
  if (content === undefined) return <LinearProgress />

  return (
    <Grid container direction="column" 
      style={dashboardChildStyles}
      alignItems={content.type === 'Article' ? "center" : undefined}
    >
      <Typography style={{ fontSize: 25, fontWeight: 'bold' }}> 
        {content.title}
      </Typography>

      <Divider flexItem style={{ marginTop: 5, marginBottom: 15 }} />

      {
        content.type === 'PDF' && content.attachments?.length
        ? <PDFBlockUI info={{ link: content.attachments[0].secureName }} />
      : content.type === "Video" && content.attachments?.length
        ? <SecureVideo maxWidth={'100%'} secureName={content.attachments[0].secureName} />
      : content.type === 'Article' && content.blocks?.length
        ? <ArticleViewer article={content} maxWidth={600} iframeWidthAdjustment={-20} /> // iframeWidthAdjustment should match the root style padding
      : (
        <div dangerouslySetInnerHTML={{
          __html: remove_script_tags(content.htmlContent),
        }} />
      )
      }
    </Grid>
  )
}

export const ContentPreview = ({ item: content } : { item: ManagedContentRecord }) => {
  const navigate = useNavigateToPage()
  const isMobile = useIsMobile()

  return (
    <Grid container alignItems="center" wrap="nowrap"
      onClick={() => {
        navigate(routes.content, { id: content.id })
      }}
      sx={{
        marginBottom: '20px',
        cursor: 'pointer',
        borderRadius: '8px',
        minHeight: '40px',
        '&:hover': {
          backgroundColor: '#dfdfdf',
        }
      }}
    >
      {content.headerPhoto && 
        <Grid item style={{ width: 100 }}>
          <SecureImage secureName={content.headerPhoto} maxWidth={100} maxHeight={100} style={{
            borderRadius: '8px',
            boxShadow: '2px 5px #cccccccc',
          }} />
        </Grid>
      }

      <Grid item sx={{ ml: content.headerPhoto ? 2 : 0 }}>

      <Typography color="primary" sx={{ 
        fontWeight: 'bold', 
        width: {
          xs: content.headerPhoto ? '40vw' : undefined, // wrapping looks good when there's no image
        },
        fontSize: {
          xs: 16,
          sm: 18,
          md: 20,
        },
        // example indication of link when there's no content
        textDecoration: content.headerPhoto ? 'undefined' : 'underline', 
      }}>
        {truncate_string(content.title, { length: isMobile ? 55 : 100 })}
      </Typography>
      </Grid>
    </Grid>
  )
}

export const ContentList = ({ 
  records, 
  totalRecordsCount, 
  noCategories, 
  defaultContentTitle="Your Content"
} : { 
    records : ManagedContentRecord[], 
    totalRecordsCount: number,
    noCategories?: boolean,
    defaultContentTitle?: string,
}) => {
  const [, { doneLoading, loadMore }] = useManagedContentRecords({ dontFetch: true })

  const fullHeight = useFullHeight()

  const recordsWithNoCategory = records.filter(r => !r.category)
  const categories = (
    noCategories 
      ? []
      : Array.from(new Set(records.map(r => r.category).filter(r => r)))  
  )

  if (categories.length > 0 && totalRecordsCount === records.length) {
    return (
      <Grid container direction="column" spacing={4}>
      {categories.map(category => (
        <Grid item key={category}>
        <ScrollingList items={records.filter(r => r.category === category)} maxHeight={'33vh'} doneLoading={doneLoading} loadMore={loadMore}
          titleStyle={cardListTitleStyle}
          title={category}
          Item={ContentPreview}
        />
        </Grid>
      ))}
      {recordsWithNoCategory.length > 0 &&
        <Grid item>
        <ScrollingList items={records.filter(r => !r.category)} maxHeight={'33vh'} doneLoading={doneLoading} loadMore={loadMore}
          titleStyle={cardListTitleStyle}
          title={"Other Content"}
          Item={ContentPreview}
        />
        </Grid>
      }
      </Grid>
    )
  }
  return (
    <ScrollingList items={records} maxHeight={fullHeight} doneLoading={doneLoading} loadMore={loadMore}
      titleStyle={cardListTitleStyle}
      title={
        records.length === totalRecordsCount 
          ? defaultContentTitle
          : "Search Results"
      }
      emptyText={
        totalRecordsCount === 0 
          ? "No content is available at this time"
          : "No content matches your search"
      }
      Item={ContentPreview}
    />
  )
}

export const Content = () => {
  const session = useEnduserSession()
  const contentRecordsLoading = useAssignedManagedContentRecords()

  const { applyFilters, ...filterProps } = useFilters<ManagedContentRecord>()

  return (
    <LoadingLinear data={contentRecordsLoading} render={records => (
    <Grid container justifyContent="center" direction="column" style={dashboardChildStyles}>
      <Typography component="h1" style={{ 
        textAlign: 'center', 
        fontSize: 35, 
        marginBottom: 30, marginTop: 10 
      }}>
        {session.userInfo.fname ?
          <>
          <Typography color="primary" component="span" style={{ fontSize: 'inherit' }}>
            {session.userInfo.fname}'s{' '}
          </Typography>
          content library.
          </>
          : "Your Content Library"
        }
      </Typography>

      <ContentSearch key="search" {...filterProps} 
        sx={{
          mb: '30px', mx: '5px',
          '& fieldset': {
            borderRadius: '25px',
          }
        }}
      />

      <ContentList records={applyFilters(records)} totalRecordsCount={records.length} />
    </Grid>
    )} />
  )
}