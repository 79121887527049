import React, { useState } from "react"
import { FileBlob, FileDropzone, IconModal, LoadingButton, LoadingLinear, ScrollingList, useDownloadSecureFile, useEnduserSession, useFileUpload, useFiles, useModalIconButton, useResolvedSession } from "@tellescope/react-components"
import { Button, Grid, Paper, Typography } from "@mui/material"
import { DASHBOARD_CHILD_MARGIN } from "../../definitions/constants"
import { StringSelector } from "../../components/inputs"
import { useFileViewer } from "../../components/files"
import { File } from "@tellescope/types-client"
import { getPublicFileURL, mm_dd_yyyy } from "@tellescope/utilities"
import { AddCircleIcon, FileIcon, WithCircularBackground } from "../../definitions/icons"
import { ViewMenuIconButton } from "../../components/ViewMenuIconButton"
import { ResponsiveModal } from "../../components/layout"
import { routes, useNavigateToPage } from "../../definitions/routes"

export const FileCardContent = ({ file } : { file: File }) => {
  const [open, setOpen] = useState(false)
  const session = useEnduserSession()
  const { downloadFile } = useDownloadSecureFile({ preferInBrowser: file?.type?.includes('pdf') })
  const [, { updateElement: updateFile }] = useFiles()

  const { setFile } = useFileViewer()

  const handleDownloadFile = () => {
    if (!file.publicRead) {
      return (
        downloadFile(file.secureName)
        .then(url => 
          setFile({ name: file.name, url, type: file.type })
          // window.open(url, "_blank")
        )
        .catch(err => alert(err?.message))
      )
    }
    setFile({ name: file.name, type: file.type, url: getPublicFileURL({ businessId: file.businessId, name: file.secureName }) })
    // window.open(
    //   getPublicFileURL({ businessId: file.businessId, name: file.secureName }),
    //   "_blank"
    // )
  }

  return (
    <Grid container alignItems="center" wrap="nowrap">
      <Grid item sx={{ mr: 1 }}>
        <WithCircularBackground><FileIcon /></WithCircularBackground>
      </Grid>

      <Grid item sx={{
        width: {
          xs: 175,
          sm: 300,
          md: 400,
        },
      }}>
        <Typography noWrap onClick={handleDownloadFile}
          sx={{ 
            fontSize: 18, 
            cursor: 'pointer',
            textDecoration: 'underline',
            '&:hover': { color: 'primary.main' }
          }}
        >
          {file.name}
        </Typography>

        <Typography style={{ fontSize: 14, opacity: 0.8 }}>
          Uploaded {mm_dd_yyyy(new Date(file.timestamp || file.createdAt || file.updatedAt))}
        </Typography>
      </Grid>

      <Grid item alignItems="center" justifyContent="flex-end" sx={{
        ml: 'auto',
      }}>
        <ViewMenuIconButton open={open} setOpen={setOpen}
          entries={[
            { label: 'Download', onClick: handleDownloadFile },
            {
              label: "Hide",
              onClick: () => (
                updateFile(file.id, { hideFromEnduserPortal: true }).catch(console.error)
              ),
              hidden: (
                 file.pushedToClientPortal // pushed to all endusers, can't hide as individual
              || session.userInfo.businessId !== '61aa4fef95cdafe5bb9d8b37' // hide from non-Icon prod portals
              ),
            },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export const FileUploader = ({
  placeholder="Click to select a file, or drag and drop",
  publicName,
  publicRead,
  onSuccess,
} : { 
  publicName?: string,
  publicRead?: boolean,
  placeholder?: string,
  style?: React.CSSProperties,
  onSuccess?: (result: { secureName: string }) => void,
  onRemove?: () => any,
}) => {
  const [file, setFile] = useState<FileBlob | undefined>()
  const { handleUpload, uploading } = useFileUpload({ publicName, publicRead: publicRead || !!publicName })

  return (
    <Grid container direction="column" sx={{ width: 280 }}>
      <FileDropzone file={file} onChange={setFile}
        label={placeholder}
        dropzoneStyle={{
          border: '1px solid black',
          borderRadius: 5,
          width: 280,
          height: 200,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />

      <LoadingButton submitting={uploading} disabled={!file}
        submitText="Upload File"
        submittingText="Uploading..."
        onClick={async () => {
          if (!file) return
          
          const result = (
            await handleUpload({
              name: file.name,
              type: file.type,
              size: file.size,
            }, file)
          )
          onSuccess?.(result)
          setFile(undefined)
        }}
      />
    </Grid>
  )
}

export const UploadFileIconButton = () => {
  const iconProps = useModalIconButton({ Icon: AddCircleIcon, label: "Add File" })

  return (
    <IconModal {...iconProps} ModalComponent={ResponsiveModal}
      style={{ maxWidth: 500, minHeight: 400 }}
    >
    <Grid container direction="column" alignItems={"center"} justifyContent="center" spacing={1}>
      <Grid item>
        <Typography sx={{ fontSize: 20 }}>
          Upload a new file
        </Typography>
      </Grid>

      <Grid item>
        <FileUploader onSuccess={() => iconProps.setOpen(false)} />
      </Grid>
    </Grid>  
    </IconModal>
  )
}

export const Files = ({ limit } : { limit?: number }) => {
  const navigate = useNavigateToPage()
  const session = useResolvedSession()
  const [, { filtered: filteredFiles, doneLoading: doneLoadingFiles, loadMore: loadMoreFiles } ] = useFiles()
  const filesLoading = filteredFiles(f => !f.hideFromEnduserPortal && f.enduserId === session.userInfo.id)

  const [selectedFiles, setSelectedFiles] = useState<'All Files' | 'Your Uploaded Files' | 'Shared Files'>('All Files')

  return (
    <LoadingLinear data={filesLoading} render={files => (
      <>
      <ScrollingList 
        items={files.filter(f => (
            (selectedFiles === 'Shared Files' && f.creator !== f.enduserId)
          || (selectedFiles === 'Your Uploaded Files' && f.creator === f.enduserId)
          || selectedFiles === 'All Files'
          )).slice(0, limit || files.length)
        }
        doneLoading={doneLoadingFiles} loadMore={loadMoreFiles}
        emptyText=""
        TitleComponent={() => (
          <Grid container alignItems="center" sx={{ mb: 0.5 }}>
            <StringSelector value={selectedFiles} size="small" 
              style={{ marginLeft: DASHBOARD_CHILD_MARGIN, minWidth: 200 }}
              selectStyle={{ fontWeight: 'bold', fontSize: 18 }}
              itemStyle={{ fontWeight: 'bold' }}
              onChange={s => setSelectedFiles(s)}
              options={['All Files', 'Shared Files', 'Your Uploaded Files']}
            />

            <Grid item sx={{ ml: 0.5, mb: 0.3 }}>
              <UploadFileIconButton />
            </Grid>
          </Grid>
        )}
        titleStyle={{ paddingLeft: DASHBOARD_CHILD_MARGIN }}
        itemContainerStyle={{
          padding: DASHBOARD_CHILD_MARGIN,
          paddingTop: 2,
        }}
        Item={({ item }) => (
          <Paper elevation={5} sx={{ padding: 2, borderRadius: 4, marginBottom: 1 }}>
            <FileCardContent file={item} />
          </Paper>
        )}
      />

      {limit !== undefined && files.length > limit && 
        <Button variant="outlined" onClick={() => navigate(routes.files)} sx={{ width: 200, borderRadius: 3 }}>
          View All Files
        </Button>
      }
      </>
    )} />
  )
}