// re-usable headers + other fields for legal docs
// e.g. privacy policy and terms of service

import React from "react"
// import logo from "../../img/logo.png"

export const Header = ({ title, downloadLink } : { title: string, downloadLink: string }) => (
  <div style={{
    textAlign: 'center',
  }}>
    {/* <img src={logo} alt="Tellescope Logo" style={{
      height: 75,
      marginTop: 30,
    }}/> */}
    <h1 style={{
      fontSize: 25,
      marginBottom: 8,
      fontWeight: "bold",
    }}>
      {title}
    </h1>
    {downloadLink && 
      <a href={downloadLink} rel="noopener noreferrer" target="_blank">
        Download as PDF
      </a>
    }
  </div>
)