import React, { useEffect } from "react"
import { Typography } from "@mui/material"
import { 
  useEnduserSessionContext, 
  useResetState 
} from "@tellescope/react-components"

export const Logout = () => {
  const { enduserSession, logout } = useEnduserSessionContext()  
  const resetState = useResetState()

  useEffect(() => {
    if (!enduserSession.authToken) return 

    logout().finally(resetState)
  }, [enduserSession, logout, resetState])

  return (
    <Typography>Logging out</Typography>
  )
}