import React from "react"
import { LoadingButton, useEnduserSession } from "@tellescope/react-components"

export const StripeCustomerPortalButton = () => {
  const session = useEnduserSession()

  if (!session.userInfo.stripeCustomerId) return null
  if (!session.userInfo.stripeKey) return null

  return (
    <LoadingButton variant="contained" style={{ width: 300 }} 
      submitText="Manage Subscriptions" submittingText="Loading..."
      onClick={() => 
        session.api.products.get_stripe_portal_session({
          return_url: window.location.href,
          stripeCustomerId: session.userInfo.stripeCustomerId,
          stripeKey: session.userInfo.stripeKey,
        })
        .then(({ url }) => window.location.href = url)
      }
    />
      
  )
}