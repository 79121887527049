import React, { useState } from "react"
import {
  Alert,
  Button,
  Grid,
  Paper,
  SxProps,
  TextField,
  Typography, 
} from "@mui/material"
import { 
  LoadingButton,
  LoadingLinear, 
  Styled, 
  useCalendarEventRSVPs, 
  useCalendarEvents, 
  useChatRooms,
  useEnduserSession, 
  useFormResponses, 
  usePortalCustomizations, 
  useResolvedSession, 
  value_is_loaded 
} from "@tellescope/react-components"
import { Link } from "../components/Link"
import { routes } from "../definitions/routes"
import { ResolveCustomPage } from "./CustomPage"
import { dashboardChildStyles } from "../definitions/constants"
import { EventsTable } from "./Events"
import { ResponsiveModal } from "../components/layout"
import { TogglePasswordIcon } from "../components/inputs"
import { password as passwordValidation } from "../definitions/schemas"
import { AlertIcon, InfoIcon, SecurityIcon } from "../components/icons"
import { WithCircularBackground } from "../definitions/icons"
import { useLocation, useSearchParams } from "react-router-dom"
import { useOrganizationTheme } from "../definitions/contexts"
import { should_show_unsubmitted_form_response_for_interval } from "@tellescope/utilities"

const useUnsubmittedFormCount = () => {
  const [formResponsesLoading] = useFormResponses()
  if (!value_is_loaded(formResponsesLoading)) return undefined

  const unsubmittedCount = (
    formResponsesLoading 
    .value
    .filter(f => 
      !f.hideFromEnduserPortal
      && !f.submittedAt
      && !f.isInternalNote
      && !f.draftSavedAt // enduser can't currently save draft
      && f.source !== 'Formsort'
      && should_show_unsubmitted_form_response_for_interval(f)
    )
    .length
  )
  return unsubmittedCount
}
export const UnsubmittedFormsAlert = ({ style } : Styled) => {
  const unsubmittedCount = useUnsubmittedFormCount()

  const [portalCustomizationsLoading] = usePortalCustomizations()
  if (!value_is_loaded(portalCustomizationsLoading)) return null

  // don't show link to Documents page when it's disabled
  if (portalCustomizationsLoading.value.find(p => p.page === 'Documents')?.disabled) {
    return null
  }

  if (!unsubmittedCount) return null
  return (
    <Paper elevation={5} style={{ ...style, borderRadius: 10 }}>
    <Alert severity="warning" icon={<AlertIcon />}>
      You have {unsubmittedCount} outstanding form{unsubmittedCount > 1 ? 's' : ''} - <Link to={routes.documents}>click here to view</Link>
    </Alert>
    </Paper>
  )
}

const useUnreadMessageCount = () => {
  const session = useEnduserSession()
  const [chatRooms] = useChatRooms()
  if (!value_is_loaded(chatRooms)) return undefined

  const unreadCount = (
    chatRooms
    .value
    .map(c => c.infoForUser?.[session.userInfo.id]?.unreadCount ?? 0)
    .reduce((a, b) => a + b, 0)
  )

  return unreadCount
}

export const UnreadMessagesAlert = ({ style } : Styled) => {
  const unreadCount = useUnreadMessageCount()
  if (!unreadCount) return null
  return (
    <Paper elevation={5} style={{ ...style, borderRadius: 10 }}>
    <Alert severity="info" icon={<InfoIcon />}>
      You have {unreadCount} unread message{unreadCount > 1 ? 's' : ''} - <Link to={routes.communications}>click here to view</Link>
    </Alert>
    </Paper>
  )
}

export const Alerts = ({ sx } : { sx?: SxProps }) => {
  const unreadCount = useUnreadMessageCount()
  const unsubmittedCount = useUnsubmittedFormCount()

  if (!(unreadCount || unsubmittedCount)) return null

  return (
    <Grid container direction="column" sx={sx}>
      <UnsubmittedFormsAlert style={{ marginBottom: 8 }} />
      <UnreadMessagesAlert style={{ marginBottom: 8 }} />
    </Grid>
  )
}

export const DefaultHome = () => {
  const session = useResolvedSession()

  const [eventsLoading] = useCalendarEvents()
  const [, { filtered }] = useCalendarEventRSVPs({ loadFilter: { creator: session.userInfo.id } })
  
  const creatorRSVPsLoading = filtered(r => r.creator === session.userInfo.id)

  return (
    <LoadingLinear data={eventsLoading} render={events => (
      <Grid container direction="column" style={dashboardChildStyles}>
        <UnsubmittedFormsAlert style={{ marginBottom: 8 }} />
        <UnreadMessagesAlert style={{ marginBottom: 8 }} />
        <EventsTable events={
          events.filter(e => (
            e.publicRead // only display public events when the client is RSVPed
              ? value_is_loaded(creatorRSVPsLoading)
                ? creatorRSVPsLoading.value.find(rsvp => rsvp.eventId === e.id)
                : false
              : true
          ))
        } />
      </Grid> 
    )} />
  )
}

export const Home = () => (
  <ResolveCustomPage page="Home" defaultPageComponent={<DefaultHome />} />
)

const password_validation = (password: string): string | undefined => {
  try {
    // throws error if invalid
    passwordValidation.validateSync(password)
    return 
  } catch(err: any) {
    return err?.message
  }
}

const HIDE_PROMPT = 'hide_set_password_prompt'
export const WithSetInitialPasswordPrompt = ({ children } : { children: React.ReactNode }) => {
  const session = useEnduserSession()
  const [password, setPassword] = useState('')
  const [hide, setHide] = useState(!!window.localStorage[HIDE_PROMPT] || session.userInfo.passwordIsUnset !== true)
  const [showPassword, setShowPassword] = useState(false)
  const theme = useOrganizationTheme()

  const location = useLocation()
  const [searchParams] = useSearchParams()
  const embedded = searchParams.get('embedded')
  if (embedded) {
    try {
      window.localStorage[HIDE_PROMPT] = "true"
    } catch(err) {}
  }

  if (theme.theme.portalSettings?.authentication?.dontPromptSetPassword || embedded || hide || location.search.includes('token=')) {
    return <>{children}</>
  }

  const passwordValidationMessage = password_validation(password)

  const handleHide = () => {
    window.localStorage[HIDE_PROMPT] = new Date();
    setHide(true)
  }

  return (
    <>
    <ResponsiveModal open={true} setOpen={() => undefined} maxWidth={600}>
    <Grid container justifyContent="center">
    <Grid container style={{ width: '100%', maxWidth: 500 }}>
    <Grid container direction="column" justifyContent="center" spacing={2}
      sx={{ minHeight: 500, mb: 6, mt: 4 }}
    >
      <Grid item alignSelf="center" sx={{ width: 60, height: 50, mb: 1 }}>
      <WithCircularBackground >
        <SecurityIcon style={{ width: 30, height: 30 }} />
      </WithCircularBackground>
      </Grid>

      <Grid item alignSelf="center" sx={{ my: 1 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 25, textAlign: 'center', maxWidth: 300 }}>
          Set a password for future logins.
        </Typography>
      </Grid>

      <Grid item alignSelf="center" sx={{ mb: 3 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 17, opacity: 0.55, textAlign: "center", lineHeight: '35px' }}>
          At least 8 characters. <br/>
          A mixture of uppercase and lowercase.<br/>
          A mixture of letters and numbers.<br/>
        </Typography>
      </Grid>


      <Grid item>
        <TextField 
          label="Password" variant="outlined" fullWidth autoFocus
          value={password} onChange={e => setPassword(e.target.value)}
          style={{ borderRadius: 15 }}
          sx={{
            [`& fieldset`]: {
              borderRadius: 15,
            },
            "& .MuiInputBase-input": {
              marginLeft: 1,
            },
          }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{ 
            endAdornment: <TogglePasswordIcon showing={showPassword} onChange={setShowPassword} />
          }}
        />
      </Grid>

      <Grid item>
        <LoadingButton variant="contained" submitText="Save" disabled={!!passwordValidationMessage}     
          onClick={async () => {
            await session.api.endusers.set_password({ id: session.userInfo.id, password })
            handleHide()
          }}
        />
        {password && passwordValidationMessage &&
          <Typography color="error" sx={{ mt: 0.5 }}>
            {passwordValidationMessage} 
          </Typography>
        }
      </Grid>

      <Grid item>
        <Button variant="outlined" fullWidth onClick={handleHide}> 
          No Thanks
        </Button>
      </Grid>
    </Grid>
    </Grid>
    </Grid>
    </ResponsiveModal>
    
    {children}
    </>
  )
}