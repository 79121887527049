// import { SvgIcon, SvgIconProps } from "@mui/material"

import AddIcon from "@mui/icons-material/AddOutlined"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from "@mui/icons-material/EditOutlined"
import EmailIcon from "@mui/icons-material/EmailOutlined"
import PersonIcon from "@mui/icons-material/PersonOutline"
import PhoneIcon from "@mui/icons-material/PhoneOutlined"
import RightArrowIcon from "@mui/icons-material/ArrowRightAlt"
import LikeIcon from '@mui/icons-material/ThumbUpOutlined';
import CommentIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import { Grid } from "@mui/material";
export {
  AddIcon,
  AddCircleIcon,
  AddCircleOutlineIcon,
  CommentIcon,
  EditIcon,
  EmailIcon,
  PersonIcon,
  PhoneIcon,
  RightArrowIcon,
  LikeIcon,
}
export {
  FavoriteBorder as CarePlanIcon,
  Info as InfoIcon,
  VideoCall as VideoCallIcon,
  Search as SearchIcon,
  ArrowForwardIos as ChevronRightIcon,
  FileOpen as FormOpenIcon,
  CheckCircle as CheckCircleIcon,
  Assignment as TaskIcon,
  PendingActions as TaskPendingIcon,
  CalendarToday as CalendarIcon,
  FileCopy as FileIcon,
  Assignment as FormResponseIcon,
  FileDownload as FileDownloadIcon,
  EventAvailable as BookAppointmentIcon,
  InsertDriveFileOutlined as FormIcon,
} from "@mui/icons-material"

export const WithCircularBackground = ({ children } : { children: React.ReactNode }) => {
  return (
    <Grid container alignItems="center" justifyContent="center"
      style={{
        backgroundColor: '#f0f0f0cc',
        borderRadius: 100,
        padding: 6,
      }}
    >
      <Grid item alignSelf="center">
        {children}
      </Grid>
    </Grid>
  )
}