import React, { useEffect, useMemo, useRef, useState } from "react"
import axios from "axios"
import { PortalCustomization } from "@tellescope/types-client"
import { useParams } from "react-router-dom"
import { LinearProgress, Typography } from "@mui/material"
import { useEnduserSession, usePortalCustomizations } from "@tellescope/react-components"
import { useFullHeight } from "../components/navigation"

export const CustomPageIframe = ({ page } : { page: PortalCustomization }) => {
  const globalFrame = useMemo(() => 
    page.iframeURL?.startsWith('global:') ? page.iframeURL.replace('global:', '').trim() : '',
    [page.iframeURL]
  ) 

  const session = useEnduserSession()
  const [iframe, setIframe] = useState('')
  const height = useFullHeight()

  const fetchRef = useRef(iframe)
  useEffect(() => {
    if (globalFrame) return

    const authToken = session.authToken
    if (!authToken) return
    if (!page?.iframeURL) return
    if (fetchRef.current === page.iframeURL) return
    fetchRef.current = page.iframeURL

    axios.post(page.iframeURL, { token: authToken })
    .then(result => setIframe(result?.data?.iframe || ''))
    .catch((err: any) => (
      setIframe(`Error: ${err?.response?.data?.toString() || err?.message || err?.toString()}`)
    ))
  }, [session.authToken, page, globalFrame])

  const resolvedIframe = globalFrame || iframe
  if (resolvedIframe.startsWith("Error")) return <Typography>{resolvedIframe}</Typography>
  if (!resolvedIframe) return <LinearProgress />
  return (
    <iframe width="100%" title={page.page}
      src={resolvedIframe}
      style={{ border: 'none', height }}
    />
  )
}

export const CustomPageIframeLoading = () => {
  const pageId = useParams().pageId
  const [, { findById: findPage }] = usePortalCustomizations() 

  const page = pageId ? findPage(pageId) : undefined

  if (!pageId || page === null) return <Typography>Page not found</Typography>
  if (page === undefined) return <LinearProgress />

  return <CustomPageIframe page={page} />
}