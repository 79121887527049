import { useEffect } from "react"

export const usePolling = (action: Function, delayInMS: number) => {
  useEffect(() => {
    const i = setInterval(async () => {
      try {
        await action()
      } catch(err) {
        console.error('error polling', err)
      }
    }, delayInMS)
    return () => { clearInterval(i) }
  }, [action, delayInMS])
}