import React from "react"
import { Button, Divider, Grid, Typography } from "@mui/material"
import { OrganizationLogo, useOrganizationTheme, usePolicies } from "../definitions/contexts"
import { PORTAL_DEFAULT_LANDING_TITLE } from "@tellescope/constants"
import { routes, useNavigateToPage } from "../definitions/routes"

export const LandingPage = () => {
  const { theme } = useOrganizationTheme()
  const navigate = useNavigateToPage()
  const { registerEnabled } = usePolicies()

  return (
    <Grid container justifyContent="center">
    <Grid container alignItems="center" justifyContent="center" direction="column" wrap="nowrap"
      sx={{ 
        height: '100vh', py: 8, maxWidth: 600,
      }}
    >
      <Grid item>
      <Grid container direction="column" alignItems="center">
        <Grid item sx={{ textAlign: 'center'}}>
          {theme.portalSettings?.authentication?.landingLogo
            ? <img src={theme.portalSettings.authentication.landingLogo} alt="landing logo" style={{ maxHeight: 60, maxWidth: '0-%' }} />
            : <OrganizationLogo />
          }
        </Grid>

        <Grid item sx={{ py: 4, px: 4, textAlign: 'center' }}>
          <Typography color="primary" sx={{ fontSize: 28 }}>
            {theme.portalSettings?.authentication?.landingTitle ?? PORTAL_DEFAULT_LANDING_TITLE}
          </Typography>
        </Grid>

        {theme.portalSettings?.authentication?.landingGraphic &&
          <img src={theme.portalSettings.authentication.landingGraphic} alt="landing logo" 
            style={{ maxHeight: '25vh', maxWidth: '90%' }}
          />
        }
      </Grid>
      </Grid>

      <Grid item sx={{ 
        width: '100%', px: 3, 
        mt: theme.portalSettings?.authentication?.landingGraphic ? '30vh' : '10vh'
      }}>
      <Grid container direction="column" spacing={1}>
        <Grid item>
        <Button fullWidth variant="contained" sx={{ borderRadius: 15, textTransform: 'none'  }}
          onClick={() => navigate(routes.login)}
        >
          Log in
        </Button>
        </Grid>

        {registerEnabled &&
          <>
          <Grid item>
            <Divider flexItem>
              <Typography sx={{ opacity: 0.5 }}>or</Typography>
            </Divider>
          </Grid>
          
          <Grid item sx={{ width: '100%' }}>
          <Button variant="outlined" fullWidth sx={{ borderRadius: 15, textTransform: 'none', fontWeight: 'bold' }}
            onClick={() => navigate(routes.register)}
          >
            Sign up for an account
          </Button>
          </Grid>
          </>
        }
      </Grid>
      </Grid>
    </Grid>
    </Grid>
  )
}