import { Button, Grid, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import axios from "axios"

const useFileViewer____internal = () => {
  const [file, setFile] = useState<{ name: string, url: string, type: string }>()

  return {
    file,
    setFile,
  }
}
const HEADER_HEIGHT = 50
const PADDING = 12
const FileViewerContext = React.createContext({} as ReturnType<typeof useFileViewer____internal>)

const downloadFile = (data: Uint8Array | Blob | Buffer | string, options : { name?: string, dataIsURL?: boolean, type?: string}) => {
  let { name, type } = options
  name = name || "download.txt"
  type = type || "octet/stream"

  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none"

  const blob = new Blob([data], {type});
  const url = (
    (options.dataIsURL && typeof data === 'string')
      ? data
      : window.URL.createObjectURL(blob)
  )
  
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const WithFileViewer = ({ children } : { children: React.ReactNode }) => {
  const value = useFileViewer____internal()

  const forceDownload = value.file?.type?.toLowerCase()?.includes('html')

  useEffect(() => {
    if (!forceDownload) return
    if (!value.file?.url) return

    axios.get(value.file.url, { responseType: 'arraybuffer' })
    .then(r => downloadFile(r.data, { name: value.file?.name || ''}))
    .catch(console.error)
  }, [value, forceDownload])

  if (value.file?.url) {
    return (
      <Grid container direction="column" sx={{ p: `${PADDING}px` }}>
        <Grid container alignItems="center" sx={{ height: HEADER_HEIGHT }}>
          <Button variant="outlined" color="primary" onClick={() => value.setFile(undefined)}>
            Back
          </Button>

          <Typography sx={{ pl: 1, fontSize: 20, }}>
            {value.file.name}
          </Typography>
        </Grid>
 
        <Grid item style={{ paddingTop: PADDING }}>
        {forceDownload // ensure download to prevent XSS threat
          ? <></>
          : value.file?.type?.includes('image')
            ? <img src={value.file.url} alt=""
                style={{
                  maxWidth: window.innerWidth - PADDING * 2,
                  maxHeight: window.innerHeight - HEADER_HEIGHT - PADDING * 4,
                }}
              /> 
            : (
              <iframe title={value.file.name} src={value.file.url} style={{ border: 'none' }}
                width={window.innerWidth - PADDING * 2} 
                height={window.innerHeight - HEADER_HEIGHT - PADDING * 4} 
              />
            )
        
        }
        </Grid>
      </Grid>
    )
  }
  return (
    <FileViewerContext.Provider value={value}>
      {children}
    </FileViewerContext.Provider>
  )
}
export const useFileViewer = () => React.useContext(FileViewerContext)