import React, { createContext, CSSProperties, useContext, useEffect, useRef, useState } from "react"
import { OrganizationTheme } from "@tellescope/types-models"
import axios from "axios"
import { Flex } from "@tellescope/react-components"
import { Typography } from "@mui/material"
import { TellescopeHost } from "./constants"

const OrganizationThemeContext = createContext({} as { 
  theme: OrganizationTheme, 
  setTheme: (theme: OrganizationTheme) => void 
  error: string,
})
export const WithOrganizationTheme = ({ children, subdomain, customPortalURL } : { children: React.ReactNode, subdomain?: string, customPortalURL?: string }) => {
  const [theme, setTheme] = useState({} as OrganizationTheme)
  const [error, setError] = useState('')
  const fetchRef = useRef(false)

  useEffect(() => {
    if (theme.name) return // already loaded
    if (!(subdomain || customPortalURL)) return
    if (fetchRef.current) return
    fetchRef.current = true

    axios.get(`${TellescopeHost}/v1/organization-info`, { params: { subdomain, customPortalURL }})
    .then(r => setTheme(r.data))
    .catch((err: any) => setError(err?.message ?? (err ?? '')?.toString()))
  }, [theme, fetchRef, subdomain, customPortalURL])

  return (
    <OrganizationThemeContext.Provider value={{ error, theme, setTheme }}>
      {children}
    </OrganizationThemeContext.Provider>
  )
}

export const useOrganizationTheme = () => useContext(OrganizationThemeContext)

const LOGO_HEIGHT = 40
export const OrganizationLogo = ({ height=LOGO_HEIGHT, style, imgStyle } : { height?: CSSProperties['height'], style?: React.CSSProperties, imgStyle?: React.CSSProperties } ) => {
  const { theme } = useOrganizationTheme()

  return (
    theme.logoURL 
      ? (
        <Flex alignItems="center" justifyContent={"center"} style={{ height, ...style }}>
          <img src={theme.logoURL} alt={theme.name} height={height} style={imgStyle} /> 
        </Flex>
      )
      : (
        <Typography style={{ fontSize: 22, textAlign: 'center', fontWeight: 600, ...style }}>
          {theme.name}
        </Typography>
      )
  )
}

export const usePolicies = () => {
  const { theme } = useOrganizationTheme()

  return {
    customTermsOfService: theme.customTermsOfService,
    customPrivacyPolicy: theme.customPrivacyPolicy,
    registerEnabled: !!(theme.customTermsOfService && theme.customPrivacyPolicy),
  }
}