import React from "react"

export const pageRootStyle: React.CSSProperties = { 
  minHeight: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
}

export const cardListTitleStyle: React.CSSProperties = {
  fontSize: 18,
  opacity: 0.5,
  marginBottom: 12,
}