import React, { useState } from "react"
import { Grid, TextField, Typography } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { OrganizationLogo, useOrganizationTheme } from "../definitions/contexts"
import { routes, useNavigateToPage } from "../definitions/routes"
import { pageRootStyle } from "../definitions/styles"
import { TogglePasswordIcon } from "../components/inputs"
import { LoadingButton, useEnduserSession } from "@tellescope/react-components"

export const ResetPassword = () => {
  const session = useEnduserSession()
  const { theme } = useOrganizationTheme()
  const navigate = useNavigateToPage()
  const [search] = useSearchParams()
 
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const resetToken = search.get('resetToken')

  if (!resetToken) return (
    <Typography>
      This link is invalid or has expired
    </Typography>
  )
  return (
    <Grid container style={pageRootStyle} direction="column" spacing={2}>
      <Grid item>
        <OrganizationLogo height={60} />
      </Grid>

      <Grid item alignSelf="center">
        <Typography>Password Reset</Typography>
      </Grid>

      <Grid item style={{ width: '90%', maxWidth: 600 }}>
        <TextField label="New Password" variant="outlined" fullWidth 
          value={password} onChange={e => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{ 
            endAdornment: <TogglePasswordIcon showing={showPassword} onChange={setShowPassword} />
          }}
        />

        <LoadingButton submitText="Reset" submittingText="Resetting..."
          disabled={!(resetToken && password) || password.length < 8}
          onClick={async () => {
            setError('')
            session.reset_password({ 
              resetToken, 
              newPassword: password, 
              businessId: theme.businessId,
            })
            .then(() => navigate(routes.login))
            .catch((err: any) => setError(err?.message ?? err?.toString()))
          }}
        />
      </Grid>

      <Grid item>
        <Typography color="error">
          {error.replace('Error parsing field newPassword: ', '')}
        </Typography>
      </Grid>
    </Grid>
  )
}