import React, { useEffect, useState } from "react"
import { useEnduserSession } from "@tellescope/react-components"
import { useSearchParams } from "react-router-dom"
import { LinearProgress, Typography } from "@mui/material"

export const Unsubscribe = () => {
  const session = useEnduserSession()
  const [getParams] = useSearchParams()
  const [unsubscribing, setUnsubscribing] = useState(true)

  const enduserId = getParams.get('enduserId')
  const type = getParams.get('type')

  useEffect(() => {
    if (!enduserId) return
    if (!type) return

    session.api.endusers.unsubscribe({
      enduserId,
      unsubscribeFrom: [type],
    })
    .then(() => setUnsubscribing(false))
    .catch(console.error)
  }, [session, enduserId, type,])
  
  if (!(enduserId && type)) {
    return (
      <Typography>
        Unsubscribe link is invalid
      </Typography>
    )
  }

  if (unsubscribing) {
    return <LinearProgress />
  }
 
  return (
    <Typography>
      You have successfully unsubscribed
    </Typography>
  )
}