import React, { useState } from "react"
import { 
  FileBlob, 
  FileDropzone, FormikSubmitButton, initial_values_for_input_schema, 
  LabeledIconButton, LoadingButton, Styled, useFileUpload, 
  validation_for_input_schema,
  Form,
  SubmitButton,
  useEnduserSession,
} from "@tellescope/react-components"
import { Formik, FormikProps } from "formik"
import { accountFields, accountFieldsRelaxed } from "../definitions/schemas"
import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, TextFieldProps, Typography } from "@mui/material"

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../css/datepicker.css"
import { Indexable } from "@tellescope/utilities"
import { VisibilityIcon, VisibilityOffIcon } from "./icons"
import { File } from "@tellescope/types-client"
import { ResponsiveModal } from "./layout"
import { TermsOfServiceAndPrivacyPolicyConsent } from "./agreements"

export interface FormikInput <T extends Indexable<string>> extends Styled {
  formik: FormikProps<T>,
  field: (keyof T & string),
  placeholder?: string,
  label?: string,
  disabled?: boolean,
  onChangeModifier?: (s: string) => string,
}
export interface FormikInputLabeled <T extends Indexable<string>> extends FormikInput<T> { label: string }
export const FormikTextField = <T extends Indexable<string>>({ field, formik, onChangeModifier, ...props } : FormikInputLabeled<T> & TextFieldProps) => (
  <TextField {...props} 
    name={field} 
    error={formik.touched[field] && Boolean(formik.errors[field])}
    helperText={formik.touched[field] && formik.errors[field]}
    value={formik.values[field]} 
    onChange={e => { 
      formik.setFieldTouched(field); 

      if (onChangeModifier) {
        e.target.value = onChangeModifier(e.target.value)
      }
      formik.handleChange(e)
    }}
  />
)

export const TogglePasswordIcon = ({ showing, onChange } : { showing: boolean, onChange: (b: boolean) => void }) => {
  return (
    <LabeledIconButton 
      Icon={showing ? VisibilityIcon : VisibilityOffIcon}
      label={showing ? 'Hide Password' : 'Show Password'} 
      onClick={() => onChange(!showing)}
    />
  )
}

const ResetPassword = () => {
  const session = useEnduserSession()
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState(false)
  const [error, setError] = useState('')

  return (
    <>
    <ResponsiveModal open={open} setOpen={setOpen} style={{ maxWidth: 500 }}>
      <Form style={{ width: '100%' }}
        onSubmit={async () => {
          setError('')

          session.request_password_reset({ email, businessId: session.userInfo.businessId })
          .then(() => {
            alert("Please check your email for a link to reset your password")
            setOpen(false)
            setEmail('')
          })
          .catch((err: any) => setError(err?.message ?? err?.toString()))
        }}
      >
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{
        py: 10,
      }}>
        <Typography style={{ fontSize: 18, marginBottom: 5 }}>
          Password Reset Request
        </Typography>

        <TextField type="email" label="Email" placeholder="Enter your email..."
          value={email} onChange={e => setEmail(e.target.value)} fullWidth 
          style={{ maxWidth: 400 }}
        />

        <Grid container style={{ maxWidth: 400 }}>
          <SubmitButton disabled={!email} 
            submitText="Reset Password"
            submittingText="Working..."
          />
        </Grid>
      
        <Typography color="error" style={{ marginTop: 5 }}>
          {error}
        </Typography>
      </Grid>
      </Form>
    </ResponsiveModal>

    <Typography onClick={() => setOpen(true)} sx={{
      color: 'primary.main',
      textDecoration: 'underline',
      cursor: 'pointer',
      fontSize: 15,
    }}>
      Forgot password?
    </Typography>
    </>
  )
}

export const EmailAndPasswordInput = ({
  onSubmit,
  submitText="Submit",  
  submittingText=submitText,
  relaxed,
  requireTerms,
  alternateButtonText,
  onClickAlernateButton,
  email,
}: { 
  onSubmit: (args: { email: string, password: string }) => Promise<string | undefined>,
  email?: string,
  submitText?: string,
  submittingText?: string,
  relaxed?: boolean
  requireTerms?: boolean,
  alternateButtonText?: string,
  onClickAlernateButton: () => void,
}) => {
  const [error, setError] = React.useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [termsSigned, setTermsSigned] = useState(false)

  const schema = relaxed ? accountFieldsRelaxed : accountFields

  return (
    <Formik
      initialValues={initial_values_for_input_schema(schema, { email })}
      validationSchema={validation_for_input_schema(schema)}
      onSubmit={async ({ email, password }) => {
        if (!(email && password)) return
        setError('')

        const errorMessage = await onSubmit({ email, password })
        if (errorMessage) {
          setError(errorMessage)
        }
      }}
    >{formik => (
      <form onSubmit={formik.handleSubmit}>
        <Grid container style={{ marginBottom: 10 }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <FormikTextField formik={formik} field="email" label="Email" variant="outlined" fullWidth 
              autoFocus={!email}
              disabled={!!email} // when email is provided, lock by default
              style={{ borderRadius: 15 }}
              sx={{
                [`& fieldset`]: {
                  borderRadius: 15,
                },
                "& .MuiInputBase-input": {
                  marginLeft: 1,
                },
              }}
            />
          </Grid>

          <Grid item xs={12}>
          <Grid container direction="column">
            <Grid item>
              <FormikTextField formik={formik} field="password" label="Password" variant="outlined" fullWidth 
                autoFocus={!!email}
                style={{ borderRadius: 15 }}
                sx={{
                  [`& fieldset`]: {
                    borderRadius: 15,
                  },
                  "& .MuiInputBase-input": {
                    marginLeft: 1,
                  },
                }}
                type={showPassword ? 'text' : 'password'}
                InputProps={{ 
                  endAdornment: <TogglePasswordIcon showing={showPassword} onChange={setShowPassword} />
                }}
              />
            </Grid>
            
            <Grid item alignSelf="flex-end" sx={{ mt: '3px' }}>
              <ResetPassword />
            </Grid>
          </Grid>
          </Grid> 
          
          <Grid item xs={12}>
          {requireTerms && 
            <TermsOfServiceAndPrivacyPolicyConsent value={termsSigned} onChange={setTermsSigned} />
          }
          </Grid>
        </Grid> 

        <FormikSubmitButton formik={formik} submitText={submitText} submittingText={submittingText} 
          disabled={requireTerms && !termsSigned}
          style={{ borderRadius: 15, width: '100%', textTransform: 'none' }} 
        />
        <Typography color="error" style={{ marginTop: 5 }}>{error}</Typography>


        {alternateButtonText &&
          <>
          <Divider flexItem sx={{ my: 1 }}>
            <Typography sx={{ opacity: 0.5 }}>or</Typography>
          </Divider>
          <Button variant="outlined" onClick={onClickAlernateButton} 
            sx={{ borderRadius: 15, width: '100%', textTransform: 'none' }}
          >
            {alternateButtonText}
          </Button>
          </>
        }
      </form>
    )}
  </Formik>
  )
}

const CustomDateInput = React.forwardRef((props: TextFieldProps, ref) => <TextField fullWidth inputRef={ref} {...props} />)

export const DateTimeSelector = ({ placement='top', inputProps, style, ...props } : {
  selected: Date,
  onChange: (d: Date) => void,
  placement?: 'top' | 'right' | 'bottom' | 'left'
  style?: React.CSSProperties,
  inputProps?: TextFieldProps,
  maxDate?: Date,
  minDate?: Date,
}) => {
  const inputRef = React.useRef(null);

  return (
    <div className="customDatePickerWidth" style={style}>
    <DatePicker // wrap in item to prevent movement on focused
      {...props}
      showTimeSelect
      dateFormat="Pp"
      autoComplete="off"
      timeIntervals={15} // 30 is default
      popperPlacement={placement}
      customInput={<CustomDateInput inputRef={inputRef} {...inputProps} />}
      // className={css`width: 100%;`}
    />
    </div>
  )
}

// todo: allow for restrictions on file type, more complex handling, etc
export const UploadFileUncontrolled = ({
  onSuccess,
} : {
  onSuccess?: (f: File) => void,
}) => {
  const { handleUpload, uploading } = useFileUpload()

  const [file, setFile] = useState<undefined | FileBlob>(undefined)
  const [error, setError] = useState('')

  return (
    <Grid container direction="column" style={{ maxWidth: 400 }}>

    <FileDropzone file={file} onChange={setFile}  
      label={`Select a file`}
      // accept={".png,.jpg,.jpeg,.gif"}
      dropzoneStyle={{
        cursor: 'pointer',
        border: '1px solid black',
        borderRadius: 5,
        height: 200,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />

    <LoadingButton submitText="Upload" submittingText="Uploading..." 
      disabled={!file}
      submitting={uploading} onClick={async () => {
        if (!file) return
        setError('')

        try {
          const uploaded = await handleUpload({
            name: file.name,
            type: file.type,
            size: file.size,
          }, file)
    
          setFile(undefined) 
          onSuccess?.(uploaded)
        } catch(err: any) {
          setError(err?.message ?? err?.toString())
        }
      }}
    />

    <Typography color="error">{error}</Typography>
    
    </Grid>
  )
}

export const StringSelector = <T extends string>({ 
  options, value, onChange, onClear, id, label, 
  placeholder,
  style, itemStyle, selectStyle,
  ...selectProps
} : {
  options: T[],
  value?: T,
  onChange: (v: T) => void,
  onClear?: () => void,
  id?: string,
  placeholder?: string,
  style?: React.CSSProperties,
  label?: string,
  itemStyle?: React.CSSProperties,
  selectStyle?: React.CSSProperties,
  size?: "small"
}) => {
  return (
    <FormControl style={style}>
      {label &&
        <InputLabel id={`${id}-label`}>{label}</InputLabel>
      }
      <Select {...selectProps} style={selectStyle}
        labelId={`${id}-label`} id={id}
        value={value}
        label={label}
        onChange={({ target: { value } }) => value ? onChange(value as T) : onClear?.()}
      >
        {placeholder && <MenuItem value={''}>{placeholder}</MenuItem>}
        {options.map(o => (
          <MenuItem value={o} style={itemStyle}>
            {o}
          </MenuItem>
        ))} 
      </Select>
    </FormControl>
  )
}