import React from "react"
import { Grid, Typography } from "@mui/material"
import { LoadingLinear, Table, useEnduserOrders, usePortalCustomizations } from "@tellescope/react-components"
import { OPEN_LOOP_TITLE } from "@tellescope/constants"
import { first_letter_capitalized } from "@tellescope/utilities"
import { StripeCustomerPortalButton } from "../components/StripeCustomerPortal"

export const OpenLoopOrders = () => {
  const [, { filtered }] = useEnduserOrders()
  const [, { findByFilter }] = usePortalCustomizations()
  const customization = findByFilter(c => c.page === 'Orders')


  return (
    <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
      {customization?.showStripePortalLink && 
        <Grid item>
          <StripeCustomerPortalButton />
        </Grid>
      }

      <Grid item>
      <LoadingLinear data={filtered(o => o.source === OPEN_LOOP_TITLE)} render={orders => (
      <Table items={orders} emptyText="Your orders will appear here"
        fields={[
          {
            key: 'title', label: "Pharmacy",
            width: 225,
            render: v => (
              <Typography noWrap sx={{ maxWidth: 220 }}>
                {v.title.replace('OpenLoop: ', '')}
              </Typography>
            )
          },
          {
            key: "status", label: "Status",
            width: 100,
            render: v => (
              <Typography noWrap sx={{ maxWidth: 90 }}>
                {first_letter_capitalized(v.status)}
              </Typography>
            )
          },
          {
            key: 'instructions', label: "Instructions",
            width: 400,
            render: v => (
              <div dangerouslySetInnerHTML={{ __html: v.instructions || '' }} />
            )
          },
          {
            key: "shippedDate", label: "Shipped On",
            width: 150,
            render: v => (
              <Typography noWrap sx={{ maxWidth: 140 }}>
                {v.shippedDate}
              </Typography>
            )
          },
          {
            key: "tracking", label: "Tracking",
            width: 250,
            render: v => (              
              v.tracking
                ? (
                  <a target="_blank" rel="noopener noreferrer"
                    href={v.tracking.startsWith('http') ? v.tracking : `https://www.google.com/search?q=${v.tracking}`}
                  >
                  <Typography noWrap sx={{ maxWidth: 140, fontSize: 14 }}>
                    {v.tracking}
                  </Typography>
                  </a>
                )
                : (
                  <Typography noWrap sx={{ maxWidth: 140, fontSize: 14 }}>
                    {v.tracking}
                  </Typography>
                ) 
            )
          },
        ]}
      /> 
      )} />
      </Grid>
    </Grid>
  )
}

export const Orders = () => <OpenLoopOrders />