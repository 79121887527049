import React, { useState } from "react"
import { Grid, Modal as MuiModal, Paper, PaperProps } from "@mui/material"
import { Styled } from "@tellescope/react-components"
import { useWindowWidth } from "./navigation"

export const HoverPaper = (props: PaperProps) => {
  const [elevation, setElevation] = useState(3)
 
  return (
    <Paper {...props} 
      elevation={elevation} 
      onMouseEnter={() => setElevation(5)} onMouseLeave={() => setElevation(2)} 
    />
  )
}

interface ModalProps extends Styled {
  children: React.ReactNode,
  open: boolean,
  setOpen: (b: boolean) => void,
  width?: React.CSSProperties['width']
  maxWidth?: React.CSSProperties['maxWidth']
}
export const ResponsiveModal = ({ children, width, maxWidth, style, open, setOpen }: ModalProps) => {
  const windowWidth = useWindowWidth()

  return (
    <MuiModal open={open} onClose={() => setOpen(false)}>
    <Grid container style={style} sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: 250,
      width: width ?? windowWidth - 40,
      maxWidth,
      bgcolor: 'background.paper',
      borderRadius: 2,
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
      maxHeight: "calc(100vh - 40px)",
      outline: 'none',
      overflowY: 'auto',
    }}>
      {children}
    </Grid>
    </MuiModal>
  )
}
