import { Typography } from "@mui/material"
import { useEnduserSession } from "@tellescope/react-components"
import React from "react"

export const TitleWithFirstName = ({ ending } : { ending: string }) => {
  const session = useEnduserSession()

  return (
    <Typography style={{ fontSize: 25 }}>
      <Typography color="primary" component="span" style={{ fontSize: 'inherit'}}>
        {session.userInfo.fname ? `${session.userInfo.fname}'${!session.userInfo.fname.endsWith('s') ? 's' : ''}` : "Your"}{' '}
      </Typography> 

      {ending}
    </Typography>
  )
}