// MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS

import React, { useEffect, useState } from "react"
import Grid from "@mui/material/Grid"
import { Enduser, Form, FormField, FormResponse } from "@tellescope/types-client"
import { TellescopeForm, useEnduserSession, useTellescopeForm } from "@tellescope/react-components"
import { Typography } from "@mui/material"
import { FormResponseValue } from "@tellescope/types-models"
import { get_prepopulated_responses } from "@tellescope/utilities"

// MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS
const FormForLoadedData = ({
  accessCode,
  automationStepId,
  enduserId='', 
  formResponseId,
  fields,
  thanksMessage,
  htmlThanksMessage,
  alreadySubmitted,
  form,
  hideBg,
  existingResponses,
  urlLogicValue,
  enduser,
} : { 
  accessCode: string,
  formResponseId: string,
  automationStepId?: string,
  enduserId?: string,
  form: Form
  fields: FormField[],
  thanksMessage?: string,
  htmlThanksMessage?: string,
  alreadySubmitted?: boolean,
  existingResponses?: FormResponseValue[] 
  hideBg?: boolean,
  urlLogicValue?: string,  
  enduser?: Partial<Enduser>
}) => {
  const session = useEnduserSession()

  const [submitted, setSubmitted] = useState(alreadySubmitted || false)
  
  try {
    const formProps = useTellescopeForm({
      accessCode,
      formResponseId,
      automationStepId,
      enduserId, // library autofills in enduserSession
      fields,
      formTitle: form.displayTitle || form.title,
      ga4measurementId: form.ga4measurementId,
      submitRedirectURL: form.submitRedirectURL,
      customization: form.customization,
      urlLogicValue: urlLogicValue,
      form,
      enduser,
      existingResponses: (
        existingResponses?.length
          ? existingResponses
          : get_prepopulated_responses(fields, session.userInfo)
      )
    })
  
  
    // MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS
    return (
      <TellescopeForm form={form} enduser={enduser}
        {...formProps} submitted={submitted} 
        hideBg={hideBg} backgroundColor={form.backgroundColor}
        thanksMessage={thanksMessage} 
        htmlThanksMessage={htmlThanksMessage}  
        onSuccess={() => setSubmitted(true)} 
      />
    )
  } catch(err: any) {
    const message = (
      typeof err === 'string'
        ? err
    : typeof err?.message === 'string'
        ? err.message
        : 'An error occurred'
    )
    return (
      <Typography>
        {message === "Root not found for given fields"
          ? "Start question not found for form"
          : message
        } 
      </Typography>
    )
  }
  
}

// MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS
const FormDisplay = ({ 
  formId: _formId, 
  accessCode: _accessCode, 
  automationStepId: _automationStepId,
  existingResponses,
} : { formId?: string, accessCode?: string, automationStepId?: string, existingResponses?: FormResponseValue[]  }) => {
  const q = new URLSearchParams(window.location.search)
  const formId = _formId || q.get('f')!
  const accessCode = _accessCode || q.get('a')!
  const automationStepId = _automationStepId ?? q.get('stepId') ?? undefined
  const hideBg = q.get('hideBg') ?? undefined
  const urlLogicValue = q.get('logic') || undefined
  const session = useEnduserSession()
  
  const [form, setForm] = useState(undefined as Form | undefined | null)
  const [fields, setFields] = useState<FormField[]>([])
  const [formResponseInfo, setFormResponseInfo] = useState(undefined as FormResponse | undefined | null)

  useEffect(() => {
    if (!formId) return;
    if (!accessCode) return;
    
    session.api.form_responses.info_for_access_code({ accessCode }) 
    .then(({ response, form, fields }) => {
      setForm({ ...form, id: form.id } as any as Form)
      setFields(fields)
      if (response) { 
        setFormResponseInfo(response)
      }
    })
    .catch((err: string) => {
      console.error(err)
      setFormResponseInfo(null)
    })
  }, [accessCode, formId, session])

  if (formResponseInfo === null) {
    return (
      <div>This form is no longer available</div>
    )
  }

  if (!(form && fields && formResponseInfo)) return null

  // MAKE SURE ANY CHANGES ARE COPIED TO CODE IN PROVIDER APP FOR OLD LINKS

  const alreadySubmitted = !!formResponseInfo.submittedAt // (formResponseInfo.responses?.length ?? 0) > 0
  return (
    <Grid container wrap="nowrap" sx={{ minHeight: '100vh' }}>
    <FormForLoadedData alreadySubmitted={alreadySubmitted}
      urlLogicValue={urlLogicValue}
      form={form} existingResponses={existingResponses}
      formResponseId={formResponseInfo.id}
      enduser={session.userInfo}
      htmlThanksMessage={form.htmlThanksMessage}
      thanksMessage={alreadySubmitted 
        ? "This form has already been submitted"
        : form.thanksMessage
      }
      hideBg={!!hideBg}
      accessCode={accessCode} automationStepId={automationStepId} fields={fields} 
    />
    </Grid>
  )
}


export default FormDisplay