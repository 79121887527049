import React, { useEffect, useMemo, useRef, useState } from "react"
import { BaseAvailabilityBlock, StripeCheckoutInfo, Timezone } from "@tellescope/types-models"
import { BookingPage, TrackingEvent } from "./types"
import { 
  useEnduserSession,
  PublicAppointmentBookingInfo,
} from "@tellescope/react-components"
import { AppointmentLocation, Enduser, CalendarEventTemplate, CalendarEvent, User } from "@tellescope/types-client"

import ReactGA from "react-ga4";
import { useSearchParams } from "react-router-dom"
import { Typography } from "@mui/material"

export const getLocalTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone as Timezone

// internal for easier type definitions in context (and potential re-use elsewhere?)
const useBookingFlowState = ({ scheduledBy, formResponseId, holdAppointmentMinutes, userFilterTags, userTags, skipState, userId: _userId, userIds: _userIds, isAuthenticated, bookingToken, minDate, maxDate, fields, multi, rescheduledCalendarEventId } : { 
  userId?: string, 
  userIds?: string[],
  isAuthenticated?: boolean,
  bookingToken?: string,
  minDate?: Date,
  maxDate?: Date,
  fields?: Record<string, string>,
  rescheduledCalendarEventId?: string,
  multi?: boolean,
  skipState?: boolean,
  userTags?: string[],
  userFilterTags?: string[],
  formResponseId?: string,
  holdAppointmentMinutes?: number,
  scheduledBy?: string,
}) => {
  const [searchparams] = useSearchParams()
  const [page, setPage] = useState(BookingPage.Loading) 
  const [selectedSlot, setSelectedSlot] = useState<BaseAvailabilityBlock>({
    startTimeInMS: Date.now(), // start with current day selected
    durationInMinutes: -1, // indicate no slot selected
    userId: '', // indicate no slot selected
  })
  const [stripe, setStripe] = useState<StripeCheckoutInfo & { complete?: boolean }>()
  const [userId, setUserId] = useState(_userId || searchparams.get('userId') || undefined)
  const [userIds, setUserIds] = useState(_userIds)
  const [noUsersFound, setNoUsersFound] = useState(false)
  const [template, setTemplate] = useState<CalendarEventTemplate>()
  const [location, setLocation] = useState<AppointmentLocation>()
  const [loadedSlots, setLoadedSlots] = useState<Record<string, BaseAvailabilityBlock[]>>({})
  const [intakeInfo, setIntakeInfo] = useState<Partial<Enduser>>()
  const [loadedInfo, setLoadedInfo] = useState<PublicAppointmentBookingInfo>()
  const [error, setError] = useState('')
  const [disabledDays, setDisabledDays] = useState<Date[]>([])
  const [timezone, setTimezone] = useState(getLocalTimezone())
  const [bookedEvent, setBookedEvent] = useState<CalendarEvent>()
  const [selectedUsers, setSelectedUsers] = useState<User[]>([])
  const [locationToggleCount, setLocationToggleCount] = useState(0)
  const [reason, setReason] = useState('')

  const trackSuccess = React.useCallback(() => {
    console.log('tracking Success')
    ReactGA.event({
      category: "booking_page",
      action: "Completed Booking",
      label: "Completed Booking", // optional
      transport: "xhr", // optional, beacon/xhr/image
      value: 1,
    });
  }, [])

  const restrictByState = useMemo(() => {
    const templateRestrictions = (
      template?.id && loadedInfo?.appointmentBookingPage?.restrictionsByTemplate?.find(t => t.templateId === template.id)
    )
    if (templateRestrictions) { return !!templateRestrictions.restrictions.state }

    return loadedInfo?.appointmentBookingPage?.limitedByState
  }, [template, loadedInfo])

  const restrictByCareTeam = useMemo(() => {
    const templateRestrictions = (
      template?.id && loadedInfo?.appointmentBookingPage?.restrictionsByTemplate?.find(t => t.templateId === template.id)
    )
    if (templateRestrictions) { return !!templateRestrictions.restrictions.careTeam }

    return loadedInfo?.appointmentBookingPage?.limitedToCareTeam
  }, [template, loadedInfo])

  const hoursBeforeRestriction = useMemo(() => {
    const templateRestrictions = (
      template?.id && loadedInfo?.appointmentBookingPage?.restrictionsByTemplate?.find(t => t.templateId === template.id)
    )
    if (templateRestrictions) { return templateRestrictions.restrictions.hoursBefore }

    return loadedInfo?.appointmentBookingPage?.hoursBeforeBookingAllowed
  }, [template, loadedInfo])

  const hoursAfterRestriction = useMemo(() => {
    const templateRestrictions = (
      template?.id && loadedInfo?.appointmentBookingPage?.restrictionsByTemplate?.find(t => t.templateId === template.id)
    )
    if (templateRestrictions) { return templateRestrictions.restrictions.hoursAfter }
  }, [template, loadedInfo])

  return {
    locationToggleCount, setLocationToggleCount,
    bookedEvent, setBookedEvent,
    page, setPage,
    stripe, setStripe,
    selectedSlot, setSelectedSlot,
    location, setLocation,
    template, setTemplate,
    intakeInfo, setIntakeInfo,
    loadedInfo, setLoadedInfo,
    error, setError,
    loadedSlots, setLoadedSlots,
    disabledDays, setDisabledDays,
    timezone, setTimezone,
    trackSuccess, isAuthenticated,
    userId, setUserId, userIds, setUserIds,
    bookingToken,
    minDate: (
      minDate 
      || (hoursBeforeRestriction 
        ? new Date(Date.now() + hoursBeforeRestriction * 60 * 60 * 1000)
        : undefined
      )
    ), 
    maxDate,
    rescheduledCalendarEventId,
    fields, multi,
    skipState,
    userTags, userFilterTags,
    selectedUsers, setSelectedUsers,
    noUsersFound, setNoUsersFound,
    formResponseId, holdAppointmentMinutes,
    reason, setReason,
    scheduledBy, 
    restrictByState, restrictByCareTeam,
    hoursBeforeRestriction, hoursAfterRestriction,
  }
}

export const BookingFlowContext = React.createContext({ } as ReturnType<typeof useBookingFlowState>)
export const useBookingFlowContext = () => React.useContext(BookingFlowContext)
export const WithBookingFlowContext = ({ scheduledBy, formResponseId, holdAppointmentMinutes, userFilterTags, userTags, skipState, businessId, fields, userIds, multi, rescheduledCalendarEventId, appointmentBookingPageId, userId, children, isAuthenticated, bookingToken, minDate, maxDate } : { 
  children: React.ReactNode,
  businessId: string,
  appointmentBookingPageId: string,
  userId?: string,
  userIds?: string[],
  isAuthenticated?: boolean,
  bookingToken?: string,
  rescheduledCalendarEventId?: string,
  minDate?: Date,
  maxDate?: Date,
  fields?: Record<string, string>
  multi?: boolean,
  skipState?: boolean,
  userTags?: string[],
  userFilterTags?: string[],
  formResponseId?: string,
  holdAppointmentMinutes?: number,
  scheduledBy?: string,
}) => {
  const state = useBookingFlowState({ 
    userId, userIds, isAuthenticated, bookingToken, minDate, maxDate, fields, rescheduledCalendarEventId, multi,
    skipState, userTags, userFilterTags, formResponseId, holdAppointmentMinutes, scheduledBy
  })
  const loadRef = useRef(false)
  const session = useEnduserSession()
  const trackingRef = useRef({ } as Record<TrackingEvent, boolean>)
  const [bookingTokenIsValid, setBookingTokenIsValid] = useState(
    bookingToken
      ? undefined
      : true
  )

  useEffect(() => {
    if (!bookingToken) return
    if (bookingTokenIsValid !== undefined) return

    session.api.appointment_booking_pages.validate_access_token({ token: bookingToken })
    .then(({ isValid }) => setBookingTokenIsValid(isValid))
    .catch(err => {
      console.error(err)
      setBookingTokenIsValid(false)
    })
  }, [bookingToken, bookingTokenIsValid, session])

  // load initial data to populate a booking flow
  useEffect(() => {
    if (state.page !== BookingPage.Loading) return
    if (loadRef.current === true) return
    if (!bookingTokenIsValid) return
    loadRef.current = true

    session.api.calendar_events.details_for_appointment_booking_page({
      businessId,
      appointmentBookingPageId,
      userId: state.userId,
      userTags, userFilterTags,
    })
    .then(info => {
      // don't require user selection, but userIds are filtered by tags
      if (userFilterTags?.length) {
        state.setUserIds((info.users || []).map(u => u.id))
        if (!info.users?.length) {
          state.setNoUsersFound(true)
        }
      }

      state.setLoadedInfo(info)

      if (!info?.appointmentBookingPage?.requireLocationSelection) {
        const location = info.locations?.[0]
        state.setLocation(location)
        if (location?.timezone) {
          state.setTimezone(location.timezone)
        }
      }
      
      if (info.calendarEventTemplates.length === 1) {
        if (info.appointmentBookingPage?.limitedByState) {
          // intake must come first to ensure enduser state is collected before showing calendar
          state.setPage(BookingPage.Intake)
        } else { // intake can come after booking time (as initially designed)
          state.setPage(BookingPage.Calendar)
        }
        
        state.setTemplate(info.calendarEventTemplates[0])
      } else {
        state.setPage(BookingPage.PickAppointmentType)
      }
    })
    .catch(err => {
      console.error(err)
      state.setError(err?.message ?? err?.toString())
    })
  }, [state, loadRef, session, businessId, appointmentBookingPageId, bookingTokenIsValid, userTags, userFilterTags])

  // initialize react GA
  useEffect(() => {
    if (!state.loadedInfo?.appointmentBookingPage.ga4measurementId) return

    ReactGA.initialize(state.loadedInfo.appointmentBookingPage.ga4measurementId);
  }, [state.loadedInfo])

  // track progress via GA
  useEffect(() => {
    if (state.page === BookingPage.Calendar && !trackingRef.current['view']) {
      trackingRef.current['view'] = true;
      return ReactGA.event({
        category: "booking_page",
        action: "Booking Widget Loaded",
        label: "Booking Widget Loaded", // optional
        transport: "xhr", // optional, beacon/xhr/image
        value: 1,
      });
    }
    if (state.page === BookingPage.Intake && !trackingRef.current['selected_time']) {
      trackingRef.current['selected_time'] = true;
      return ReactGA.event({
        category: "booking_page",
        action: "Selected Time",
        label: "Selected Time", // optional
        transport: "xhr", // optional, beacon/xhr/image
        value: 1,
      });
    }
    if (state.page === BookingPage.Confirmation && !trackingRef.current['completed_intake']) {
      trackingRef.current['completed_intake'] = true;
      return ReactGA.event({
        category: "booking_page",
        action: "Completed Intake",
        label: "Completed Intake", // optional
        transport: "xhr", // optional, beacon/xhr/image
        value: 1,
      });
    }
    // use trackSuccess instead in cases of both redirect and built-in thank you
    // if (state.page === BookingPage.ThankYou && !trackingRef.current['booked']) {
    //   trackingRef.current['booked'] = true;
    //   return ReactGA.event({
    //     category: "booking_page",
    //     action: "Booked Appointment",
    //     label: "Booked Appointment", // optional
    //     transport: "xhr", // optional, beacon/xhr/image
    //     value: 1,
    //   });
    // }
  }, [state.page, trackingRef])

  if (bookingTokenIsValid === undefined) return null
  if (bookingTokenIsValid === false) {
    return (
      <Typography>
        This link has expired
      </Typography>
    )
  }
  return (
    <BookingFlowContext.Provider value={state}>
      {children}
    </BookingFlowContext.Provider>
  )
}
