import React, { useRef } from "react"
import { Grid, LinearProgress, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { useEnduserSession, useFormResponses } from "@tellescope/react-components"
import FormDisplay from "../FormDisplay"
import { FormResponseView } from "./Documents/SubmittedForms"

export const FormResponsePage = () => {
  const session = useEnduserSession()
  const [, { findById: findFormResponse }] = useFormResponses({ dontFetch: true })
  const formResponseId = useParams().id
  const didCompleteRef = useRef(false)

  if (!formResponseId) return (
    <Typography>
      Invalid URL
    </Typography>
  )

  const formResponse = findFormResponse(formResponseId)
  if (formResponse === undefined) {
    return <LinearProgress />
  }
  if (formResponse === null) {
    return (
      <Typography>
        Error loading form - it may have been deleted
      </Typography>
    )
  }

  if (!formResponse.submittedAt) {
    didCompleteRef.current = true
    return (
      <FormDisplay formId={formResponse.formId} accessCode={formResponse.accessCode} 
        automationStepId={formResponse.automationStepId}
        existingResponses={formResponse.responses}
      />
    )
  }

  if (didCompleteRef.current) return <Typography>Your form submission has been received!</Typography>
  return (
    <Grid container sx={{ p: 2 }}>
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography>Your submitted response:</Typography>
    </Grid>

    <FormResponseView enduser={session.userInfo}
      // when a specific field is sharedWithEnduser, only show sharedWithEnduser fields
      response={
        formResponse?.responses?.find(r => r.sharedWithEnduser === true)
          ? {
            ...formResponse, responses: formResponse.responses.filter(r => r.sharedWithEnduser)
          }
          : formResponse
      }
    />
    </Grid>
  )
}