import React, { useState } from "react"
import {
  Button,
  Divider,
  Grid, 
  SxProps, 
  TextField, 
  Typography,
} from "@mui/material"
import { Form, LabeledIconButton, SubmitButton, useEnduserSessionContext } from "@tellescope/react-components"
import { EmailAndPasswordInput } from "../components/inputs"
import { routes, useNavigateToPage } from "../definitions/routes"
import { OrganizationLogo, useOrganizationTheme, usePolicies } from "../definitions/contexts"
import { GoBackArrowIcon } from "../components/icons"
import { PORTAL_DEFAULT_LOGIN_DESCRIPTION, PORTAL_DEFAULT_LOGIN_TITLE } from "@tellescope/constants"
import { LoginFlowResult } from "@tellescope/validation"

import isEmail from "validator/lib/isEmail"

const passwordlessSX: SxProps = {
  fontSize: 22, p: 4, textAlign: 'center'
}
export const Login = () => {
  const { enduserSession, updateLocalSessionInfo } = useEnduserSessionContext()
  const navigate = useNavigateToPage()
  const { theme } = useOrganizationTheme()
  const { registerEnabled } = usePolicies()

  const [emailOrPhone, setEmailOrPhone] = useState('')
  const [enduserEmail, setEnduserEmail] = useState('')
  const [result, setResult] = useState<LoginFlowResult>()

  return (
    <Grid container justifyContent="center">
    <Grid container direction="column" justifyContent='center' wrap="nowrap"
      sx={{ height: '100vh', py: 4, maxWidth: 600 }}
    >
      <Grid item>
      <Grid container direction="column" alignItems="center" spacing={2}>
        <Grid item alignSelf="flex-start">
          {theme.portalSettings?.authentication?.hideRegister !== true &&
            <LabeledIconButton Icon={GoBackArrowIcon} label="Go Back" color="default"
              style={{ marginLeft: 5 }}
              onClick={() => {
                if (!result) {
                  return navigate(routes.landing)
                }
                setResult(undefined)
                setEmailOrPhone('')
                setEnduserEmail('')
              }}
            />
          }
        </Grid>

        <Grid item>
        <Grid container justifyContent="center">
          {theme.portalSettings?.authentication?.loginGraphic
            ? <img src={theme.portalSettings.authentication.loginGraphic} alt="login graphic" 
                style={{ maxHeight: '30vh', maxWidth: '80%' }}
              />
            : <OrganizationLogo height={60} />
          }
        </Grid>
        </Grid>

        <Grid item>
          <Typography color="primary" sx={{ fontSize: 45, px: 2, textAlign: 'center' }}>
            {theme.portalSettings?.authentication?.loginTitle ?? PORTAL_DEFAULT_LOGIN_TITLE}
          </Typography>
        </Grid>

        <Grid item>
          <Typography sx={{ fontSize: 17, textAlign: 'center', mb: 1 }}>
            {theme.portalSettings?.authentication?.loginDescription ?? PORTAL_DEFAULT_LOGIN_DESCRIPTION}
          </Typography>
        </Grid>

        {result === 'sent-email' ? (
          <Typography sx={passwordlessSX}>
            Please check your email for a link to access your account
          </Typography>
        ) 
        : result === 'sent-sms' ? (
          <Typography sx={passwordlessSX}>
            Please check your phone for a text with a link to access your account
          </Typography>
        ) : null 
        }
      </Grid>
      </Grid>

      <Grid item sx={{ px: 2, mt: '25vh' }}>
        {result === 'continue-with-password'
          ? (
            <EmailAndPasswordInput relaxed submitText="Log in" submittingText="Logging in..."
              email={enduserEmail}
              onSubmit={async ({ email, password }) => {
                try {
                  const { authToken, enduser } = await enduserSession.authenticate(email, password) 
                  updateLocalSessionInfo(enduser, authToken)
                } catch(err: any) {
                  return err?.message ?? 'An unknown error occurred'
                }
              }}
              alternateButtonText={theme.portalSettings?.authentication?.hideRegister ? undefined : "Sign up for an account"}
              onClickAlernateButton={() => navigate(routes.register)}
            /> 
          ) 
        : result === 'sent-email' ? null // text moved higher
        : result === 'sent-sms' ? null // text moved higher
        : (
            <Grid container direction="column">
              <Form onSubmit={async () => {
                const { result, email } = await enduserSession.begin_login_flow(
                  isEmail(emailOrPhone)
                    ? { email: emailOrPhone }
                    : { phone: emailOrPhone }
                )
                setEnduserEmail(email ?? '')
                setResult(result)
              }}>
              <TextField fullWidth 
                label="Email or Phone Number" placeholder="Enter email or phone number"
                value={emailOrPhone} onChange={e => setEmailOrPhone(e.target.value)}
                style={{ borderRadius: 15 }}
                sx={{
                  [`& fieldset`]: {
                    borderRadius: 15,
                  },
                  "& .MuiInputBase-input": {
                    marginLeft: 1,
                  },
                }}
              />

              <SubmitButton submitText="Continue" submittingText="Continuing" disabled={!emailOrPhone} 
                style={{ borderRadius: 15, width: '100%', marginTop: 5 }}
              />
              </Form>

              {!theme.portalSettings?.authentication?.hideRegister && registerEnabled &&
                <>
                <Divider flexItem sx={{ my: 1 }}>
                  <Typography sx={{ opacity: 0.5 }}>or</Typography>
                </Divider>

                <Button variant="outlined" onClick={() => navigate(routes.register)} 
                  sx={{ borderRadius: 15, width: '100%', textTransform: 'none' }}
                >
                  Sign up for an account
                </Button>
                </>
              }
            </Grid>
          )
        } 
      </Grid>
    </Grid>
    </Grid>
  )
}