import React from "react"
import MenuOpenIcon from '@mui/icons-material/MoreVert';
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"
import { 
  Styled,
} from "@tellescope/react-components"

export interface IconMenuProps extends Styled {
  open: boolean,
  setOpen: (b: boolean) => void,
  entries: { label: React.ReactNode, onClick: () => void, hidden?: boolean, }[],
  disabled?: boolean,
  label?: string,
  color?: "disabled" | "primary" | "secondary" | "inherit" | "action" | "error" | "info" | "success" | "warning" | undefined, 
  // MenuItem?: React.JSXElementConstructor<IconMenuItemProps<T>>,
  // iconButtonProps?: Omit<LabeledIconButtonProps, 'open'>,
  remainOpenOnClick?: boolean,
}
export const ViewMenuIconButton = ({ open, setOpen, entries, disabled, label="Options", remainOpenOnClick, color }: IconMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOpen = React.useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true)
  }, [setOpen])
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
    setOpen(false)
  }, [setOpen])

  return (
    <>
      {/* Using LabeledIconButton causes issues menu in wrong location */}
      <IconButton onClick={handleOpen} disabled={disabled}>
        <Tooltip title={label} placement='top' arrow>
          <MenuOpenIcon color={color} />
        </Tooltip>
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} id="simple-menu">
        {entries.map(({ label, onClick, hidden }, i) => hidden ? null : (

          <MenuItem key={i} onClick={() => {
            onClick()
            if (!remainOpenOnClick) handleClose()
          }}>
            {typeof label === 'string'
              ? (
                <Typography>{label}</Typography>
              )
              : label
            }
          </MenuItem>
        ))}
    </Menu>
    </>
  )
}